import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import { comma } from '../../common';
import 'moment/locale/ko'; // Locale Setting
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import * as XLSX from 'xlsx-js-style';

const OrderListTotalPage = () => {
    const [isOrderDeatailModal, setOrderDeatailModal] = useState(false);
    const [isFlag, setFlag] = useState(2);
    const [isBuyDetail, setBuyDetail] = useState();
    const inputsZipcode = useRef();
    const inputsAddress = useRef();
    const inputsAddressDetail = useRef();
    const editDefault = {
        idx: 0,
        recipient_name: false,
        recipient_phone: false,
        address: false,
        buycnt: false,
        memo: false,
        oldValue: '',
        newValue: '',
    };
    const [isDetailEdit, setDetailEdit] = useState(editDefault);
    const [isSearchFilterDateFlag, setSearchFilterDateFlag] = useState();
    const [isSearchFilterFlag, setSearchFilterFlag] = useState();
    const [isSearchFilterTp, setSearchFilterTp] = useState();
    const [isSearchFilterValue, setSearchFilterValue] = useState();
    const [isSearchFilterOffice, setSearchFilterOffice] = useState();
    const [isSearchFilterDate, setSearchFilterDate] = useState(); //조회기간
    const [isDateBtnActive, setDateBtnActive] = useState(1); //기간 버튼

    const [isPageInfo, setPageInfo] = useState({ pageCount: 5, totalCount: 0, pageSize: 10 });
    const [isOrderList, setOrderList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isOffice, setOffice] = useState(''); //office

    //엑셀
    const downloadExcel = async () => {
        const params = {
            page: isParams.page,
            itemsPerPage: 9999,
            dateFlag: isParams.dateFlag,
            startDate: isParams.startDate,
            endDate: isParams.endDate,
        };

        if (isParams.flag != '') {
            params.flag = isParams.flag;
        }

        if (isParams.searchTp != '') {
            params.searchTp = isParams.searchTp;
        }

        if (isParams.searchValue != '') {
            params.searchValue = isParams.searchValue;
        }

        if (isParams.office != '') {
            params.office = isParams.office;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/order/list/total`, { params });
        if (data && data.result === true) {
            const rowData = dataRemake(data.orderList.items);
            const header = isListHeader.map((row) => ({
                v: row,
                s: {
                    font: {
                        bold: true,
                        color: { rgb: 'ffffff' },
                    },
                    alignment: {
                        horizontal: 'center',
                    },
                    fill: {
                        type: 'pattern',
                        patternType: 'solid',
                        fgColor: { rgb: '3a3b3c' },
                    },
                },
            }));

            let rows = [];
            rows.push(header);

            for (const idx in rowData) {
                //console.log(idx);
                rows.push([
                    {
                        v: rowData[idx].receipt_no, //주문번호
                    },
                    {
                        v: rowData[idx].buyer_name, //구매자명
                    },
                    {
                        v: rowData[idx].buyer_email, //구매자ID
                    },
                    {
                        v: rowData[idx].buyer_phone, //구매자연락처
                    },
                    {
                        v: rowData[idx].buyer_office_name, //지점
                    },
                    {
                        v: rowData[idx].recipient_name, //수취인명
                    },
                    {
                        v: rowData[idx].recipient_phone, //수취인연락처
                    },
                    {
                        v: rowData[idx].delivery_flag, //주문상태
                    },
                    {
                        v: rowData[idx].delivery_detail_flag, //주문세부상태
                    },
                    {
                        v: rowData[idx].createdAt, //결제일
                    },
                    {
                        v: rowData[idx].updatedAt, //발주확인일
                    },
                    {
                        v: rowData[idx].enddAt, //발송처리일
                    },
                    {
                        v: rowData[idx].canceldAt, //주문취소일
                    },
                    {
                        v: rowData[idx].product_code, //상품번호
                    },
                    {
                        v: rowData[idx].product_name, //상품명
                    },
                    {
                        v: rowData[idx].option_name, //옵션정보
                    },
                    {
                        v: rowData[idx].buycnt, //수량
                        t: 'n',
                        z: '#,##0_-;-#,##0_-;0;General',
                    },
                    {
                        v: rowData[idx].sell_price, //옵션가격
                        t: 'n',
                        z: '#,##0_-;-#,##0_-;0;General',
                    },
                    {
                        v: rowData[idx].total_price, //상품별 총 주문금액
                        t: 'n',
                        z: '#,##0_-;-#,##0_-;0;General',
                    },
                    {
                        v: rowData[idx].address, //수취인주소
                    },
                    {
                        v: rowData[idx].zipcode, //우편번호
                    },
                ]);
            }

            const worksheet = XLSX.utils.aoa_to_sheet(rows);
            worksheet['!cols'] = [{ width: 20 }, { width: 10 }, { width: 20 }, { width: 25 }, { width: 10 }, { width: 15 }, { width: 20 }, { width: 15 }, { width: 22 }, { width: 18 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 20 }, { width: 30 }, { width: 30 }, { width: 10 }, { width: 18 }, { width: 25 }, { width: 30 }, { width: 10 }];
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'my_sheet');

            XLSX.writeFile(workbook, 'json_to_excel.xlsx');
        }
        setLoading(false);
    };

    const isListHeader = ['주문번호', '구매자명', '구매자ID', '구매자연락처', '지점', '수취인명', '수취인연락처', '주문상태', '주문세부상태', '결제일', '발주확인일', '발송처리일', '주문취소일', '상품번호', '상품명', '옵션정보', '수량', '옵션가격', '상품별 총 주문금액', '수취인주소', '우편번호'];
    //엑셀 끝

    const handleCallbackView = (start, end) => {
        setSearchFilterDate({ start, end });
    };

    //검색
    const [isParams, setParams] = useState({
        page: 1,
        dateFlag: 0,
        flag: '',
        searchTp: '',
        searchValue: '',
        office: '',
    });

    //페이징에서 호출
    const setPageStatus = function (pg) {
        setParams({
            ...isParams,
            page: pg,
        });
    };

    const handleModal = {
        OrderDetailClose: () => {
            setOrderDeatailModal(false);
            getOrderList();
        },
    };
    // 검색 끝

    function dataRemake(data) {
        const arrayItem = [];
        let num = 1;
        let diffkey = '';
        data.map((e, idx) => {
            if (diffkey == '') {
                diffkey = e.receipt_no;
            }

            if (diffkey != e.receipt_no) {
                num++;
                diffkey = e.receipt_no;
            }

            const makeList = {
                num: num,
                idx: e.idx,
                receipt_no: e.receipt_no,
                buyer_name: e.buyer_name,
                buyer_email: e.buyer_email,
                buyer_phone: e.buyer_phone,
                buyer_office_name: e.buyer_office_name,
                recipient_name: e.recipient_name,
                recipient_phone: e.recipient_phone,
                delivery_flag: e.flag == 0 || e.flag == 1 || e.flag == 7 ? '발송대기' : e.flag == 8 || e.flag == 9 || e.flag == 10 ? '주문취소' : e.flag == 2 ? '발송완료' : '',
                delivery_detail_flag: e.flag == 1 ? (e.msg != '' && e.canceldAt ? '발주확인(취소거부)' : '발주확인') : e.flag == 7 ? '취소요청' : e.flag == 8 ? '취소완료(구매자)' : e.flag == 9 ? '취소완료(관리자)' : e.flag == 10 ? '취소완료(승인)' : e.flag == 2 ? '' : '신규주문',
                createdAt: e.createdAt ? moment(e.createdAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                updatedAt: e.updatedAt ? moment(e.updatedAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                enddAt: e.enddAt ? moment(e.enddAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                canceldAt: e.canceldAt ? moment(e.canceldAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                product_code: e.product_code,
                product_name: e.product_name,
                option_name: e.option_name,
                buycnt: e.buycnt,
                sell_price: e.sell_price,
                total_price: e.total_price,
                address: e.address + ' ' + e.address_detail,
                zipcode: e.zipcode,
            };
            arrayItem.push(makeList);
        });

        return arrayItem;
    }

    async function getOrderList() {
        const params = {
            page: isParams.page,
            itemsPerPage: isPageInfo.pageSize,
            dateFlag: isParams.dateFlag,
            startDate: isParams.startDate,
            endDate: isParams.endDate,
        };

        if (isParams.flag != '') {
            params.flag = isParams.flag;
        }

        if (isParams.searchTp != '') {
            params.searchTp = isParams.searchTp;
        }

        if (isParams.searchValue != '') {
            params.searchValue = isParams.searchValue;
        }

        if (isParams.office != '') {
            params.office = isParams.office;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/order/list/total`, { params });
        if (data && data.result === true) {
            setOrderList(dataRemake(data.orderList.items));
            setPageInfo({ ...isPageInfo, totalCount: data.orderList.itemsLength });
        }
        setLoading(false);
    }

    async function handleSerach() {
        setParams({
            ...isParams,
            page: 1,
            dateFlag: isSearchFilterDateFlag,
            flag: isSearchFilterFlag,
            searchTp: isSearchFilterTp,
            searchValue: isSearchFilterValue,
            office: isSearchFilterOffice,
            startDate: isSearchFilterDate ? moment(isSearchFilterDate.start).format('YYYY-MM-DD') : '',
            endDate: isSearchFilterDate ? moment(isSearchFilterDate.end).format('YYYY-MM-DD') : '',
        });
    }
    async function handleSearchClear() {
        setSearchDate(-1, 'w', 1);
        setParams({
            ...isParams,
            page: 1,
            dateFlag: 0,
            flag: '',
            searchTp: '',
            searchValue: '',
            office: '',
            startDate: moment(new Date()).add(-1, 'w').format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD'),
        });
    }

    async function getOrderDetail(idx) {
        try {
            if (!idx) {
                return;
            }
            setLoading(true);
            const { data } = await apiClient.get('/order/detail/' + idx);
            if (data.result) {
                setBuyDetail(data.detail.order);
                setOrderDeatailModal(true);
            }
            setLoading(false);
        } catch (e) {
            alert(e);
        }
    }

    useEffect(() => {
        //상단 카운트 메뉴
        setFlag(isParams.flag);
        setSearchFilterTp(isParams.searchTp);
        setSearchFilterValue(isParams.searchValue);
        setSearchFilterOffice(isParams.office);
        setSearchFilterFlag(isParams.flag);
        setSearchFilterDateFlag(isParams.dateFlag);

        getOrderList();
    }, [isParams]);

    async function getOffice(codeGubun, setOffice, parentIdx) {
        try {
            if (parentIdx === '') return;
            const { data } = await apiClient.get('/code/list/' + codeGubun + '/' + parentIdx);
            if (data.result) {
                setOffice(data.codeList);
            }
        } catch (e) {}
    }

    function setSearchDate(day, flag, status) {
        setDateBtnActive(status);
        handleCallbackView(moment(new Date()).add(day, flag).add(1, 'd'), moment(new Date()));
    }

    //시작시 카테고리 호출
    useEffect(() => {
        getOffice('office', setOffice, 0);
        setSearchDate(-1, 'w', 1);
    }, []);

    useEffect(() => {
        isSearchFilterTp === '' && setSearchFilterValue('');
    }, [isSearchFilterTp]);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-light">
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">기간</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <select
                                                                className="form-select form-select-sm"
                                                                onChange={(e) => {
                                                                    setSearchFilterDateFlag(e.target.value);
                                                                }}
                                                                value={isSearchFilterDateFlag}
                                                            >
                                                                <option value="0">결제일</option>
                                                                <option value="1">발주확인일</option>
                                                                <option value="2">취소완료일</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="btn-group bg-white" role="group">
                                                                <button type="button" className={`btn btn-sm btn-outline-secondary waves-effect text-nowrap ` + (isDateBtnActive === 0 ? ' active' : '')} onClick={() => setSearchDate(-1, 'd', 0)}>
                                                                    오늘
                                                                </button>
                                                                <button type="button" className={`btn btn-sm btn-outline-secondary waves-effect text-nowrap ` + (isDateBtnActive === 1 ? ' active' : '')} onClick={() => setSearchDate(-1, 'w', 1)}>
                                                                    1주일
                                                                </button>
                                                                <button type="button" className={`btn btn-sm btn-outline-secondary waves-effect text-nowrap ` + (isDateBtnActive === 2 ? ' active' : '')} onClick={() => setSearchDate(-1, 'M', 2)}>
                                                                    1개월
                                                                </button>
                                                                <button type="button" className={`btn btn-sm btn-outline-secondary waves-effect text-nowrap ` + (isDateBtnActive === 3 ? ' active' : '')} onClick={() => setSearchDate(-3, 'M', 3)}>
                                                                    3개월
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="input-group text-nowrap">
                                                                <DateRangePicker
                                                                    onCallback={handleCallbackView}
                                                                    initialSettings={{
                                                                        timePicker: false,
                                                                        timePicker24Hour: true,
                                                                        locale: {
                                                                            cancelLabel: '닫기',
                                                                            applyLabel: '적용',
                                                                            format: 'YYYY/MM/DD',
                                                                            daysOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
                                                                            monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                                                                        },
                                                                    }}
                                                                >
                                                                    <button type="button" className="btn btn-dark btn-sm input-group-prepend">
                                                                        <i className="fe-calendar"></i>
                                                                    </button>
                                                                </DateRangePicker>
                                                                <input type="text" className="form-control form-control-sm" style={{ width: '200px' }} value={(isSearchFilterDate ? moment(isSearchFilterDate.start).format('YYYY/MM/DD') + ' ~ ' : '') + (isSearchFilterDate ? moment(isSearchFilterDate.end).format('YYYY/MM/DD') : '')} readOnly="readOnly" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">상세조건</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill me-2" style={{ width: '140px' }}>
                                                                    <select className="form-select form-select-sm" value={isSearchFilterTp} onChange={(e) => setSearchFilterTp(e.target.value)}>
                                                                        <option value="">전체</option>
                                                                        <option value="recipient_name">수취인명</option>
                                                                        <option value="buyer_name">구매자명</option>
                                                                        <option value="buyer_phone">구매자연락처</option>
                                                                        <option value="recipient_phone">수취인연락처</option>
                                                                        <option value="email">구매자ID</option>
                                                                        <option value="receipt_no">주문번호</option>
                                                                        <option value="product_code">상품번호</option>
                                                                    </select>
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className={`form-control form-control-sm`} style={isSearchFilterTp === '' ? { backgroundColor: '#f7f7f7' } : {}} value={isSearchFilterValue} readOnly={isSearchFilterTp === '' ? true : false} onChange={(e) => setSearchFilterValue(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill" style={{ width: '140px' }}>
                                                                    <select
                                                                        className="form-select form-select-sm"
                                                                        onChange={(e) => {
                                                                            setSearchFilterOffice(e.target.value);
                                                                        }}
                                                                        value={isSearchFilterOffice}
                                                                    >
                                                                        <option value="">지점선택</option>
                                                                        {isOffice && isOffice ? (
                                                                            isOffice.items.map((item) => (
                                                                                <option key={item.idx} value={item.idx}>
                                                                                    {item.txt}
                                                                                </option>
                                                                            ))
                                                                        ) : (
                                                                            <option value="">등록된 지점이 없습니다.</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill me-2" style={{ width: '140px' }}>
                                                                    <select
                                                                        className="form-select form-select-sm"
                                                                        onChange={(e) => {
                                                                            setSearchFilterFlag(e.target.value);
                                                                        }}
                                                                        value={isSearchFilterFlag}
                                                                    >
                                                                        <option value="">주문상태</option>
                                                                        <option value="0">발송완료</option>
                                                                        <option value="1">취소완료</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="justify-content-end row">
                                                <div className="col-12 text-center">
                                                    <button type="submit" className="btn btn-dark waves-effect waves-light" onClick={() => handleSerach()}>
                                                        검색
                                                    </button>
                                                    <button type="submit" className="btn btn-secondary waves-effect waves-light ms-1" onClick={() => handleSearchClear()}>
                                                        초기화
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-inline-flex fw-normal h4">주문목록({isPageInfo && isPageInfo.totalCount ? isPageInfo.totalCount : 0})</div>
                                            </div>
                                            <hr className="p-0 m-0 mb-2" />

                                            <div className="row mb-2 gap-1 ps-2">
                                                <div className="col text-end p-0 me-2">
                                                    <button className="btn btn-sm btn-dark waves-effect" onClick={() => downloadExcel()}>
                                                        엑셀 다운로드
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-bordered mb-0" id="inline-editable">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            {isListHeader.map((item) => (
                                                                <>
                                                                    <th className="text-center text-nowrap">{item}</th>
                                                                </>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {isOrderList &&
                                                            isOrderList.map((item) => (
                                                                <tr key={item.idx} className={`text-dark`} style={item.num % 2 == 0 ? { 'background-color': 'rgb(206 212 218 / 37%)' } : {}}>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        <span className="link" onClick={() => getOrderDetail(item.idx)}>
                                                                            {item.receipt_no}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_email}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_phone}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_office_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.recipient_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.recipient_phone}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.delivery_flag}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.delivery_detail_flag}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.createdAt}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.updatedAt}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.enddAt}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.canceldAt}</td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        <Link className="link" target="_blank" to={process.env.REACT_APP_FRONT_URL + '/' + item.product_code}>
                                                                            {item.product_code}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="text-start text-nowrap align-middle">{item.product_name}</td>
                                                                    <td className="text-start text-nowrap align-middle">{item.option_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{comma(item.buycnt)}</td>
                                                                    <td className="text-end text-nowrap align-middle">{comma(item.sell_price)}원</td>
                                                                    <td className="text-end text-nowrap align-middle">{comma(item.total_price)}원</td>
                                                                    <td className="text-start text-nowrap align-middle">{item.address}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.zipcode}</td>
                                                                </tr>
                                                            ))}
                                                        {isOrderList && isOrderList.length == 0 && (
                                                            <>
                                                                <tr>
                                                                    <td colSpan="22" className="text-center">
                                                                        내역이 없습니다.
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12 col-sm-12 col-lg-2"></div>
                                                <div className="col-12 col-lg-8">{isPageInfo.totalCount ? <Pagination isPageInfo={isPageInfo} isCurPage={isParams.page} setPageStatus={setPageStatus} /> : ''}</div>
                                                <div className="col-12 col-sm-12 col-lg-2 text-end"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}

            <Modal show={isOrderDeatailModal} onHide={handleModal.OrderDetailClose} backdrop="static" size="lg" centered>
                <Modal.Header className="p-0 m-0 pe-2 order-detail-popup" closeButton>
                    <Modal.Title className="p-0 m-0">
                        <div className="row p-0">
                            <div className="col">
                                <div className="bg-dark p-2">
                                    <h5 className="text-white">상품주문정보 조회</h5>
                                </div>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                    }}
                >
                    <div className="row">
                        <div className="col">
                            <div>
                                <h5>주문상세 정보</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="border px-2 mb-2">
                                    <h5>
                                        상품주문번호: {isBuyDetail && isBuyDetail.receipt_no}
                                        {isBuyDetail && isBuyDetail.flag == 1 && isBuyDetail.msg == '' && isBuyDetail.canceldAt == null && <span className="text-dark ms-2">발주확인</span>}
                                        {isBuyDetail && isBuyDetail.flag == 1 && isBuyDetail.msg != '' && isBuyDetail.canceldAt && <span className="text-dark ms-2">발주확인(취소거부)</span>}
                                        {isBuyDetail && isBuyDetail.flag == 2 && <span className="text-dark ms-2">발송완료</span>}
                                        {isBuyDetail && isBuyDetail.flag == 7 && <span className="text-danger ms-2">취소요청</span>}
                                        {isBuyDetail && isBuyDetail.flag == 8 && <span className="text-danger ms-2">취소완료(구매자요청)</span>}
                                        {isBuyDetail && isBuyDetail.flag == 9 && <span className="text-danger ms-2">취소완료(관리자)</span>}
                                        {isBuyDetail && isBuyDetail.flag == 10 && <span className="text-danger ms-2">취소완료(승인)</span>}
                                    </h5>
                                </div>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                상품명
                                            </td>
                                            <td className="bg-white" colSpan="3" width="80%">
                                                {isBuyDetail && isBuyDetail.product_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                구매자명
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && isBuyDetail.buyer_name}
                                            </td>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                구매자 ID
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && isBuyDetail.buyer_email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                상품주문상태
                                            </td>
                                            <td className="bg-white" width="30%">
                                                결제완료
                                                {isBuyDetail && isBuyDetail.flag}
                                            </td>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                연락처
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && isBuyDetail.buyer_phone}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="ms-1">
                                    <h5>배송지 정보</h5>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 정보 변경시 같은 주문번호 주문건은 모두 변경됩니다.
                                    </p>
                                </div>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2" width="20%">
                                                수취인명
                                            </td>
                                            <td className="bg-white align-middle" width="30%">
                                                {isDetailEdit.recipient_name ? <input type="text" maxLength="30" className="form-control form-control-sm" id="recipient_name" defaultValue={isBuyDetail && isBuyDetail.recipient_name} onFocus={handleOnFcous} onBlur={handleOnBlur} /> : isBuyDetail && isBuyDetail.recipient_name}
                                                {!isDetailEdit.recipient_name && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                recipient_name: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className="bg-light text-nowrap align-middle pe-2" width="20%">
                                                연락처
                                            </td>
                                            <td className="bg-white align-middle" width="30%">
                                                {isDetailEdit.recipient_phone ? <input type="text" maxLength="20" className="form-control form-control-sm" placeholder="010-XXXX-XXXX" id="recipient_phone" defaultValue={isBuyDetail && isBuyDetail.recipient_phone} onFocus={handleOnFcous} onBlur={handleOnBlur} /> : isBuyDetail && isBuyDetail.recipient_phone}
                                                {!isDetailEdit.recipient_phone && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                recipient_phone: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2">지점</td>
                                            <td className="bg-white" colSpan="3">
                                                {isBuyDetail && isBuyDetail.buyer_office_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2">배송지</td>
                                            <td className="bg-white" colSpan="3">
                                                {isDetailEdit.address ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="d-flex align-items-center flex-row">
                                                                    <div className="flex-fill me-2">
                                                                        <input type="text" className="form-control form-control-sm" placeholder="우편번호" ref={inputsZipcode} readOnly onClick={handleDaum.ZipCodePopupOpen} />
                                                                    </div>
                                                                    <div className="flex-fill">
                                                                        <button className="btn btn-sm btn-secondary rounded-1" onClick={handleDaum.ZipCodePopupOpen}>
                                                                            검색
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-8"></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <input type="text" className="form-control form-control-sm mb-1 mt-1" placeholder="주소" ref={inputsAddress} readOnly onClick={handleDaum.ZipCodePopupOpen} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <input type="text" className="form-control form-control-sm" ref={inputsAddressDetail} placeholder="상세주소" maxLength="50" />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    isBuyDetail && isBuyDetail.address + ' ' + isBuyDetail.address_detail + ' 우편번호 : ' + isBuyDetail.zipcode
                                                )}
                                                {!isDetailEdit.address && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                address: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    isBuyDetail &&
                                                    isBuyDetail.flag <= 1 && (
                                                        <>
                                                            <button
                                                                className="btn btn-sm btn-danger rounded-1 mt-1"
                                                                onClick={() =>
                                                                    setDetailEdit({
                                                                        ...isDetailEdit,
                                                                        address: false,
                                                                    })
                                                                }
                                                            >
                                                                취소
                                                            </button>
                                                            <button className="btn btn-sm btn-secondary rounded-1 float-end mt-1" onClick={() => setAddressUpdate()}>
                                                                저장
                                                            </button>
                                                        </>
                                                    )
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2">배송메모</td>
                                            <td className="bg-white" colSpan="3">
                                                {isDetailEdit.memo ? <input type="text" className="form-control form-control-sm" id="memo" defaultValue={isBuyDetail && isBuyDetail.memo} onFocus={handleOnFcous} onBlur={handleOnBlur} maxLength="200" /> : isBuyDetail && isBuyDetail.memo}{' '}
                                                {!isDetailEdit.memo && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                memo: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="ms-1">
                                    <h5>주문정보</h5>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 수량변경시 판매상품 재고 및 구매자 주문내역도 변경됩니다.
                                    </p>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 상품 판매중지, 전시중지 상관없이 옵션 판매중인(사용여부) 재고 재고수량을 표시합니다.
                                    </p>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 현재 옵션 재고 수량 : <span className="text-danger">{isBuyDetail && isBuyDetail.product_option_cnt ? isBuyDetail.product_option_cnt : 0}개</span>
                                    </p>
                                </div>
                                <table className="table table-bordered mb-2">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap" width="20%">
                                                총주문금액
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && comma(isBuyDetail.buycnt * isBuyDetail.sell_price)}원
                                            </td>
                                            <td className="bg-light text-nowrap" width="20%">
                                                구매수량
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isDetailEdit.buycnt ? <input type="text" className="form-control form-control-sm" id="buycnt" defaultValue={isBuyDetail && isBuyDetail.buycnt} onFocus={handleOnFcous} onBlur={handleOnBlur} maxLength="10" /> : isBuyDetail && comma(isBuyDetail.buycnt)}
                                                {!isDetailEdit.buycnt && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                buycnt: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2 fw-bold h5">
                                                <div className="d-flex">
                                                    <div className="flex-fill text-start">{isBuyDetail && isBuyDetail.product_name}</div>
                                                    <div className="flex-fill text-end ">상품번호 : {isBuyDetail && isBuyDetail.product_code}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap pe-2">
                                                <div className="fw-bold">
                                                    <div className="d-flex">
                                                        <div className="flex-fill text-start"> {isBuyDetail && isBuyDetail.option_name}</div>
                                                        <div className="flex-fill text-end ">옵션번호 : {isBuyDetail && isBuyDetail.option_idx}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-1">
                                                    <div className="flex-fill text-start">
                                                        수량 {isBuyDetail && comma(isBuyDetail.buycnt)} x {comma(isBuyDetail && isBuyDetail.sell_price)}원
                                                    </div>
                                                    <div className="flex-fill text-end ">{isBuyDetail && comma(isBuyDetail.buycnt * isBuyDetail.sell_price)}원</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="ms-1">
                                    <h5>주문이력</h5>
                                </div>
                                <table className="table table-bordered mb-2">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                주문
                                            </td>
                                            <td className="bg-white">{isBuyDetail && isBuyDetail.createdAt && isBuyDetail.createdAt}</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2">발주확인</td>
                                            <td className="bg-white">{isBuyDetail && isBuyDetail.updatedAt && isBuyDetail.updatedAt}</td>
                                        </tr>
                                        {isBuyDetail && isBuyDetail.canceldAt && (
                                            <>
                                                <tr>
                                                    <td className="bg-light text-nowrap pe-2">취소처리일</td>
                                                    <td className="bg-white">
                                                        {isBuyDetail && isBuyDetail.canceldAt && isBuyDetail.canceldAt}
                                                        {isBuyDetail && isBuyDetail.msg && ' / 사유 : ' + isBuyDetail.msg}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2">발송완료</td>
                                            <td className="bg-white">{isBuyDetail && isBuyDetail.enddAt && isBuyDetail.enddAt}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <div className="flex-fill text-start">
                        {/* <button type="submit" form="hook-form" className="btn btn-danger rounded-1" onClick={() => setDeleteSelectData()}>
                            수정
                        </button> */}
                    </div>
                    <div className="flex-fill text-end">
                        <button className="btn btn-secondary rounded-1" onClick={handleModal.OrderDetailClose}>
                            닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { OrderListTotalPage };
