import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import { Link, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import 'moment/locale/ko'; // Locale Setting
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';

const QnaBoardPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') != null ? searchParams.get('page') : 1;
    const searchValue = searchParams.get('sv') != null ? searchParams.get('sv') : '';
    const searchView = searchParams.get('sv2') != null ? searchParams.get('sv2') : '';
    const checkedDefault = {
        isViewY: true,
        isViewN: true,
    };

    const [isPageInfo, setPageInfo] = useState({ pageCount: 5, totalCount: 0, pageSize: 10 });
    const [isBoardList, setBoardList] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [isParams, setParams] = useState({
        page: page,
        searchValue: searchValue,
        searchView: searchView,
        board_name: 'qna',
    });

    const [isSearchFilterValue, setSearchFilterValue] = useState();
    const [isSearchFilterAllChecked, setSearchFilterAllChecked] = useState(true);
    const [isSearchFilterChecked, setSearchFilterChecked] = useState(checkedDefault);

    //페이징에서 호출
    const setPageStatus = function (pg) {
        setParams({
            ...isParams,
            page: pg,
        });
    };

    const handleFilterAllCheck = (checked) => {
        setSearchFilterChecked((prevState) => {
            const nextState = {};
            Object.keys(prevState).forEach((key) => {
                nextState[key] = checked;
            });
            return nextState;
        });
        setSearchFilterAllChecked(checked);
    };

    const handleFilterCheck = (item) => {
        setSearchFilterChecked({
            ...isSearchFilterChecked,
            [item]: !isSearchFilterChecked[item],
        });
    };

    useEffect(() => {
        const checkFilteer = Object.keys(isSearchFilterChecked).filter((key) => {
            return isSearchFilterChecked[key] === false;
        });

        setSearchFilterAllChecked(checkFilteer.length === 0 ? true : false);
    }, [isSearchFilterChecked]);

    const [isModal, setModal] = useState(false);
    const [isBoardInfo, setBoardInfo] = useState();
    const inputContentRef = useRef();

    const handleModal = {
        Close: () => {
            setModal(false);
        },
    };

    const setContent = function (val) {
        setBoardInfo({
            ...isBoardInfo,
            content: val,
        });
    };

    async function setDeleteSelectData(idx) {
        const params = {
            deleteList: isBoardInfo.idx,
            board_name: isParams.board_name,
        };

        if (confirm('삭제 후 복원이 불가능합니다. 삭제하시겠습니까?')) {
            const { data } = await apiClient.post(`/board/delete`, params);
            if (data && data.result === true) {
                setModal(false);
                getBoardList();
            }
        }
    }

    const setUpdate = async (e) => {
        if (!isBoardInfo.content) {
            alert('답변을 입력하세요.');
            inputContentRef.current.focus();
            return;
        }

        const param = {
            idx: isBoardInfo.idx,
            content: isBoardInfo.content,
            updatedAt: moment(new Date()).add(9, 'h'),
            board_name: 'qna',
            isAnswer: true,
        };

        setLoading(true);
        const { data } = await apiClient.post('/board/update', param);
        if (data.result) {
            alert('답변이 등록되었습니다.');
            setModal(false);
            getBoardList();
        }
        setLoading(false);
    };

    async function getBoardList() {
        const params = {
            page: isParams.page,
            itemsPerPage: isPageInfo.pageSize,
        };

        if (isParams.searchValue != '') {
            params.searchValue = isParams.searchValue;
        }

        if (isParams.searchView != '') {
            params.searchView = isParams.searchView;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/board/list/` + isParams.board_name, { params });
        if (data && data.result === true) {
            const totalCount = data.boardList.itemsLength;
            const arrayItem = [];
            data.boardList.items.map((e, idx) => {
                e.num = totalCount - (isParams.page - 1) * isPageInfo.pageSize - idx;
                arrayItem.push(e);
            });
            setBoardList(arrayItem);
            setPageInfo({ ...isPageInfo, totalCount: totalCount });
        }
        setLoading(false);
    }

    const handleSerach = function () {
        setParams({
            ...isParams,
            page: 1,
            searchValue: isSearchFilterValue,
            searchView: JSON.stringify(isSearchFilterChecked),
        });
    };

    const handleSearchClear = function () {
        setParams({
            ...isParams,
            page: 1,
            searchValue: '',
            searchView: JSON.stringify(checkedDefault),
        });
    };

    async function getBoardInfo(idx) {
        try {
            if (!idx) {
                return;
            }
            setLoading(true);
            const { data } = await apiClient.get('/board/detail/' + idx);
            if (data.result) {
                setBoardInfo(data.detail.board);
                setModal(true);
            }
            setLoading(false);
        } catch (e) {
            alert(e);
        }
    }

    useEffect(() => {
        setSearchFilterValue(isParams.searchValue);
        if (isParams.searchView) setSearchFilterChecked(JSON.parse(isParams.searchView));

        setSearchParams(''); //파라미터 초기화

        getBoardList();
    }, [isParams]);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-light">
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">검색어</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill" style={{ width: '95px' }}>
                                                                    문의/답변 내용
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className="form-control form-control-sm" id="search" autoComplete="off" name="search" value={isSearchFilterValue} onChange={(e) => setSearchFilterValue(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">답변상태</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2  ps-2">
                                                        <div className="col-auto">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="all" checked={isSearchFilterAllChecked} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterAllCheck(e.target.checked)} />
                                                                <label className="form-check-label ms-1 me-1" style={{ marginTop: '3px' }} htmlFor="all">
                                                                    전체
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isViewY" checked={isSearchFilterChecked.isViewY} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isViewY" style={{ marginTop: '3px' }}>
                                                                    답변완료
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isViewN" checked={isSearchFilterChecked.isViewN} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isViewN" style={{ marginTop: '3px' }}>
                                                                    답변대기
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {JSON.stringify(isSearchView)} */}
                                                </div>
                                            </div>
                                            <div className="justify-content-end row">
                                                <div className="col-12 text-center">
                                                    <button type="submit" className="btn btn-dark waves-effect waves-light" onClick={() => handleSerach()}>
                                                        검색
                                                    </button>
                                                    <button type="submit" className="btn btn-secondary waves-effect waves-light ms-1" onClick={() => handleSearchClear()}>
                                                        초기화
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-inline-flex fw-normal h4">1:1문의</div>
                                                {/* {JSON.stringify(isBoardList)} */}
                                                {/* <div className="d-inline-flex">
                                        <select className="form-select form-select-sm">
                                            <option>정렬</option>
                                            <option>전시중</option>
                                            <option>전시중지</option>
                                        </select>
                                    </div> */}
                                            </div>
                                            <hr className="p-0 m-0 mb-2 mt-2" />
                                            <div className="table-responsive-xl board-wrap">
                                                <table className="table table-bordered mb-0" id="inline-editable">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th className="text-center text-nowrap" width="150px">
                                                                등록일시
                                                            </th>
                                                            <th className="text-center text-nowrap" width="150px">
                                                                고객명
                                                            </th>
                                                            <th className="text-center text-nowrap" style={{ 'min-width': '200px' }}>
                                                                문의내용
                                                            </th>
                                                            <th className="text-center text-nowrap" width="120px">
                                                                답변
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {isBoardList &&
                                                            isBoardList.map((item, idx) => (
                                                                <tr key={item.idx}>
                                                                    <td className="text-center text-nowrap align-middle">{moment(item.createdAt).format('YYYY/MM/DD HH:mm')}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.uname}</td>
                                                                    <td className="text-start align-middle">
                                                                        {item.isAnswer === 1 ? <span className="border px-2 py-1 me-2 bg-primary text-white">답변완료</span> : ''} {item.title}
                                                                    </td>
                                                                    <td className="text-center align-middle">
                                                                        <button
                                                                            type="button"
                                                                            className={`btn ${item.isAnswer === true ? 'btn-primary' : 'btn-success'} btn-sm waves-effect waves-light rounded-0 text-nowrap m-0`}
                                                                            onClick={() => {
                                                                                getBoardInfo(`${item.idx}`);
                                                                            }}
                                                                        >
                                                                            {item.isAnswer === true ? '수정' : '답변'}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12 col-sm-12 col-lg-2"></div>
                                                <div className="col-12 col-lg-8">{isPageInfo.totalCount ? <Pagination isPageInfo={isPageInfo} isCurPage={isParams.page} setPageStatus={setPageStatus} /> : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}

            <Modal show={isModal} onHide={handleModal.Close} backdrop="static" size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>1:1문의 답변</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ms-1 text-dark">
                        <div className="d-flex justify-content-between">
                            <div className="flex-start">작성자 : {isBoardInfo && isBoardInfo.uname}</div>
                            <div className="flex-end text-end">문의 시간 : {isBoardInfo && moment(isBoardInfo.createdAt).add(-9, 'h').format('YYYY/MM/DD HH:mm')}</div>
                        </div>
                        <div className="mt-2" style={{ whiteSpace: 'pre-wrap' }}>
                            {isBoardInfo && isBoardInfo.title}
                        </div>
                    </div>
                    <div className="mt-2">
                        <textarea
                            rows={5}
                            className="form-control p-2 text-dark"
                            placeholder="답변 내용을 입력하세요."
                            maxLength={1000}
                            onChange={(e) => {
                                setContent(e.target.value);
                            }}
                            ref={inputContentRef}
                        >
                            {isBoardInfo && isBoardInfo.content}
                        </textarea>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="flex-start"> {isBoardInfo && isBoardInfo.updatedAt && '답변 시간 :' + moment(isBoardInfo.updatedAt).add(-9, 'h').format('YYYY/MM/DD HH:mm')}</div>
                            <div className="flex-end text-end">{isBoardInfo && isBoardInfo.content ? isBoardInfo.content.length : 0} / 1000</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <div className="flex-fill text-start">
                        <button type="submit" form="hook-form" className="btn btn-danger rounded-1" onClick={() => setDeleteSelectData()}>
                            삭제
                        </button>
                    </div>
                    <div className="flex-fill text-end">
                        <button type="submit" form="hook-form" className="btn btn-dark rounded-1 me-2" onClick={() => setUpdate()}>
                            답변저장
                        </button>
                        <button className="btn btn-secondary rounded-1" onClick={handleModal.Close}>
                            취소
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { QnaBoardPage };
