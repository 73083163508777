import React, { useState, useEffect } from 'react';
import apiClient from '../../apiClient';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import { comma } from '../../common';
import 'moment/locale/ko'; // Locale Setting
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Tree, MultiBackend, getBackendOptions, DndProvider, getDescendants } from '@minoru/react-dnd-treeview';
import { CustomNode } from './CustomNode';
import { CustomDragPreview } from './CustomDragPreview';
import { Placeholder } from './Placeholder';
import { theme } from './theme';
import styles from './App.module.css';
import initialData from './sample-default.json';
import { useForm } from 'react-hook-form';

const CategoryManagerPage = () => {
    //const [isEditName, setEditName] = useState();
    //const [treeData, setTreeData] = useState();
    const [isLoading, setLoading] = useState(false);
    const [isCategory1, setCategory1] = useState(''); //카테고리1
    const [isCategory2, setCategory2] = useState(''); //카테고리2
    const [selectedNode, setSelectedNode] = useState(null);

    //const tree1 = getDescendants(treeData, 0);

    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        formState: { errors },
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        setFocus: setFocus2,
        formState: { errors2 },
    } = useForm();

    const handleSelect = (node) => {
        setSelectedNode(node);
        getCategory('category-2', setCategory2, node.id);
    };

    const handleSelect2 = (node) => {};

    const handleDrop1 = async (newTreeData) => {
        const itemList = [];
        newTreeData.map((e, idx) => {
            itemList.push({
                idx: e.id,
                val: idx + 1,
                text: e.text,
            });
        });

        const param = {
            codeList: itemList,
        };

        //setLoading(true);
        const { data } = await apiClient.post('/code/order/', param);
        if (data.result) {
            setCategory1(newTreeData);
        }
    };

    const handleDrop2 = async (newTreeData) => {
        const itemList = [];
        newTreeData.map((e, idx) => {
            itemList.push({
                idx: e.id,
                val: idx + 1,
                text: e.text,
            });
        });

        const param = {
            codeList: itemList,
        };

        //setLoading(true);
        const { data } = await apiClient.post('/code/order/', param);
        if (data.result) {
            setCategory2(newTreeData);
        }
    };

    const handleDelete1 = async (id) => {
        if (!confirm('분류 삭제시 해당 분류에 포함된 상품은 미분류로 변경됩니다. 삭제 하시겠습니까?')) {
            return;
        }
        const param = {
            idx: id,
            isUsed: 0,
        };

        const { data } = await apiClient.post('/code/delete/', param);
        if (data.result) {
            const deleteIds = [id, ...getDescendants(isCategory1, id).map((node) => node.id)];
            const newTree = isCategory1.filter((node) => !deleteIds.includes(node.id));
            setCategory1(newTree);
            if (selectedNode.id === id) {
                setCategory2('');
            }
        }
    };

    const handleDelete2 = async (id) => {
        if (!confirm('분류 삭제시 해당 분류에 포함된 상품은 미분류로 변경됩니다. 삭제 하시겠습니까?')) {
            return;
        }
        const param = {
            idx: id,
            isUsed: 0,
        };

        const { data } = await apiClient.post('/code/delete/', param);
        if (data.result) {
            const deleteIds = [id, ...getDescendants(isCategory2, id).map((node) => node.id)];
            const newTree = isCategory2.filter((node) => !deleteIds.includes(node.id));
            setCategory2(newTree);
        }
    };

    const handleTextChange1 = async (id, value) => {
        const newTree = isCategory1.map((node) => {
            if (node.id === id) {
                return {
                    ...node,
                    text: value,
                };
            }

            return node;
        });

        const param = {
            idx: id,
            txt: value,
        };

        const { data } = await apiClient.post('/code/update/', param);
        if (data.result) {
            setCategory1(newTree);
        } else {
            if (data.msg == 'duplication') {
                alert('이미 사용중인 카테고리 명입니다. 다른 카테고리명을 입력해주세요.');
                setFocus('newName');
            }
        }
    };

    const handleTextChange2 = async (id, value) => {
        const newTree = isCategory2.map((node) => {
            if (node.id === id) {
                return {
                    ...node,
                    text: value,
                };
            }

            return node;
        });

        const param = {
            idx: id,
            txt: value,
            parent_idx: selectedNode.id,
        };

        const { data } = await apiClient.post('/code/update/', param);
        if (data.result) {
            setCategory2(newTree);
        } else {
            if (data.msg == 'duplication') {
                alert('이미 사용중인 카테고리 명입니다. 다른 카테고리명을 입력해주세요.');
                setFocus('newName');
            }
        }
    };

    const handleCategort2 = (id) => {
        getCategory('category-2', setCategory2, id);
    };

    const onSubmit1 = async (param) => {
        console.log(param);
        if (param.newName.trim() == '') {
            alert('카테고리 명을 입력해주세요.');
            setValue('newName', '');
            setFocus('newName');
            return;
        }

        const params = {
            gubun: 'category-1',
            isUsed: 1,
            parent_idx: 0,
            val: String(isCategory1.length + 1),
            txt: param.newName,
        };
        const { data } = await apiClient.post('/code/update/', params);
        if (data.result) {
            getCategory('category-1', setCategory1, 0);
            setValue('newName', '');
        } else {
            if (data.msg == 'duplication') {
                alert('이미 사용중인 카테고리 명입니다. 다른 카테고리명을 입력해주세요.');
                setFocus('newName');
            }
        }
    };

    const onSubmit2 = async (param) => {
        console.log(param);
        if (param.newName2.trim() == '') {
            alert('카테고리 명을 입력해주세요.');
            setValue2('newName2', '');
            setFocus2('newName2');
            return;
        }

        const params = {
            gubun: 'category-2',
            isUsed: 1,
            parent_idx: selectedNode.id,
            val: String(isCategory2.length + 1),
            txt: param.newName2,
        };
        const { data } = await apiClient.post('/code/update/', params);
        if (data.result) {
            getCategory('category-2', setCategory2, selectedNode.id);
            setValue2('newName2', '');
        } else {
            if (data.msg == 'duplication') {
                alert('이미 사용중인 카테고리 명입니다. 다른 카테고리명을 입력해주세요.');
                setFocus2('newName2');
            }
        }
    };

    async function getCategory(categoryGubun, setCategory, parentIdx) {
        try {
            if (parentIdx === '') return;
            setLoading(true);
            const { data } = await apiClient.get('/code/list/' + categoryGubun + '/' + parentIdx);
            if (data.result) {
                const codeList = data.codeList.items;
                const itemList = [];
                codeList.map((e, idx) => {
                    itemList.push({
                        id: e.idx,
                        parent: e.parent_idx == 0 ? 0 : 1,
                        droppable: false,
                        text: e.txt,
                    });
                });
                setCategory(itemList);
            }
            setLoading(false);
        } catch (e) {}
    }

    useEffect(() => {
        getCategory('category-1', setCategory1, 0);
    }, []);

    // useEffect(() => {
    //     getCategory('category-2', setCategory2, isSelectCategory1);
    // }, [isSelectCategory1]);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-inline-flex fw-normal h4">분류관리(카테고리)</div>
                                            </div>
                                            <hr className="p-0 m-0 mb-2" />
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <div className="d-flex-item flex-fill border category-manager">
                                                        <div className="bg-light p-2 border-bottom text-nowrap">
                                                            <span className="h5">대분류 카테고리</span>
                                                        </div>
                                                        <>
                                                            {isCategory1 !== '' && (
                                                                <>
                                                                    <ThemeProvider theme={theme}>
                                                                        {/* <CssBaseline /> */}
                                                                        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                                                                            <div className={styles.app}>
                                                                                <Tree
                                                                                    tree={isCategory1}
                                                                                    rootId={0}
                                                                                    sort={false}
                                                                                    insertDroppableFirst={false}
                                                                                    dropTargetOffset={10}
                                                                                    canDrop={(isCategory1, { dragSource, dropTargetId }) => {
                                                                                        // console.log(dragSource);
                                                                                        // console.log(dropTargetId);
                                                                                        // //console.log(tree1);
                                                                                        // console.log('----------------------');
                                                                                        if (dragSource?.parent === dropTargetId) {
                                                                                            //console.log(dragSource?.parent + ' ' + dropTargetId);
                                                                                            return true;
                                                                                        } else {
                                                                                            return false;
                                                                                        }
                                                                                    }}
                                                                                    render={(node, options) => <CustomNode node={node} {...options} onDelete={handleDelete1} onCategory2={handleCategort2} onTextChange={handleTextChange1} isSelected={node.id === selectedNode?.id} onSelect={handleSelect} />}
                                                                                    dragPreviewRender={(monitorProps) => <CustomDragPreview monitorProps={monitorProps} />}
                                                                                    placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
                                                                                    onDrop={handleDrop1}
                                                                                    classes={{
                                                                                        root: styles.treeRoot,
                                                                                        draggingSource: styles.draggingSource,
                                                                                        dropTarget: styles.dropTarget,
                                                                                        placeholder: styles.placeholderContainer,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </DndProvider>
                                                                    </ThemeProvider>
                                                                </>
                                                            )}
                                                        </>
                                                        <div className="bg-light p-1 border-top text-nowrap">
                                                            <form id="hook-form" onSubmit={handleSubmit(onSubmit1)} noValidate>
                                                                <div className="row py-1 ps-2">
                                                                    <div className="col-6 btn-group">
                                                                        <input className="form-control" type="text" id="newName" {...register('newName', { required: '내용을 입력하세요.' })} />
                                                                        <button type="submit" className="btn btn-dark waves-effect waves-light pe-3">
                                                                            추가
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    {isCategory2 !== '' && (
                                                        <>
                                                            <div className="d-flex-item flex-fill border category-manager">
                                                                <div className="bg-light p-2 border-bottom text-nowrap">
                                                                    <span className="h5">중분류 카테고리 {selectedNode ? '선택 : ' + selectedNode.text : ''}</span>
                                                                </div>
                                                                <ThemeProvider theme={theme}>
                                                                    {/* <CssBaseline /> */}
                                                                    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                                                                        <div className={styles.app}>
                                                                            <Tree
                                                                                tree={isCategory2}
                                                                                rootId={1}
                                                                                sort={false}
                                                                                insertDroppableFirst={false}
                                                                                dropTargetOffset={10}
                                                                                canDrop={(isCategory2, { dragSource, dropTargetId }) => {
                                                                                    if (dragSource?.parent === dropTargetId) {
                                                                                        return true;
                                                                                    } else {
                                                                                        return false;
                                                                                    }
                                                                                }}
                                                                                render={(node, options) => <CustomNode node={node} {...options} onDelete={handleDelete2} onTextChange={handleTextChange2} onSelect={handleSelect2} />}
                                                                                dragPreviewRender={(monitorProps) => <CustomDragPreview monitorProps={monitorProps} />}
                                                                                placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
                                                                                onDrop={handleDrop2}
                                                                                classes={{
                                                                                    root: styles.treeRoot,
                                                                                    draggingSource: styles.draggingSource,
                                                                                    dropTarget: styles.dropTarget,
                                                                                    placeholder: styles.placeholderContainer,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </DndProvider>
                                                                </ThemeProvider>
                                                                <div className="bg-light p-1 border-top text-nowrap">
                                                                    <form id="hook-form2" onSubmit={handleSubmit2(onSubmit2)} noValidate>
                                                                        <div className="row py-1 ps-2">
                                                                            <div className="col-6 btn-group">
                                                                                <input className="form-control" type="text" id="newName2" {...register2('newName2', { required: '내용을 입력하세요.' })} />
                                                                                <button type="submit" className="btn btn-dark waves-effect waves-light pe-3">
                                                                                    추가
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}
        </>
    );
};

export { CategoryManagerPage };
