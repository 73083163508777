import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Content-type': 'application/json',
    },
    withCredentials: true,
    validateStatus: (status) => {
        return status >= 200 && status != 401;
        // && status != 403;
    },
});

apiClient.interceptors.request.use(
    async function (config) {
        const accessToken = localStorage.getItem(`accessTokenAdmin`);
        config.headers = { Authorization: `Bearer ${accessToken}` };
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

apiClient.interceptors.response.use(
    async function (response) {
        const { config } = response;
        const originalRequest = config;
        return response;
    },
    async (error) => {
        const {
            config,
            response: { status },
        } = error;

        if (status === undefined) {
            return;
        }
        if (status === 401) {
            if (error.response.data.message === 'Unauthorized') {
                const originalRequest = config;

                if (originalRequest.url === '/refresh') {
                    localStorage.setItem(`accessTokenAdmin`, '');
                    localStorage.setItem(`accessTokenAdmin_exp`, '');
                    window.location.href = '/admin/notice';
                    return new Promise(() => {});
                }
                // token refresh 요청
                const { data } = await apiClient.post(`/refresh`);

                localStorage.setItem(`accessTokenAdmin`, data.token);
                if (originalRequest.data !== undefined) {
                    originalRequest.data = JSON.parse(originalRequest.data);
                }
                originalRequest.headers = { Authorization: `Bearer ${data.token}` };
                // 401로 요청 실패했던 요청 새로운 accessToken으로 재요청
                return axios(originalRequest);
            }

            if (error.response.data.message === 'Invalid Token') {
                localStorage.setItem(`accessTokenAdmin`, '');
                localStorage.setItem(`accessTokenAdmin_exp`, '');
                window.location.href = '/admin/notice';
                return new Promise(() => {});
            }
        }

        // //관리자만 403 처리 role 없을 경우
        // if (status === 403) {
        //     window.location.href = '/rainbow_adm/Login';
        //     return new Promise(() => {});
        // }
        return Promise.reject(error);
    },
);

export default apiClient;
