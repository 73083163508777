import React, { useState, useEffect, useRef, useContext } from 'react';
import apiClient from '../../apiClient';
import Loading from '../../components/Loading/Loading';
import 'moment/locale/ko'; // Locale Setting
import { ResponsiveLine } from '@nivo/line';
import { comma } from '../../common';
import { AppContext } from '../../App';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import { Link } from 'react-router-dom';

const DashBoardPage = () => {
    const { isGlobalItem, setGlobalItems } = useContext(AppContext);
    const [isLoading, setLoading] = useState(false);
    const [isProductStatistics, setProductStatistics] = useState([]);
    const [isSaleStatistics, setSaleStatistics] = useState([]);
    const [isUserStatistics, setUserStatistics] = useState([]);
    const [isBuyStatistics, setBuyStatistics] = useState();
    const [isChartData, setChartData] = useState();
    const [isChartSelect, setChartSelect] = useState('buyOrderCnt');
    const getProductStatistics = async () => {
        const { data } = await apiClient.get('/product/statistics', {});
        if (data.result) {
            setProductStatistics(data.statistics);
        }
    };

    const getSaleStatistics = async () => {
        const { data } = await apiClient.get('/saleStatistics/info', {});
        if (data.result) {
            setSaleStatistics(data.statistics);
        }
    };

    const getUserStatistics = async () => {
        const { data } = await apiClient.get('/userStatistics/info', {});
        if (data.result) {
            setUserStatistics(data.statistics);
        }
    };

    const getBuyStatistics = async () => {
        const { data } = await apiClient.get('/buyStatistics/info', {});
        if (data.result) {
            setChartData(data.statistics);

            // data.statistics.forEach((el) => dateArray.data.push({ x: el.buyDate.replaceAll('-', '.').substring(5, 10), y: el.buyTotalPrice }));

            // dateArray.data.reverse();
            // setBuyStatistics([dateArray]);
        }
    };

    const chartGrid = function (gubun) {
        setChartSelect(gubun);
        const chartArray = {
            id: 'chart',
            data: [],
        };
        //console.log(isChartData);
        if (isChartData === undefined) return;

        if (gubun === 'buyOrderCnt') {
            isChartData.forEach((el) => chartArray.data.push({ x: el.buyDate.replaceAll('-', '.').substring(5, 10), y: el.buyOrderCnt }));
        }
        if (gubun === 'buyTotalPrice') {
            isChartData.forEach((el) => chartArray.data.push({ x: el.buyDate.replaceAll('-', '.').substring(5, 10), y: el.buyTotalPrice }));
        }
        if (gubun === 'buyUserCnt') {
            isChartData.forEach((el) => chartArray.data.push({ x: el.buyDate.replaceAll('-', '.').substring(5, 10), y: el.buyUserCnt }));
        }
        console.log(chartArray);
        chartArray.data.reverse();
        setBuyStatistics([chartArray]);
    };

    useEffect(() => {
        setGlobalItems('reload');
        getProductStatistics();
        getSaleStatistics();
        getUserStatistics();
        getBuyStatistics();
    }, []);

    useEffect(() => {
        chartGrid('buyOrderCnt');
    }, [isChartData]);
    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* {JSON.stringify(isChartData)} */}
                                <div className="col-xl-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* {JSON.stringify(isBuyStatistics)} */}
                                            <h4 className="header-title mt-0">판매/배송</h4>

                                            <div className="row">
                                                <div className="d-flex align-content-stretch justify-content-center flex-wrap gap-3">
                                                    <div className="text-center flex">
                                                        <Link to="/order?flag=0" className="link-primary">
                                                            <h2>{isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.Order : 0}</h2>
                                                            <p className="text-dark text-nowrap">신규주문</p>
                                                        </Link>
                                                    </div>
                                                    <div className="flex d">
                                                        <h2>&nbsp;</h2>
                                                        <p className="text-dark">
                                                            <i className="fe-chevron-right"></i>
                                                        </p>
                                                    </div>
                                                    <div className="text-center flex">
                                                        <Link to="/order?flag=1">
                                                            <h2>{isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.Ready : 0}</h2>
                                                            <p className="text-dark text-nowrap">배송준비</p>
                                                        </Link>
                                                    </div>
                                                    <div className=" flex">
                                                        <h2>&nbsp;</h2>
                                                        <i className="fe-chevron-right"></i>
                                                    </div>
                                                    <div className="text-center flex">
                                                        <Link to="/order?flag=2">
                                                            <h2>{isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.End : 0}</h2>
                                                            <p className="text-dark text-nowrap">발송완료</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card">
                                        {/* <div style={{ position: 'absolute' }} className="position-absolute top-50 start-50 translate-middle">
                                <div className="spinner-grow text-primary m-2" role="status"></div>
                            </div> */}
                                        <div className="card-body">
                                            <h4 className="header-title mt-0">취소</h4>
                                            <div className="row">
                                                <div className="d-flex align-content-stretch justify-content-center flex-wrap gap-5">
                                                    <div className="text-center flex">
                                                        <Link to="/order?flag=7">
                                                            <h2>{isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.ReqCancel : 0}</h2>
                                                            <p className="text-dark text-nowrap">출고중지요청</p>
                                                        </Link>
                                                    </div>
                                                    <div className="text-center flex">
                                                        <Link to="/order?flag=8">
                                                            <h2>{isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.Cancel : 0}</h2>
                                                            <p className="text-dark text-nowrap">취소완료</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mt-0">상품현황</h4>
                                            <div className="row">
                                                <div className="d-flex align-content-stretch justify-content-center flex-wrap gap-5">
                                                    <div className="text-center flex">
                                                        <Link to="/product">
                                                            <h2>{isProductStatistics && isProductStatistics.totalCnt ? isProductStatistics.totalCnt : 0}</h2>
                                                            <p className="text-dark text-nowrap">전체상품</p>
                                                        </Link>
                                                    </div>
                                                    <div className="text-center flex">
                                                        <Link to="/product?sv=%7B%22isSaleY%22%3Atrue%2C%22isSaleN%22%3Afalse%2C%22isViewY%22%3Atrue%2C%22isViewN%22%3Afalse%2C%22isOutOfStock%22%3Afalse%7D">
                                                            <h2>{isProductStatistics && isProductStatistics.isSaleAndisViewCnt ? isProductStatistics.isSaleAndisViewCnt : 0}</h2>
                                                            <p className="text-dark text-nowrap">판매&전시</p>
                                                        </Link>
                                                    </div>
                                                    <div className="text-center flex">
                                                        <Link to="/product?sv=%7B%22isSaleY%22%3Afalse%2C%22isSaleN%22%3Atrue%2C%22isViewY%22%3Afalse%2C%22isViewN%22%3Afalse%2C%22isOutOfStock%22%3Afalse%7D">
                                                            <h2>{isProductStatistics && isProductStatistics.isNotSaleCnt ? isProductStatistics.isNotSaleCnt : 0}</h2>
                                                            <p className="text-dark text-nowrap">판매중지</p>
                                                        </Link>
                                                    </div>
                                                    <div className="text-center flex">
                                                        <Link to="/product?sv=%7B%22isSaleY%22%3Afalse%2C%22isSaleN%22%3Afalse%2C%22isViewY%22%3Afalse%2C%22isViewN%22%3Afalse%2C%22isOutOfStock%22%3Atrue%7D">
                                                            <h2>{isProductStatistics && isProductStatistics.isStockPartZeroCnt ? isProductStatistics.isStockPartZeroCnt : 0}</h2>
                                                            <p className="text-dark text-nowrap">부분품절</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mt-0 mb-3">오늘 판매금액</h4>

                                            <div className="widget-box-2">
                                                <div className="widget-detail-2 text-end">
                                                    <span className="badge bg-pink rounded-pill float-start mt-3">
                                                        {isSaleStatistics && isSaleStatistics.yesterdayPrice == 0 ? 100 : Math.ceil((isSaleStatistics.todayPrice / isSaleStatistics.yesterdayPrice) * 100)}% <i className="mdi mdi-trending-up"></i>{' '}
                                                    </span>
                                                    <h2 className="fw-bold mb-1"> {isSaleStatistics && isSaleStatistics.todayPrice ? comma(isSaleStatistics.todayPrice) : 0}원</h2>
                                                    <p className="text-muted mb-3">어제 수익 {isSaleStatistics && isSaleStatistics.yesterdayPrice ? comma(isSaleStatistics.yesterdayPrice) : 0}원</p>
                                                </div>
                                                <div className="progress progress-bar-alt-pink progress-sm">
                                                    <div className="progress-bar bg-pink" role="progressbar" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100" style={{ width: `${isSaleStatistics && isSaleStatistics.yesterdayPrice == 0 ? 100 : Math.ceil((isSaleStatistics.todayPrice / isSaleStatistics.yesterdayPrice) * 100)}%` }}>
                                                        <span className="visually-hidden">{isSaleStatistics && isSaleStatistics.yesterdayPrice == 0 ? 100 : Math.ceil((isSaleStatistics.todayPrice / isSaleStatistics.yesterdayPrice) * 100)}% Complete</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mt-0 mb-3">이번달 판매금액</h4>

                                            <div className="widget-box-2">
                                                <div className="widget-detail-2 text-end">
                                                    <span className="badge bg-success rounded-pill float-start mt-3">
                                                        {isSaleStatistics && isSaleStatistics.lastMonthPrice == 0 ? 100 : Math.ceil((isSaleStatistics.thisMonthPrice / isSaleStatistics.lastMonthPrice) * 100)}% <i className="mdi mdi-trending-up"></i>{' '}
                                                    </span>
                                                    <h2 className="fw-bold mb-1"> {isSaleStatistics && isSaleStatistics.thisMonthPrice ? comma(isSaleStatistics.thisMonthPrice) : 0}원</h2>
                                                    <p className="text-muted mb-3">지난달 수익 {isSaleStatistics && isSaleStatistics.lastMonthPrice ? comma(isSaleStatistics.lastMonthPrice) : 0}원</p>
                                                </div>
                                                <div className="progress progress-bar-alt-success progress-sm">
                                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100" style={{ width: `${isSaleStatistics && isSaleStatistics.lastMonthPrice == 0 ? 100 : Math.ceil((isSaleStatistics.thisMonthPrice / isSaleStatistics.lastMonthPrice) * 100)}%` }}>
                                                        <span className="visually-hidden">{isSaleStatistics && isSaleStatistics.lastMonthPrice == 0 ? 100 : Math.ceil((isSaleStatistics.thisMonthPrice / isSaleStatistics.lastMonthPrice) * 100)}% Complete</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mt-0 mb-3">오늘 방문자수(개발중)</h4>

                                            <div className="widget-box-2">
                                                <div className="widget-detail-2 text-end">
                                                    <span className="badge bg-primary rounded-pill float-start mt-3">
                                                        32% <i className="mdi mdi-trending-up"></i>{' '}
                                                    </span>
                                                    <h2 className="fw-normal mb-1"> 300 </h2>
                                                    <p className="text-muted mb-3">어제 방문자수 450</p>
                                                </div>
                                                <div className="progress progress-bar-alt-primary progress-sm">
                                                    <div className="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100" style={{ width: '77%' }}>
                                                        <span className="visually-hidden">77% Complete</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mt-0 mb-3">오늘 회원가입(전체)</h4>

                                            <div className="widget-box-2 text-end">
                                                <div className="widget-detail-2">
                                                    <span className="badge bg-warning rounded-pill float-start mt-3">
                                                        {isUserStatistics && isUserStatistics.yesterdayUserCnt == 0 ? 100 : Math.ceil((isUserStatistics.todayUserCnt / isUserStatistics.yesterdayUserCnt) * 100)}% <i className="mdi mdi-trending-up"></i>{' '}
                                                    </span>
                                                    <h2 className="fw-normal mb-1 fw-bold">
                                                        {isUserStatistics && isUserStatistics.todayUserCnt ? comma(isUserStatistics.todayUserCnt) : 0}명({isUserStatistics && isUserStatistics.totalUserCnt ? comma(isUserStatistics.totalUserCnt) : 0}){' '}
                                                    </h2>
                                                    <p className="text-muted mb-3">어제 회원가입 {isUserStatistics && isUserStatistics.yesterdayUserCnt ? comma(isUserStatistics.yesterdayUserCnt) : 0}명</p>
                                                </div>
                                                <div className="progress progress-bar-alt-warning progress-sm">
                                                    <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100" style={{ width: `${isUserStatistics && isUserStatistics.yesterdayUserCnt == 0 ? 100 : Math.ceil((isUserStatistics.todayUserCnt / isUserStatistics.yesterdayUserCnt) * 100)}%` }}>
                                                        <span className="visually-hidden">{isUserStatistics && isUserStatistics.yesterdayUserCnt == 0 ? 100 : Math.ceil((isUserStatistics.todayUserCnt / isUserStatistics.yesterdayUserCnt) * 100)}% Complete</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="dropdown float-end">
                                                <div className="btn-group">
                                                    <button type="button" className={`btn btn-sm border btn-primary ${isChartSelect === 'buyOrderCnt' && 'active'}`} onClick={() => chartGrid('buyOrderCnt')}>
                                                        결제건수
                                                    </button>
                                                    <button type="button" className={`btn btn-sm border btn-primary ${isChartSelect === 'buyUserCnt' && 'active'}`} onClick={() => chartGrid('buyUserCnt')}>
                                                        결제자수
                                                    </button>
                                                    <button type="button" className={`btn btn-sm border btn-primary ${isChartSelect === 'buyTotalPrice' && 'active'}`} onClick={() => chartGrid('buyTotalPrice')}>
                                                        결제금액
                                                    </button>
                                                </div>
                                            </div>
                                            <h4 className="header-title">매출통계</h4>

                                            <hr />
                                            <div className="row" style={{ height: '300px' }}>
                                                {isBuyStatistics && (
                                                    <>
                                                        {/* {JSON.stringify(isBuyStatistics)} */}
                                                        <ResponsiveLine
                                                            data={isBuyStatistics}
                                                            margin={{ top: 30, right: 30, bottom: 50, left: 70 }}
                                                            xScale={{ type: 'point' }}
                                                            yScale={{
                                                                type: 'linear',
                                                                min: 'auto',
                                                                max: 'auto',
                                                                stacked: true,
                                                                reverse: false,
                                                            }}
                                                            axisBottom={{
                                                                orient: 'bottom',
                                                                tickSize: 5,
                                                                tickPadding: 5,
                                                                tickRotation: -40, // x축 텍스트를 회전시켜줍니다. (세로)
                                                                legend: 'Date', // x 축 단위를 표시합니다.
                                                                legendOffset: 60,
                                                                legendPosition: 'middle',
                                                            }}
                                                            yFormat=">-,"
                                                            curve="monotoneX"
                                                            axisTop={null}
                                                            axisRight={null}
                                                            enableSlices="x"
                                                            sliceTooltip={({ slice }) => {
                                                                //console.log(slice.points[0].data);
                                                                return (
                                                                    <div className="fw-bold bg-dark text-white p-1 rounded-1 bg-opacity-50 shadow">
                                                                        <p className="p-0 m-0">{slice.points[0].data.xFormatted.replace('.', '월 ')}일</p>
                                                                        <p className="p-0 m-0">
                                                                            {comma(slice.points[0].data.yStacked)}
                                                                            {isChartSelect == 'buyOrderCnt' && '건'}
                                                                            {isChartSelect == 'buyTotalPrice' && '원'}
                                                                            {isChartSelect == 'buyUserCnt' && '명'}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }}
                                                            enableGridX={false}
                                                            colors={{ scheme: 'category10' }}
                                                            pointSize={7}
                                                            pointColor={{ from: 'color', modifiers: [] }}
                                                            pointBorderWidth={2}
                                                            pointBorderColor={{ from: 'color', modifiers: [] }}
                                                            pointLabelYOffset={-12}
                                                            crosshairType="bottom"
                                                            useMesh={true}
                                                            legends={[]}
                                                            motionConfig={{
                                                                mass: 1,
                                                                tension: 170,
                                                                friction: 26,
                                                                clamp: false,
                                                                precision: 0.01,
                                                                velocity: 0,
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-body" style={{ height: '415px' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}
        </>
    );
};

export { DashBoardPage };
