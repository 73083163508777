import React, { useMemo, useCallback, memo, useState } from 'react';
import { useMutation } from 'react-query';
//import styles from './quillEditor.module.css';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import apiClient from '../../apiClient';
import QuillImageDropAndPaste from './ImageDropAndPaste';
import ImageResize from 'quill-image-resize';
import Loading from '../Loading/Loading';

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
Quill.register('modules/imageResize', ImageResize);

const QuillEditor = ({ quillRef, htmlContent, setHtmlContent }) => {
    const [isloading, setLoading] = useState(false);
    //const accessToken = localStorage.getItem(`accessToken`);

    const { mutate: mutatePostImage } = useMutation((data) => {
        setLoading(true);
        return apiClient.post('/product/postImageUpload', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    });

    const imageHandler = useCallback(() => {
        const formData = new FormData();
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('multiple', 'multiple');
        input.click();

        input.onchange = async () => {
            const file = input.files;

            for (let i = 0; i < file.length; i++) {
                formData.append('files', file[i]);
            }

            mutatePostImage(formData, {
                onSuccess: ({ data }) => {
                    console.log(data);
                    data.uploadFile.forEach((item) => {
                        const quill = quillRef.current.getEditor();
                        const range = quill.getSelection()?.index;
                        if (typeof range !== 'number') return;
                        quill.setSelection(range, 1);
                        quill.clipboard.dangerouslyPasteHTML(range, `<img src=${item.file_path} alt="image" />`);
                    });
                },
                onSettled: (data, error, variables, context) => {
                    setLoading(false);
                },
            });
        };
    }, [quillRef]);

    const imageDragHandler = useCallback((dataUrl, type, imageData, callback) => {
        imageData
            .minify({
                quality: 0.8,
            })
            .then((miniImageData) => {
                const file = miniImageData.toFile();
                const formData = new FormData();
                formData.append('files', file);

                mutatePostImage(formData, {
                    onSuccess: ({ data }) => {
                        console.log(data);
                        const url = data.uploadFile[0].file_path;
                        const quill = quillRef.current.getEditor();
                        const range = quill.getSelection()?.index;
                        if (typeof range !== 'number') return;
                        quill.setSelection(range, 1);
                        console.log(url);
                        quill.clipboard.dangerouslyPasteHTML(range, `<img src=${url} alt="image" />`);
                    },
                    onSettled: (data, error, variables, context) => {
                        setLoading(false);
                    },
                });
            });
    });

    const modules = useMemo(
        () => ({
            imageDropAndPaste: { handler: imageDragHandler },
            toolbar: {
                container: [['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ size: ['small', false, 'large', 'huge'] }, { color: [] }], [({ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] })], ['image']],
                imageDrop: true,
                handlers: {
                    image: imageHandler,
                },
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
            },
        }),
        [imageHandler],
    );
    return (
        <>
            <ReactQuill ref={quillRef} value={htmlContent} onChange={setHtmlContent} modules={modules} theme="snow" />
            {isloading ? <Loading /> : null}
        </>
    );
};

export default memo(QuillEditor);
