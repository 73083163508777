import React, { useRef, useState } from 'react';
import apiClient from '../../apiClient';
import { ToastContainer, toast } from 'react-toastify';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ColorSelect = ({ idx, children, isColorData, title, handleColorSelect }) => {
    // let ref = useRef(null);
    return (
        <OverlayTrigger
            // ref={(r) => (ref = r)}
            placement="right"
            key="right"
            trigger="click"
            overlay={
                <Popover>
                    <Popover.Header as="h3">색상 선택</Popover.Header>
                    <Popover.Body className="p-0 m-0">
                        <ul className="color-list p-0 m-0 pt-1 ps-1">
                            {isColorData.items.map((item) => (
                                <>
                                    <li>
                                        <Link
                                            onClick={() => {
                                                handleColorSelect(item, idx);
                                            }}
                                            className={`color-type ` + item.txt2}
                                            title={item.txt}
                                        >
                                            <span className="sr-only">{item.txt}</span>
                                        </Link>
                                    </li>
                                </>
                            ))}

                            <li style={{ paddingTop: '2px' }}>
                                <Link
                                    onClick={() => {
                                        handleColorSelect(0, idx);
                                    }}
                                >
                                    <span className="text-dark">없음</span>
                                </Link>
                            </li>
                        </ul>
                    </Popover.Body>
                </Popover>
            }
            rootClose
        >
            {children}
        </OverlayTrigger>
    );
};

export default ColorSelect;
