// Loading.js
import React from 'react';
import { Background, LoadingText } from './Styles';
import Spinner from '../../assets/images/spinner.gif';

const Loading = () => {
    return (
        <Background>
            <img src={Spinner} alt="로딩중" />
        </Background>
    );
};
export default Loading;
