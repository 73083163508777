import { React, createContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import { Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navi } from './components/Navi/Navi';
import { LeftSide } from './components/LeftSide/LeftSide';
import { RightSide } from './components/RightSide/RightSide';
import Layout from './components/Layout/Layout';
import { useLocation } from 'react-router-dom';
import apiClient from './apiClient';
import './App.css';

export const AppContext = createContext();
const queryClient = new QueryClient();
function App() {
    const location = useLocation();

    const [isGlobalItem, setGlobalItem] = useState({
        isStatistics: [],
    });

    async function setGlobalItems(gubun, flag) {
        if (gubun === 'reload') {
            let { data } = await apiClient.get(`/statistics/info`);
            if (data && data.result === true) {
                setGlobalItem({
                    ...isGlobalItem,
                    isStatistics: data.statistics,
                });
            }
        }
    }
    return (
        <div>
            <AppContext.Provider value={{ isGlobalItem, setGlobalItems }}>
                <Helmet>
                    <title>RainbowLab 레인보우랩</title>
                </Helmet>

                <QueryClientProvider client={queryClient}>
                    <Layout />
                </QueryClientProvider>
            </AppContext.Provider>
        </div>
    );
}

export default App;
