import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import { comma } from '../../common';
import 'moment/locale/ko'; // Locale Setting
import moment from 'moment-timezone';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import { ToastContainer, toast } from 'react-toastify';

const ProductPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') != null ? searchParams.get('page') : 1;
    const searchProductName = searchParams.get('spn') != null ? searchParams.get('spn') : '';
    const searchProductCode = searchParams.get('spc') != null ? searchParams.get('spc') : '';
    const searchProductOptionName = searchParams.get('spon') != null ? searchParams.get('spon') : '';
    const searchProductOptionStock = searchParams.get('spon') != null ? searchParams.get('spon') : '';
    const searchCategory1 = searchParams.get('sc1') != null ? searchParams.get('sc1') : '';
    const searchCategory2 = searchParams.get('sc2') != null ? searchParams.get('sc2') : '';
    const searchView = searchParams.get('sv') != null ? searchParams.get('sv') : '';
    const searchViewStock = searchParams.get('svs') != null ? searchParams.get('svs') : '';
    const checkedDefault = {
        isSaleY: true,
        isSaleN: true,
        isViewY: true,
        isViewN: true,
    };
    const checkedStockPartDefault = {
        isOutOfStockPart: false,
    };

    const handleFocus = (event) => event.target.select();

    const [isPageInfo, setPageInfo] = useState({ pageCount: 5, totalCount: 0, pageSize: 10 });
    const [isProductsList, setProductsList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isCheckItems, setCheckItems] = useState([]);
    const [isSelectSale, setSelectSale] = useState('');
    const [isSelectView, setSelectView] = useState('');
    const [isStatistics, setStatistics] = useState({});

    //검색
    const [isParams, setParams] = useState({
        page: page,
        searchProductName: searchProductName,
        searchProductCode: searchProductCode,
        searchProductOptionName: searchProductOptionName,
        searchProductOptionStock: searchProductOptionStock,
        searchCategory1: searchCategory1,
        searchCategory2: searchCategory2,
        searchView: searchView,
        searchViewStock: searchViewStock,
    });

    const [isCategory1, setCategory1] = useState(''); //카테고리1
    const [isCategory2, setCategory2] = useState(''); //카테고리2
    const [isSearchFilterProductName, setSearchFilterProductName] = useState();
    const [isSearchFilterProductCode, setSearchFilterProductCode] = useState();
    const [isSearchFilterProductOptionName, setSearchFilterProductOptionName] = useState();
    const [isSearchFilterCategory1, setSearchFilterCategory1] = useState();
    const [isSearchFilterCategory2, setSearchFilterCategory2] = useState();
    const [isSearchFilterAllChecked, setSearchFilterAllChecked] = useState(true);
    const [isSearchFilterChecked, setSearchFilterChecked] = useState(checkedDefault);
    const [isSearchFilterStockChecked, setSearchFilterStockChecked] = useState(checkedStockPartDefault);

    const handleFilterCheckAllMenu = function () {
        const checkFilter = {};
        Object.keys(checkedDefault).forEach((key) => {
            checkFilter[key] = true;
        });

        handleSearchClear(checkFilter);
    };

    const handleOrderUpdate = async function (event, idx) {
        const num = event.target.value;
        if (isNaN(num)) {
            num = num.replace(/[^0-9]/g, '');
        }
        if (num === '') {
            toast.error('숫자만 입력 가능합니다.');
            event.target.value = 0;
        }

        const params = {
            idx: idx,
            isOrder: num,
        };

        const { data } = await apiClient.post(`/product/status/order/update`, params);
        if (data && data.result === true) {
            toast.success('순서가 지정되었습니다.');
        }
    };

    const handleFilterCheckMenu = function (id) {
        const checkFilter = {};
        Object.keys(checkedDefault).forEach((key) => {
            checkFilter[key] = key === id ? true : false;
        });

        handleSearchClear(checkFilter);
    };

    const handleFilterCheckStockMenu = function (id) {
        const checkFilter = {};
        Object.keys(checkedStockPartDefault).forEach((key) => {
            checkFilter[key] = key === id ? true : false;
        });

        handleSearchClear(checkFilter);
    };

    //페이징에서 호출
    const setPageStatus = function (pg) {
        setParams({
            ...isParams,
            page: pg,
        });
    };

    const handleFilterAllCheck = (checked) => {
        setSearchFilterChecked((prevState) => {
            const nextState = {};
            Object.keys(prevState).forEach((key) => {
                nextState[key] = checked;
            });
            return nextState;
        });
        setSearchFilterAllChecked(checked);
    };

    const handleFilterCheck = (item) => {
        setSearchFilterChecked({
            ...isSearchFilterChecked,
            [item]: !isSearchFilterChecked[item],
        });
    };

    const handleFilterStockCheck = (item) => {
        setSearchFilterStockChecked({
            ...isSearchFilterStockChecked,
            [item]: !isSearchFilterStockChecked[item],
        });
    };

    useEffect(() => {
        const checkFilteer = Object.keys(isSearchFilterChecked).filter((key) => {
            return isSearchFilterChecked[key] === false;
        });

        console.log(checkFilteer);
        setSearchFilterAllChecked(checkFilteer.length === 0 ? true : false);
    }, [isSearchFilterChecked]);
    // 검색 끝

    async function getProductsList() {
        const params = {
            page: isParams.page,
            itemsPerPage: isPageInfo.pageSize,
        };

        if (isParams.searchProductName != '') {
            params.productName = isParams.searchProductName;
        }

        if (isParams.searchProductCode != '') {
            params.productCode = isParams.searchProductCode;
        }

        if (isParams.searchProductOptionName != '') {
            params.productOptionName = isParams.searchProductOptionName;
        }

        if (isParams.searchView != '') {
            params.searchView = isParams.searchView;
        }

        if (isParams.searchViewStock != '') {
            params.searchViewStock = isParams.searchViewStock;
        }

        if (isParams.searchCategory1 != '') {
            params.category1 = isParams.searchCategory1;
        }

        if (isParams.searchCategory2 != '') {
            params.category2 = isParams.searchCategory2;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/product/list`, { params });
        if (data && data.result === true) {
            setProductsList(data.productList.items);
            setPageInfo({ ...isPageInfo, totalCount: data.productList.itemsLength });
            setStatistics(data.statistics);
        }
        setLoading(false);
    }

    async function setCheckedAllFn(checked) {
        let arryCopy = [];
        if (checked) {
            isProductsList.map((item) => {
                arryCopy.push(item.idx);
            });
        } else {
            arryCopy = [];
        }
        setCheckItems(arryCopy);
    }

    async function setDeleteSelectData() {
        if (isCheckItems.length === 0) {
            alert('선택된 데이터가 없습니다.');
            return;
        }
        const params = {};
        params['deleteList'] = isCheckItems;

        if (confirm('삭제 후 복원이 불가능합니다. 삭제하시겠습니까?')) {
            const { data } = await apiClient.post(`/product/delete`, params);
            if (data && data.result === true) {
                setCheckedAllFn(false);
                getProductsList();
            }
        }
    }

    async function setUpdateSelectData(idx) {
        const params = {};

        if (idx) {
            params['deleteList'] = idx;
        } else {
            if (isCheckItems.length === 0) {
                alert('선택된 데이터가 없습니다.');
                return;
            }
            params['deleteList'] = isCheckItems;
        }

        if (isSelectView == '' && isSelectSale == '') {
            alert('판매 상태값 또는 전시 상태값을 선택후 이용하세요.');
            return;
        }

        if (isSelectSale != '') params['isSale'] = isSelectSale;
        if (isSelectView != '') params['isView'] = isSelectView;

        const { data } = await apiClient.post(`/product/status/update`, params);
        if (data && data.result === true) {
            alert('상태값이 변경 되었습니다.');
            setCheckedAllFn(false);
            setSelectSale(''); //변경후 셀렉트 박스 초기화
            setSelectView(''); //변경후 셀렉트 박스 초기화
            setCheckItems([]);
            getProductsList();
        }
        //}
    }

    async function handleSerach() {
        setParams({
            ...isParams,
            page: 1,
            searchProductName: isSearchFilterProductName,
            searchProductCode: isSearchFilterProductCode,
            searchProductOptionName: isSearchFilterProductOptionName,
            searchCategory1: isSearchFilterCategory1,
            searchCategory2: isSearchFilterCategory2,
            searchView: JSON.stringify(isSearchFilterChecked),
            searchViewStock: JSON.stringify(isSearchFilterStockChecked),
        });
    }
    async function handleSearchClear(checkView) {
        setParams({
            ...isParams,
            page: 1,
            searchProductName: '',
            searchProductCode: '',
            searchProductOptionName: '',
            searchCategory1: '',
            searchCategory2: '',
            searchView: JSON.stringify(checkView === undefined ? checkedDefault : checkView),
            searchViewStock: JSON.stringify(checkView === undefined ? checkedStockPartDefault : checkView),
        });
    }

    async function setCheckedFn(checked, idx) {
        let arryCopy = [...isCheckItems];
        if (checked) {
            arryCopy.push(idx);
        } else {
            arryCopy = arryCopy.filter((el) => el !== idx);
        }

        setCheckItems(arryCopy);
    }

    function goProductUpdate(product_code) {
        searchParams.set('page', isParams.page);
        searchParams.set('spn', isParams.searchProductName);
        searchParams.set('spc', isParams.searchProductCode);
        searchParams.set('spon', isParams.searchProductOptionName);
        searchParams.set('sc1', isParams.searchCategory1);
        searchParams.set('sc2', isParams.searchCategory2);
        searchParams.set('sv', isParams.searchView);
        searchParams.set('svs', isParams.searchViewStock);
        setSearchParams(searchParams);

        navigate(`/product/update/` + product_code + '?' + searchParams);
    }

    //카테고리 호출 함수
    async function getCategory(categoryGubun, setCategory, parentIdx) {
        try {
            if (parentIdx === '') return;
            const { data } = await apiClient.get('/code/list/' + categoryGubun + '/' + parentIdx);
            if (data.result) {
                setCategory(data.codeList);
            }
        } catch (e) {}
    }

    function setCopy(product_code) {
        navigate(`/product/update/` + product_code + '?copy=y');
    }

    //시작시 카테고리 호출
    useEffect(() => {
        getCategory('category-1', setCategory1, 0);
    }, []);

    useEffect(() => {
        if (isSearchFilterCategory1) {
            if (isSearchFilterCategory1 !== '0') getCategory('category-2', setCategory2, isSearchFilterCategory1);
        } else {
            setCategory2('');
        }
    }, [isSearchFilterCategory1]);

    useEffect(() => {
        setSearchFilterProductName(isParams.searchProductName);
        setSearchFilterProductCode(isParams.searchProductCode);
        setSearchFilterProductOptionName(isParams.searchProductOptionName);
        setSearchFilterCategory1(isParams.searchCategory1);
        setSearchFilterCategory2(isParams.searchCategory2);
        if (isParams.searchView) setSearchFilterChecked(JSON.parse(isParams.searchView));
        if (isParams.searchViewStock) setSearchFilterStockChecked(JSON.parse(isParams.searchViewStock));

        setSearchParams(''); //파라미터 초기화

        getProductsList();
    }, [isParams]);
    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-2 col-md-6 col-sm-4 col-4">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isSearchFilterAllChecked === true ? 'border-primary border border-2' : '')} onClick={() => handleFilterCheckAllMenu()}>
                                            <div className="text-center">
                                                <h5 className="text-nowrap">
                                                    <i className="mdi mdi-collage h4 me-1"></i> 전체
                                                </h5>
                                                <h2 className="fw-normal text-text" data-plugin="counterup">
                                                    {isStatistics && isStatistics.totalCnt ? isStatistics.totalCnt : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-4 col-4">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isSearchFilterChecked.isSaleY === true && isSearchFilterAllChecked === false ? 'border-primary border border-2' : '')} onClick={() => handleFilterCheckMenu('isSaleY')}>
                                            <div className="text-center">
                                                <h5 className="text-nowrap">
                                                    <i className="mdi mdi-cart-outline h4 me-1"></i> 판매 중
                                                </h5>
                                                <h2 className="fw-normal text-text" data-plugin="counterup">
                                                    {isStatistics && isStatistics.isSaleCnt ? isStatistics.isSaleCnt : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-4 col-4">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isSearchFilterChecked.isSaleN === true && isSearchFilterAllChecked === false ? 'border-primary border border-2' : '')} onClick={() => handleFilterCheckMenu('isSaleN')}>
                                            <div className="text-center">
                                                <h5 className="text-nowrap">
                                                    <i className="mdi mdi-cart-arrow-up h4 me-1"></i> 판매중지
                                                </h5>
                                                <h2 className="fw-normal text-text" data-plugin="counterup">
                                                    {isStatistics && isStatistics.isNotSaleCnt ? isStatistics.isNotSaleCnt : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-4 col-4">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isSearchFilterChecked.isViewY === true && isSearchFilterAllChecked === false ? 'border-primary border border-2' : '')} onClick={() => handleFilterCheckMenu('isViewY')}>
                                            <div className="text-center">
                                                <h5 className="text-nowrap">
                                                    <i className="mdi mdi-cart-remove h4 me-1"></i> 전시중
                                                </h5>
                                                <h2 className="fw-normal text-text" data-plugin="counterup">
                                                    {isStatistics && isStatistics.isViewCnt ? isStatistics.isViewCnt : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-4 col-4">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isSearchFilterChecked.isViewN === true && isSearchFilterAllChecked === false ? 'border-primary border border-2' : '')} onClick={() => handleFilterCheckMenu('isViewN')}>
                                            <div className="text-center">
                                                <h5 className="text-nowrap">
                                                    <i className="mdi mdi-cart-remove h4 me-1"></i> 전시중지
                                                </h5>
                                                <h2 className="fw-normal text-text" data-plugin="counterup">
                                                    {isStatistics && isStatistics.isNotViewCnt ? isStatistics.isNotViewCnt : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-4 col-4">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isSearchFilterStockChecked.isOutOfStockPart === true ? 'border-primary border border-2' : '')} onClick={() => handleFilterCheckStockMenu('isOutOfStockPart')}>
                                            <div className="text-center">
                                                <h5 className="text-nowrap">
                                                    <i className="mdi mdi-cart-remove h4 me-1"></i> 부분품절
                                                </h5>
                                                <h2 className="fw-normal text-text" data-plugin="counterup">
                                                    {isStatistics && isStatistics.isStockPartZeroCnt ? isStatistics.isStockPartZeroCnt : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body bg-white">
                                <div className="row">
                                    <div className="col-3 col-sm-2">
                                        <div className="me-3">
                                            <div className="fw-normal h4 text-nowrap">
                                                <i className="mdi mdi-collage h4 me-1"></i>전체
                                            </div>
                                            <div className="h3 fw-normal text-nowrap">135건</div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-2">
                                        <div className="me-3">
                                            <div className="fw-normal h4 text-nowrap">
                                                <i className="mdi mdi-cart-outline h4 me-1"></i>판매중
                                            </div>
                                            <div className="h3 fw-normal text-nowrap">135건</div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-2">
                                        <div className="me-3">
                                            <div className="fw-normal h4 text-nowrap">
                                                <i className="mdi mdi-cart-arrow-up h4 me-1"></i>품절
                                            </div>
                                            <div className="h3 fw-normal text-nowrap">135건</div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-2">
                                        <div className="me-3">
                                            <div className="fw-normal h4 text-nowrap">
                                                <i className="mdi mdi-cart-remove h4 me-1"></i>판매중지
                                            </div>
                                            <div className="h3 fw-normal text-nowrap">135건</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-light">
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">검색어</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill" style={{ width: '75px' }}>
                                                                    상품명
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className="form-control form-control-sm" value={isSearchFilterProductName} onChange={(e) => setSearchFilterProductName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill" style={{ width: '75px' }}>
                                                                    상품번호
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className="form-control form-control-sm" value={isSearchFilterProductCode} onChange={(e) => setSearchFilterProductCode(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill" style={{ width: '75px' }}>
                                                                    옵션명
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className="form-control form-control-sm" value={isSearchFilterProductOptionName} onChange={(e) => setSearchFilterProductOptionName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">전시상태</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2  ps-2">
                                                        <div className="col-auto">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="all" checked={isSearchFilterAllChecked} value="all" style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterAllCheck(e.target.checked)} />
                                                                <label className="form-check-label ms-1 me-1" style={{ marginTop: '3px' }} htmlFor="all">
                                                                    전체
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isSaleY" checked={isSearchFilterChecked.isSaleY} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isSaleY" style={{ marginTop: '3px' }}>
                                                                    판매중
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isSaleN" checked={isSearchFilterChecked.isSaleN} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isSaleN" style={{ marginTop: '3px' }}>
                                                                    판매중지
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isViewY" checked={isSearchFilterChecked.isViewY} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isViewY" style={{ marginTop: '3px' }}>
                                                                    전시중
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isViewN" checked={isSearchFilterChecked.isViewN} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isViewN" style={{ marginTop: '3px' }}>
                                                                    전시중지
                                                                </label>
                                                            </div>
                                                            {/* {JSON.stringify(isSearchFilterChecked)} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">품절상태</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2  ps-2">
                                                        <div className="col-auto">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isOutOfStockPart" checked={isSearchFilterStockChecked.isOutOfStockPart} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterStockCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isOutOfStockPart" style={{ marginTop: '3px' }}>
                                                                    품절 (옵션이 한개라도 품절인 경우)
                                                                </label>
                                                            </div>
                                                            {/* {JSON.stringify(isSearchFilterChecked)} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">카테고리</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-2 gy-2 ps-2">
                                                        <div className="col-auto col-12 col-xl-4">
                                                            <select
                                                                className="form-select form-select-sm"
                                                                onChange={(e) => {
                                                                    setSearchFilterCategory1(e.target.value);
                                                                }}
                                                                value={isSearchFilterCategory1}
                                                            >
                                                                {isCategory1 && isCategory1.items.length > 0 ? <option value="">대분류</option> : ''}
                                                                {isCategory1 &&
                                                                    isCategory1.items.map((item) => (
                                                                        <option key={item.idx} value={item.idx}>
                                                                            {item.txt}
                                                                        </option>
                                                                    ))}
                                                                {isCategory1 && <option value="0">미분류</option>}
                                                                {!isCategory1 && <option value="">등록된 카테고리가 없습니다.</option>}
                                                            </select>
                                                        </div>
                                                        <div className="col-auto col-12 col-xl-4">
                                                            <select
                                                                className="form-select form-select-sm"
                                                                disabled={isSearchFilterCategory1 && isSearchFilterCategory1 !== '0' ? false : true}
                                                                onChange={(e) => {
                                                                    setSearchFilterCategory2(e.target.value);
                                                                }}
                                                                value={isSearchFilterCategory2}
                                                            >
                                                                <option value="">중분류</option>
                                                                {isCategory2 &&
                                                                    isCategory2.items.map((item) => (
                                                                        <option key={item.idx} value={item.idx}>
                                                                            {item.txt}
                                                                        </option>
                                                                    ))}
                                                                {isCategory1 && <option value="0">미분류</option>}
                                                                {!isCategory2 && <option value="">등록된 카테고리가 없습니다.</option>}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="justify-content-end row">
                                                <div className="col-12 text-center">
                                                    <button type="submit" className="btn btn-dark waves-effect waves-light" onClick={() => handleSerach()}>
                                                        검색
                                                    </button>
                                                    <button type="submit" className="btn btn-secondary waves-effect waves-light ms-1" onClick={() => handleSearchClear()}>
                                                        초기화
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-inline-flex fw-normal h4">상품 목록({isPageInfo && isPageInfo.totalCount ? isPageInfo.totalCount : 0})</div>
                                            </div>
                                            <div className="row mb-2 gap-1 ps-2">＊ 노출순서 : 같은 순서(번호)로 지정시 등록순으로 표시됩니다.</div>
                                            <hr className="p-0 m-0 mb-2" />
                                            <div className="row mb-2 gap-1 ps-2">
                                                <div className="col-auto p-0 me-2">
                                                    <button type="button" className="btn btn-sm btn-outline-secondary waves-effect" onClick={() => setDeleteSelectData()}>
                                                        선택삭제
                                                    </button>
                                                </div>
                                                <div className="col-auto p-0">
                                                    <select className="form-select form-select-sm" value={isSelectSale} onChange={(e) => setSelectSale(e.target.value)}>
                                                        <option value="">판매변경</option>
                                                        <option value="1">판매중</option>
                                                        <option value="0">판매중지</option>
                                                    </select>
                                                </div>
                                                <div className="col-auto p-0">
                                                    <select className="form-select form-select-sm" value={isSelectView} onChange={(e) => setSelectView(e.target.value)}>
                                                        <option value="0">전시변경</option>
                                                        <option value="1">전시중</option>
                                                        <option value="0">전시중지</option>
                                                    </select>
                                                </div>
                                                <div className="col-auto p-0">
                                                    <button type="submit" className="btn btn-dark btn-sm waves-effect waves-light" onClick={() => setUpdateSelectData()}>
                                                        선택저장
                                                    </button>
                                                </div>

                                                {/* <div>{JSON.stringify(isCheckItems)}</div>
                                    <br />
                                    <div>{JSON.stringify(isProductsList.length)}</div> */}
                                                {/* <div className="col-auto p-0 text-end">
                                        <Link to="/product/update" type="button" className="btn btn-sm btn-dark waves-effect waves-light">
                                            상품등록
                                        </Link>
                                    </div> */}
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-bordered mb-0" id="inline-editable">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th className="text-center text-nowrap">
                                                                <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isProductsList && isCheckItems.length == isProductsList.length ? true : false} onChange={(e) => setCheckedAllFn(e.target.checked)} />
                                                            </th>
                                                            <th className="text-center text-nowrap">수정</th>
                                                            <th className="text-center text-nowrap">복사</th>
                                                            <th className="text-center text-nowrap">상품번호</th>
                                                            <th className="text-center text-nowrap">썸네일</th>
                                                            <th className="text-center text-nowrap">카테고리</th>
                                                            <th className="text-center text-nowrap">상품명</th>
                                                            <th className="text-center text-nowrap">판매상태</th>
                                                            <th className="text-center text-nowrap">전시상태</th>
                                                            <th className="text-center text-nowrap">노출순서</th>
                                                            <th className="text-center text-nowrap">옵션수</th>
                                                            <th className="text-center text-nowrap">옵션품절</th>
                                                            <th className="text-center text-nowrap">재고수량</th>
                                                            <th className="text-center text-nowrap">판매가</th>
                                                            <th className="text-center text-nowrap">등록일</th>
                                                            <th className="text-center text-nowrap">마지막수정일</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <ProductList key={item.idx} data={item} isCheckItems={isCheckItems} setCheckItems={setCheckItems} />)} */}
                                                        {isProductsList &&
                                                            isProductsList.map((item, index) => (
                                                                <tr key={item.idx}>
                                                                    <td className="text-center align-middle">
                                                                        <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isCheckItems.length > 0 && isCheckItems.includes(item.idx) ? true : false} onChange={(e) => setCheckedFn(e.target.checked, item.idx)} />
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success waves-effect waves-light rounded-0 text-nowrap"
                                                                            onClick={() => {
                                                                                goProductUpdate(item.product_code);
                                                                            }}
                                                                        >
                                                                            수정
                                                                        </button>
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-dark waves-effect waves-light rounded-0 text-nowrap"
                                                                            onClick={() => {
                                                                                setCopy(item.product_code);
                                                                            }}
                                                                        >
                                                                            복사
                                                                        </button>
                                                                    </td>
                                                                    <td className="text-center align-middle">{item.product_code}</td>
                                                                    <td className="text-center p-1">
                                                                        <img src={item.thumnail} width="60px" />
                                                                    </td>
                                                                    <td className="text-nowrap align-middle">
                                                                        {item.category1}{' '}
                                                                        {item.category1 ? (
                                                                            item.category2 ? (
                                                                                ' > ' + item.category2
                                                                            ) : (
                                                                                <>
                                                                                    &gt; <span className="text-danger">미분류</span>
                                                                                </>
                                                                            )
                                                                        ) : (
                                                                            <span className="text-danger">미분류</span>
                                                                        )}
                                                                    </td>
                                                                    <td className="text-nowrap align-middle" style={{ minWidth: '300px' }}>
                                                                        {item.product_name}
                                                                    </td>
                                                                    <td className="text-center align-middle">{item.isSale === 1 ? '판매중' : '판매중지'}</td>
                                                                    <td className="text-center align-middle">{item.isView === 1 ? '전시중' : '전시중지'}</td>
                                                                    <td className="text-center align-middle">
                                                                        <input type="number" className="form-control form-control-sm text-center order-number-input" onFocus={handleFocus} defaultValue={item.isOrder} onBlur={(e) => handleOrderUpdate(e, item.idx)} />
                                                                    </td>
                                                                    <td className="text-center align-middle">{comma(item.option_cnt)}</td>
                                                                    <td className="text-center align-middle">{comma(item.option_zero_cnt)}</td>
                                                                    <td className="text-center align-middle">{comma(item.option_sum_cnt)}</td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        {comma(item.sprice)} ~ {comma(item.eprice)}원
                                                                    </td>
                                                                    <td className="text-center align-middle text-nowrap">{item.createdAt}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.updatedAt ? moment(item.updatedAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : ''}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12 col-sm-12 col-lg-2"></div>
                                                <div className="col-12 col-lg-8">{isPageInfo.totalCount ? <Pagination isPageInfo={isPageInfo} isCurPage={isParams.page} setPageStatus={setPageStatus} /> : ''}</div>
                                                <div className="col-12 col-sm-12 col-lg-2 text-end">
                                                    <Link to="/product/update" type="button" className="btn btn-dark waves-effect waves-light">
                                                        상품등록
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}
            <ToastContainer
                position="top-right" // 알람 위치 지정
                autoClose={3000} // 자동 off 시간
                hideProgressBar={false} // 진행시간바 숨김
                closeOnClick // 클릭으로 알람 닫기
                rtl={false} // 알림 좌우 반전
                pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                draggable // 드래그 가능
                pauseOnHover // 마우스를 올리면 알람 정지
                theme="light"
                // limit={1} // 알람 개수 제한
            />
        </>
    );
};

export { ProductPage };
