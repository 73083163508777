import React, { useState, useEffect, useRef, useCallback } from 'react';
import apiClient from '../../apiClient';

import styled from 'styled-components';
import Uploady, { useItemProgressListener } from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';
import withPasteUpload from '@rpldy/upload-paste';
import { useItemFinishListener } from '@rpldy/uploady';
import UploadButton from '@rpldy/upload-button';
import Loading from '../../components/Loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/ko'; // Locale Setting
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import xlsicon from './../../assets/images/xlsicon.png';
import Companylogo from './../../assets/images/rainbow-logo3.png';
// import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Chip, IconButton, ImageList, ImageListItem, ImageListItemBar, Stack } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';

const StyledDropZone = styled(UploadDropZone)`
    width: 100%;
    height: 200px;
    border: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    font-size: 13px;
    background-color: rgba(108, 117, 125, 0.25) !important;
    border: 1px solid #a7a7a7;
    border-radius: 5px;
    position: relative;
    button {
        width: 200px;
        position: absolute;
        bottom: -1px;
        border: 1px solid #a7a7a7;
        padding: 5px 0 5px 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

const PasteUploadDropZone = withPasteUpload(StyledDropZone);

const OrderAdminNoticeUpdatePage = () => {
    const navigate = useNavigate();
    const { noticeidx } = useParams();
    console.log(noticeidx);
    const [isNoticeList, setNoticeList] = useState([]);
    const [isCurrentIdx, setCurrentIdx] = useState();
    const [isDate, setDate] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [isNoticePopupIdx, setNoticePopupIdx] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [isThumnail, setThumnail] = useState([]);

    async function getNoticeList(idx_) {
        if (idx_ === undefined) {
            return;
        } else {
            setCurrentIdx(idx_);
        }

        setLoading(true);
        let { data } = await apiClient.get(`/notice/list/` + idx_);
        if (data && data.result === true) {
            const arrayItem = [];
            const imagesArray = [];

            //0이면 등록 페이지
            if (idx_ != 0) {
                if (data.xlsFile !== '') {
                    imagesArray.push(data.xlsFile);
                }
            }

            data.imageItems.map((e) => {
                imagesArray.push({ file_path: e.file_path, thumnail_idx: e.thumnail_idx, gubun: e.gubun, origin_name: e.origin_name, idx: e.idx, thumnail_idx: e.thumnail_idx });
            });

            setThumnail(imagesArray);

            try {
                setNoticePopupIdx(data.popupIdx);
            } catch (e) {
                console.log(e);
            }
        }
        setLoading(false);
    }
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const setThumnailDelete = (idx) => {
        let rowsCopy = [];
        const rows = [...isThumnail];

        rows.map((item) => {
            if (item.thumnail_idx != idx) {
                rowsCopy.push(item);
            }
        });
        setThumnail(rowsCopy);
    };

    const ThumnailUploadStatus = () => {
        useItemFinishListener((item) => {
            if (item.uploadResponse.data.result) {
                if (item.uploadResponse.data.uploadFile[0].file_path === 'none') {
                    toast.error('클립보드에 내용이 없습니다.');
                } else {
                    const rows = [...isThumnail];
                    rows.push({
                        file_path: item.uploadResponse.data.uploadFile[0].file_path,
                        thumnail_idx: item.uploadResponse.data.uploadFile[0].idx,
                        gubun: item.uploadResponse.data.uploadFile[0].gubun,
                        origin_name: item.uploadResponse.data.uploadFile[0].origin_name,
                    });
                    setThumnail(rows);
                }
            }
        });
    };

    const setUpdate = async (e) => {
        if (isThumnail.filter((e) => e.gubun === 'IMG').length == 0) {
            toast.info('이미지를 등록하세요.');
            return;
        }

        if (isThumnail.filter((e) => e.gubun === 'FILE').length == 0) {
            toast.info('발주서를 업로드하세요.');
            return;
        }

        if (isThumnail.filter((e) => e.gubun === 'FILE').length > 1) {
            toast.info('발주서는 하나만 등록 가능합니다.');
            return;
        }

        const param = {
            thumnail: isThumnail,
        };
        if (isCurrentIdx) {
            param.idx = parseInt(isCurrentIdx);
        }

        if (isNoticePopupIdx) {
            param.popup_idx = parseInt(isNoticePopupIdx);
        }

        setLoading(true);
        const { data } = await apiClient.post('/notice/update', param);
        if (data.result) {
            navigate(`/admin/notice`);
        } else {
            toast.error('처리도중 오류가 발생했습니다. 다시 시도해주세요.');
        }
        setLoading(false);
    };

    const LogProgress = () => {
        useItemProgressListener((item) => {
            console.log(`>>>>> (hook) File ${item.file.name} completed: ${item.completed}`);
        });

        return null;
    };

    const setDelete = async (e) => {
        if (isCurrentIdx === 0 || isCurrentIdx === '') {
            toast.error('삭제할수 없습니다.');
        }

        if (!confirm('삭제 하시겠습니까?')) {
            return;
        }
        const param = {
            idx: parseInt(isCurrentIdx),
        };

        setLoading(true);
        const { data } = await apiClient.post('/notice/delete', param);
        if (data.result) {
            navigate(`/admin/notice`);
        } else {
            toast.error('처리도중 오류가 발생했습니다. 다시 시도해주세요.');
        }
        setLoading(false);
    };

    const ALLOW_FILE_EXTENSION = 'jpg,jpeg,png,gif,xls,xlsx';

    const removeFileName = (originalFileName) => {
        const lastIndex = originalFileName.lastIndexOf('.');
        if (lastIndex < 0) {
            return '';
        }
        return originalFileName.substring(lastIndex + 1).toLowerCase();
    };

    const fileExtensionValid = (name) => {
        const extension = removeFileName(name);

        if (!(ALLOW_FILE_EXTENSION.indexOf(extension) > -1) || extension === '') {
            return false;
        }
        return true;
    };

    const filterFile = useCallback((file) => {
        if (!fileExtensionValid(file.name)) {
            toast.error(`업로드 가능한 확장자가 아닙니다. (이미지, 엑셀파일만 허용)`);
            return false;
        } else {
            return true;
        }
    }, []);

    useEffect(() => {
        getNoticeList(noticeidx);
    }, [noticeidx]);

    const onSortClick = (oldIndex, newIndex) => {
        setThumnail((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const setPopup = (idx) => {
        setNoticePopupIdx(idx === isNoticePopupIdx ? '' : idx);
    };

    return (
        <>
            <div className="bg-white sticky-top p-2 pt-1 pb-1 border-bottom border-1 border-secondary">
                <div className="d-flex justify-content-between">
                    <div className="bg-white lab-logo-txt">
                        <img src={Companylogo} className="notice-logo" />
                        <span className="align-bottom text-dark h5 ms-1">
                            Rainbow<strong style={{ color: '#ef4266' }}>Lab</strong>
                        </span>
                    </div>
                </div>
            </div>

            <div className="container-fluid h-100 pb-4 pt-2 ">
                <div className="gallery-list ">
                    <div className="row justify-content-md-start py-2 rounded bg-white px-2">
                        <Col className="mb-2">
                            <Uploady
                                fileFilter={filterFile}
                                multiple={false}
                                grouped
                                method="POST"
                                destination={{
                                    url: process.env.REACT_APP_AXIOS_URL + `/product/thumnailImageUpload`,
                                }}
                            >
                                <div className="avatar-xl position-relative" style={{ width: '200px', height: '200px' }}>
                                    <>
                                        <PasteUploadDropZone>
                                            엑셀 & 이미지 드래그
                                            <br /> 또는 복사 붙여넣기
                                            <br />
                                            <br />
                                            <UploadButton>파일 선택</UploadButton>
                                        </PasteUploadDropZone>
                                        <div style={{ display: 'none' }}>
                                            <LogProgress />
                                            <ThumnailUploadStatus />
                                        </div>
                                    </>
                                </div>
                            </Uploady>
                        </Col>
                        {/* {JSON.stringify(isThumnail)} */}
                        <ImageList variant="" cols={4} gap={5}>
                            {isThumnail &&
                                isThumnail.map((item, idx_) => (
                                    <>
                                        <ImageListItem key={item.file_path}>
                                            <div style={{ position: 'relative', height: '400px', backgroundColor: '#fff', overflow: 'hidden' }} className="gallery-img rounded border border-2 border-dark">
                                                {item.gubun === 'IMG' ? (
                                                    <>
                                                        <img srcSet={`${item.file_path}`} src={`${item.file_path}`} alt={item.file_path} loading="lazy" style={{ width: '100%', overflow: 'hidden', 'object-position': 'top' }} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src={`${xlsicon}`} alt={xlsicon} loading="lazy" style={{ width: '100%', overflow: 'hidden', 'object-position': 'top' }} />
                                                    </>
                                                )}

                                                <div
                                                    className="position-absolute bottom-0 bg-dark opacity-50 z-1 w-100"
                                                    style={{
                                                        padding: '1px 2px 0 2px',
                                                        margin: '1px 1px 0 0',
                                                        zIndex: '2',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            {idx_ - 1 >= 0 && (
                                                                <KeyboardArrowUpIcon
                                                                    onClick={() => {
                                                                        onSortClick(idx_ - 1, idx_);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div>
                                                            {idx_ + 1 !== isThumnail.length && (
                                                                <KeyboardArrowDownIcon
                                                                    onClick={() => {
                                                                        onSortClick(idx_, idx_ + 1);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {item.gubun === 'IMG' && (
                                                <span
                                                    className={'position-absolute top-0 left-0 z-1 m-0'}
                                                    style={{
                                                        padding: '1px',
                                                        margin: '0',
                                                        zIndex: '2',
                                                        cursor: 'pointer',
                                                        isolation: 'isolate',
                                                    }}
                                                    onClick={() => {
                                                        setPopup(item.thumnail_idx);
                                                    }}
                                                >
                                                    <Stack spacing={1}>{isNoticePopupIdx && item.thumnail_idx === isNoticePopupIdx ? <Chip label="팝업" color="primary" style={{ borderRadius: 0 }} /> : <Chip label="팝업" style={{ backgroundColor: 'grey', color: '#fff', opacity: 0.7, borderRadius: 0 }} />}</Stack>
                                                </span>
                                            )}

                                            <span
                                                className="position-absolute top-0 end-0 bg-dark opacity-50 z-1 m-0 text-white"
                                                style={{
                                                    padding: '3px',
                                                    margin: '1px 1px 0 0',
                                                    zIndex: '2',
                                                    cursor: 'pointer',
                                                    isolation: 'isolate',
                                                }}
                                                onClick={() => {
                                                    setThumnailDelete(item.thumnail_idx);
                                                }}
                                            >
                                                <CloseIcon />
                                            </span>
                                        </ImageListItem>
                                    </>
                                ))}
                        </ImageList>
                    </div>

                    <div className="row pt-2">
                        <div className="px-0">
                            <button type="button" className="btn-sm btn-secondary waves-effect waves-light border-0" onClick={() => navigate(`/admin/notice`)}>
                                취소
                            </button>
                            {isCurrentIdx && (
                                <>
                                    <button type="button" className="btn-sm btn-danger waves-effect waves-light ms-1 border-0" onClick={() => setDelete()}>
                                        삭제
                                    </button>
                                </>
                            )}

                            <button type="button" className="btn-sm btn-primary waves-effect waves-light float-end border-0" onClick={() => setUpdate()}>
                                {isCurrentIdx && isCurrentIdx ? '수정' : '등록'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? <Loading /> : null}
            <ToastContainer
                position="top-right" // 알람 위치 지정
                autoClose={3000} // 자동 off 시간
                hideProgressBar={false} // 진행시간바 숨김
                closeOnClick // 클릭으로 알람 닫기
                rtl={false} // 알림 좌우 반전
                pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                draggable // 드래그 가능
                pauseOnHover // 마우스를 올리면 알람 정지
                theme="light"
                // limit={1} // 알람 개수 제한
            />

            {isViewerOpen && (
                <ImageViewer
                    src={isImages}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: 'rgba(0,0,0,0.9)',
                        zIndex: '2',
                    }}
                    closeOnClickOutside={true}
                />
            )}
        </>
    );
};

export { OrderAdminNoticeUpdatePage };
