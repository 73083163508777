// Loading.js
import React, { useEffect, useState } from 'react';
import './intro.css';
import { useNavigate } from 'react-router-dom';
import rainbowbg from './../../assets/images/rainbow_bg.png';
import txt_1 from './../../assets/images/txt/rainbowlab_txt_01.png';
import txt_2 from './../../assets/images/txt/rainbowlab_txt_02.png';
import txt_3 from './../../assets/images/txt/rainbowlab_txt_03.png';
import txt_4 from './../../assets/images/txt/rainbowlab_txt_04.png';
import txt_5 from './../../assets/images/txt/rainbowlab_txt_05.png';
import txt_6 from './../../assets/images/txt/rainbowlab_txt_06.png';
import txt_7 from './../../assets/images/txt/rainbowlab_txt_07.png';
import txt_8 from './../../assets/images/txt/rainbowlab_txt_08.png';
import txt_9 from './../../assets/images/txt/rainbowlab_txt_09.png';
import txt_10 from './../../assets/images/txt/rainbowlab_txt_10.png';
import { ToastContainer, toast } from 'react-toastify';
import { Button, TextField } from '@mui/material';
import apiClient from '../../apiClient';

const OrderNoticeIntroPage = () => {
    const navigate = useNavigate();
    const [isPassChk, setPassChk] = useState('');
    const [isPassVal, setPassVal] = useState('');

    async function login() {
        const payload = { passVal: isPassVal };

        const { data } = await apiClient.post('/notice/login', payload);
        if (data.result) {
            localStorage.setItem(`accessTokenOrderNotice`, data.token);
            navigate('/notice');
        } else {
            toast.error('정보가 올바르지 않습니다.');
        }
    }

    async function getLoginStatus() {
        let { data } = await apiClient.get(`/notice/logininfo`);
        if (data && data.result === true) {
            setPassChk(data.chk);
        }
    }

    async function goView() {
        if (isPassChk === 'N') {
            navigate('/notice');
        }
    }

    useEffect(() => {
        getLoginStatus();
    }, []);

    useEffect(() => {
        //getLoginStatus();
        if (isPassChk === 'N') {
            let timer = setTimeout(() => {
                navigate('/notice');
            }, 2500);
        }
    }, [isPassChk]);

    const activeEnter = (e) => {
        if (e.key === 'Enter') {
            login();
        }
    };
    return (
        <div className="lab-intro-warap text-center" onClick={() => goView()}>
            {/*  */}
            <div>
                <div className="lab-intro-logo">
                    <img src={rainbowbg} className="animate__animated animate__bounce" />
                </div>
                <div className="lab-intro-text mt-4">
                    <img src={txt_1} style={{ '--i': '1' }} />
                    <img src={txt_2} style={{ '--i': '2' }} />
                    <img src={txt_3} style={{ '--i': '3' }} />
                    <img src={txt_4} style={{ '--i': '4' }} />
                    <img src={txt_5} style={{ '--i': '5' }} />
                    <img src={txt_6} style={{ '--i': '6' }} />
                    <img src={txt_7} style={{ '--i': '7' }} />
                    <img src={txt_8} style={{ '--i': '8' }} />
                    <img src={txt_9} style={{ '--i': '9' }} />
                    <img src={txt_10} style={{ '--i': '10' }} />
                </div>
                {isPassChk === 'Y' && (
                    <div>
                        <div className="lab-intro-input mt-4" style={{ width: '200px', margin: '0 auto 0 auto', '--i': '11' }}>
                            <div>
                                <TextField
                                    type="password"
                                    autoComplete="new-password"
                                    value={isPassVal}
                                    onChange={(e) => {
                                        setPassVal(e.target.value);
                                    }}
                                    label={'CODE'}
                                    sx={{
                                        '& .Mui-focused': { color: '#fff' },
                                        '& .MuiInputLabel-outlined': {
                                            color: '#fff',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#fff',
                                        },
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: 'center' },
                                            maxLength: 10,
                                        },
                                    }}
                                    focused
                                    onKeyDown={(e) => activeEnter(e)}
                                />
                            </div>
                            <div className="mt-1">
                                <Button variant="contained" size="large" sx={{ width: '100%' }} onClick={() => login()}>
                                    Login
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer
                position="top-right" // 알람 위치 지정
                autoClose={3000} // 자동 off 시간
                hideProgressBar={false} // 진행시간바 숨김
                closeOnClick // 클릭으로 알람 닫기
                rtl={false} // 알림 좌우 반전
                pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                draggable // 드래그 가능
                pauseOnHover // 마우스를 올리면 알람 정지
                theme="light"
                // limit={1} // 알람 개수 제한
            />
        </div>
    );
};

export { OrderNoticeIntroPage };
