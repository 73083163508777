import React, { useState } from 'react';
import apiClient from '../../apiClient';
import { ToastContainer, toast } from 'react-toastify';

const CodeInputBox = ({ title, codeGubun, parentIdx, categoryRegStatus }) => {
    const [codeText, setCodeTxt] = useState('');
    const [resultMsg, setResultMsg] = useState('');

    const handleSubmit = async () => {
        const param = {
            gubun: codeGubun,
            isUsed: 1,
            parent_idx: parentIdx,
            txt: codeText,
        };

        if (!codeText) {
            alert(commonfn.getMsg('empty'));
            return;
        }

        const { data } = await apiClient.post('/code/update/', param);
        if (data.result) {
            toast.success(commonfn.getMsg('insert-ok'), { autoClose: 1500 });
            categoryRegStatus(3);
        } else {
            alert(commonfn.getMsg(data.msg));
        }
    };

    return (
        <div className="mb-4 p-2 rounded-3 mt-2" style={{ backgroundColor: '#e2e3e5', width: '80%' }}>
            <p className="mb-1 text-black font-13">{title}</p>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control p-1"
                    onChange={(e) => {
                        setCodeTxt(e.target.value);
                    }}
                />
                <button
                    type="button"
                    className="btn btn-danger btn-xs waves-effect waves-light me-1 ms-1"
                    onClick={() => {
                        categoryRegStatus(0);
                    }}
                >
                    취소
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-xs waves-effect waves-light"
                    onClick={handleSubmit}
                >
                    등록
                </button>
            </div>
        </div>
    );
};

export default CodeInputBox;
