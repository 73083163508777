import React, { useState, useEffect, useRef, useContext } from 'react';
import apiClient from '../../apiClient';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import { PostPopup } from '../../components/PostPopup';
import { comma } from '../../common';
import 'moment/locale/ko'; // Locale Setting
import moment from 'moment-timezone';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { AppContext } from '../../App';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import * as XLSX from 'xlsx-js-style';

const OrderListPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    //alert(searchParams.get('flag'));
    const { isGlobalItem, setGlobalItems } = useContext(AppContext);
    const inputsZipcode = useRef();
    const inputsAddress = useRef();
    const inputsAddressDetail = useRef();
    const inputsMsgContent = useRef();

    const printRef = useRef();
    const editDefault = {
        idx: 0,
        recipient_name: false,
        recipient_phone: false,
        address: false,
        buycnt: false,
        memo: false,
        oldValue: '',
        newValue: '',
    };

    const [isDetailEdit, setDetailEdit] = useState(editDefault);

    const [isOrderDeatailModal, setOrderDeatailModal] = useState(false);
    const [isOrderListModal, setOrderListModal] = useState(false);
    const [isMsgModal, setMsgModal] = useState(false);
    const [isMsgModalStatus, setMsgModalStatus] = useState();
    const [isMsgContent, setMsgContent] = useState('');
    const [isFlag, setFlag] = useState(searchParams.get('flag') ? searchParams.get('flag') : 0);
    const [isBuyDetail, setBuyDetail] = useState();
    const [isDaumPopupOpenPostcode, setDaumPopupOpenPostcode] = useState(false);
    const [isOrerPrintList, setOrerPrintList] = useState();

    const [isSearchFilterTp, setSearchFilterTp] = useState();
    const [isSearchFilterValue, setSearchFilterValue] = useState();

    const [isPageInfo, setPageInfo] = useState({ pageCount: 5, totalCount: 0, pageSize: 10 });
    const [isOrderList, setOrderList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isCheckItems, setCheckItems] = useState([]);

    //엑셀
    const downloadExcel = async () => {
        const params = {
            page: isParams.page,
            itemsPerPage: 9999,
            flag: isParams.flag,
        };

        if (isParams.searchTp != '') {
            params.searchTp = isParams.searchTp;
        }

        if (isParams.searchValue != '') {
            params.searchValue = isParams.searchValue;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/order/list`, { params });
        if (data && data.result === true) {
            const rowData = dataRemake(data.orderList.items);
            const header = isListHeader.map((row) => ({
                v: row,
                s: {
                    font: {
                        bold: true,
                        color: { rgb: 'ffffff' },
                    },
                    alignment: {
                        horizontal: 'center',
                    },
                    fill: {
                        type: 'pattern',
                        patternType: 'solid',
                        fgColor: { rgb: '3a3b3c' },
                    },
                },
            }));

            let rows = [];
            rows.push(header);

            for (const idx in rowData) {
                //console.log(idx);
                rows.push([
                    {
                        v: rowData[idx].receipt_no, //주문번호
                    },
                    {
                        v: rowData[idx].buyer_name, //구매자명
                    },
                    {
                        v: rowData[idx].buyer_email, //구매자ID
                    },
                    {
                        v: rowData[idx].buyer_phone, //구매자연락처
                    },
                    {
                        v: rowData[idx].buyer_office_name, //지점
                    },
                    {
                        v: rowData[idx].recipient_name, //수취인명
                    },
                    {
                        v: rowData[idx].recipient_phone, //수취인연락처
                    },
                    {
                        v: rowData[idx].delivery_flag, //주문상태
                    },
                    {
                        v: rowData[idx].delivery_detail_flag, //주문세부상태
                    },
                    {
                        v: rowData[idx].createdAt, //결제일
                    },
                    {
                        v: rowData[idx].updatedAt, //발주확인일
                    },
                    {
                        v: rowData[idx].enddAt, //발송처리일
                    },
                    {
                        v: rowData[idx].canceldAt, //주문취소일
                    },
                    {
                        v: rowData[idx].product_code, //상품번호
                    },
                    {
                        v: rowData[idx].product_name, //상품명
                    },
                    {
                        v: rowData[idx].option_name, //옵션정보
                    },
                    {
                        v: rowData[idx].buycnt, //수량
                        t: 'n',
                        z: '#,##0_-;-#,##0_-;0;General',
                    },
                    {
                        v: rowData[idx].sell_price, //옵션가격
                        t: 'n',
                        z: '#,##0_-;-#,##0_-;0;General',
                    },
                    {
                        v: rowData[idx].total_price, //상품별 총 주문금액
                        t: 'n',
                        z: '#,##0_-;-#,##0_-;0;General',
                    },
                    {
                        v: rowData[idx].address, //수취인주소
                    },
                    {
                        v: rowData[idx].zipcode, //우편번호
                    },
                ]);
            }

            const worksheet = XLSX.utils.aoa_to_sheet(rows);
            worksheet['!cols'] = [{ width: 20 }, { width: 10 }, { width: 20 }, { width: 25 }, { width: 10 }, { width: 15 }, { width: 20 }, { width: 15 }, { width: 22 }, { width: 18 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 20 }, { width: 30 }, { width: 30 }, { width: 10 }, { width: 18 }, { width: 25 }, { width: 30 }, { width: 10 }];
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'my_sheet');

            XLSX.writeFile(workbook, 'json_to_excel.xlsx');
        }
        setLoading(false);
    };

    const isListHeader = ['주문번호', '구매자명', '구매자ID', '구매자연락처', '지점', '수취인명', '수취인연락처', '주문상태', '주문세부상태', '결제일', '발주확인일', '발송처리일', '주문취소일', '상품번호', '상품명', '옵션정보', '수량', '옵션가격', '상품별 총 주문금액', '수취인주소', '우편번호'];
    //엑셀 끝

    //검색
    const [isParams, setParams] = useState({
        page: 1,
        flag: isFlag,
        searchTp: '',
        searchValue: '',
    });

    //페이징에서 호출
    const setPageStatus = function (pg) {
        setParams({
            ...isParams,
            page: pg,
        });
    };

    const handleOnFcous = (e) => {
        setDetailEdit({
            ...isDetailEdit,
            oldValue: e.target.value.trim(),
        });
    };

    async function handleOnBlur(e) {
        console.log(e.target.id);
        if (isDetailEdit.oldValue != e.target.value) {
            //update

            if (e.target.value.trim() == '') {
                alert('입력값이 올바르지 않습니다.');
                return;
            }

            const params = {
                idx: isBuyDetail.idx,
                [e.target.id]: e.target.value.trim(),
            };

            let url = '/order/detail/update';
            if (e.target.id == 'recipient_phone') {
                const regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
                if (!regExp.test(e.target.value)) {
                    alert('전화번호 형식이 올바르지 않습니다.\n010-XXXX-XXXXX 형식으로 입력하세요.');
                    return;
                }
            }

            if (e.target.id == 'buycnt') {
                const regExp = /^[0-9]+$/;
                if (!regExp.test(e.target.value)) {
                    alert('숫자만 입력 가능합니다.');
                    return;
                }

                params.idx = isBuyDetail.tbd_idx;
                params.option_detail_idx = isBuyDetail.option_idx;
                url = '/order/detail/updateBuyCnt';
            }
            const { data } = await apiClient.post(url, params);
            if (data && data.result === true) {
                getOrderDetail(isBuyDetail.tbd_idx);
                if (!data.updateinfo.rtnResult) {
                    if (data.updateinfo.rtnMsg == 'overCnt') {
                        alert('재고 수량 이상 변경할수 없습니다.');
                    }
                }
            }
        }

        setDetailEdit({
            ...isDetailEdit,
            [e.target.id]: false,
        });
    }

    async function setAddressUpdate() {
        if (inputsAddress.current.value == '' || inputsZipcode.current.value == '') {
            alert('우편번호 검색을 버튼 클릭후 주소를 입력하세요.');
            return;
        }

        if (inputsAddressDetail.current.value == '') {
            alert('상세주소를 입력하세요.');
            inputsAddressDetail.current.focus();
        }

        const params = {
            idx: isBuyDetail.idx,
            address: inputsAddress.current.value,
            address_detail: inputsAddressDetail.current.value,
            zipcode: inputsZipcode.current.value,
        };

        const { data } = await apiClient.post(`/order/detail/update`, params);
        if (data && data.result === true) {
            getOrderDetail(isBuyDetail.tbd_idx);
        }

        setDetailEdit({
            ...isDetailEdit,
            address: false,
        });
    }

    const setFlagStatus = function (status) {
        setParams({
            ...isParams,
            flag: status,
            page: 1,
        });
        setCheckItems([]);
    };

    const handleModal = {
        OrderDetailClose: () => {
            setOrderDeatailModal(false);
            setDetailEdit(editDefault);
            getOrderList();
        },
        MsgClose: () => {
            setMsgModal(false);
        },
        OrdeerListClose: () => {
            setOrderListModal(false);
        },
    };

    const handleDaum = {
        ZipCodePopupOpen: () => {
            setDaumPopupOpenPostcode(true);
        },
        Close: () => {
            setDaumPopupOpenPostcode(false);
        },
        SelectAddress: (data) => {
            inputsZipcode.current.value = data.zonecode;
            inputsAddress.current.value = data.address;

            setDaumPopupOpenPostcode(false);
        },
    };
    // 검색 끝

    function dataRemake(data) {
        const arrayItem = [];
        let num = 1;
        let diffkey = '';
        data.map((e, idx) => {
            if (diffkey == '') {
                diffkey = e.receipt_no;
            }

            if (diffkey != e.receipt_no) {
                num++;
                diffkey = e.receipt_no;
            }

            const makeList = {
                num: num,
                idx: e.idx,
                receipt_no: e.receipt_no,
                buyer_name: e.buyer_name,
                buyer_email: e.buyer_email,
                buyer_phone: e.buyer_phone,
                buyer_office_name: e.buyer_office_name,
                recipient_name: e.recipient_name,
                recipient_phone: e.recipient_phone,
                delivery_flag: e.flag == 0 || e.flag == 1 || e.flag == 7 ? '발송대기' : e.flag == 8 || e.flag == 9 || e.flag == 10 ? '주문취소' : e.flag == 2 ? '발송완료' : '',
                delivery_detail_flag: e.flag == 1 ? (e.msg != '' && e.canceldAt ? '발주확인(취소거부)' : '발주확인') : e.flag == 7 ? '취소요청' : e.flag == 8 ? '취소완료(구매자)' : e.flag == 9 ? '취소완료(관리자)' : e.flag == 10 ? '취소완료(승인)' : e.flag == 2 ? '' : '신규주문',
                createdAt: e.createdAt ? moment(e.createdAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                updatedAt: e.updatedAt ? moment(e.updatedAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                enddAt: e.enddAt ? moment(e.enddAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                canceldAt: e.canceldAt ? moment(e.canceldAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : '',
                product_code: e.product_code,
                product_name: e.product_name,
                option_name: e.option_name,
                buycnt: e.buycnt,
                sell_price: e.sell_price,
                total_price: e.total_price,
                address: e.address + ' ' + e.address_detail,
                zipcode: e.zipcode,
            };
            arrayItem.push(makeList);
        });

        return arrayItem;
    }

    async function getOrderList() {
        const params = {
            page: isParams.page,
            itemsPerPage: isPageInfo.pageSize,
            flag: isParams.flag,
        };

        if (isParams.searchTp != '') {
            params.searchTp = isParams.searchTp;
        }

        if (isParams.searchValue != '') {
            params.searchValue = isParams.searchValue;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/order/list`, { params });
        if (data && data.result === true) {
            setOrderList(dataRemake(data.orderList.items));
            setPageInfo({ ...isPageInfo, totalCount: data.orderList.itemsLength });
            setGlobalItems('reload');
        }
        setLoading(false);
    }

    async function setCheckedAllFn(checked) {
        let arryCopy = [];
        if (checked) {
            isOrderList.map((item) => {
                arryCopy.push(item.idx);
            });
        } else {
            arryCopy = [];
        }
        setCheckItems(arryCopy);
    }

    async function handleSerach() {
        setParams({
            ...isParams,
            page: 1,
            searchTp: isSearchFilterTp,
            searchValue: isSearchFilterValue,
        });
    }
    async function handleSearchClear() {
        setParams({
            ...isParams,
            page: 1,
            searchTp: '',
            searchValue: '',
        });
    }

    async function setCheckedFn(checked, idx) {
        let arryCopy = [...isCheckItems];
        if (checked) {
            arryCopy.push(idx);
        } else {
            arryCopy = arryCopy.filter((el) => el !== idx);
        }

        setCheckItems(arryCopy);
    }

    async function setOrderPrint(status) {
        if (isCheckItems.length === 0 && status !== 'all') {
            alert('선택된 데이터가 없습니다.');
            return;
        }

        const params = {
            status: status,
            statusList: isCheckItems,
        };

        setLoading(true);
        const { data } = await apiClient.post(`/order/printList`, params);
        if (data && data.result === true) {
            const dataitem = data.orderList.items;

            const orderList = [];
            const orderInData = {};

            dataitem.map((e, idx) => {
                if (orderList.findIndex((e2) => e2.receipt_no === e.receipt_no) === -1) {
                    //console.log(e.receipt_no, idx);
                    orderList.push({
                        receipt_no: e.receipt_no,
                        list: [],
                        zipcode: '',
                        address: '',
                        address_detail: '',
                        buyer_name: '',
                        buyer_phone: '',
                        recipient_name: '',
                        recipient_phone: '',
                    });
                }
            });

            orderList.map((e, idx) => {
                //console.log(dataitem.filter((e2) => e2.receipt_no === e.receipt_no));
                const findidx = orderList.findIndex((e2) => e2.receipt_no === e.receipt_no);
                const filterData = dataitem.filter((e2) => e2.receipt_no === e.receipt_no);
                orderList[findidx].list = filterData;
                orderList[findidx].zipcode = filterData[0].zipcode;
                orderList[findidx].address = filterData[0].address;
                orderList[findidx].address_detail = filterData[0].address_detail;
                orderList[findidx].buyer_name = filterData[0].buyer_name;
                orderList[findidx].buyer_phone = filterData[0].buyer_phone;
                orderList[findidx].recipient_name = filterData[0].recipient_name;
                orderList[findidx].recipient_phone = filterData[0].recipient_phone;
            });

            setOrerPrintList(orderList);
            setOrderListModal(true);
        }
        setLoading(false);
    }

    async function setUpdateStatus(status) {
        if (isCheckItems.length === 0 && status !== 'all') {
            alert('선택된 데이터가 없습니다.');
            return;
        }
        if (confirm((status == 'all' ? '신규주문 모두' : '선태하신 주문을') + ` 발주 확인 처리 하시겠습니까?`)) {
            const params = {
                flag: 1,
                status: status,
            };
            params['statusList'] = isCheckItems;
            setLoading(true);
            const { data } = await apiClient.post(`/order/status/update`, params);
            if (data && data.result === true) {
                setCheckedAllFn(false);
                getOrderList();
            }
            setLoading(false);
        }
    }

    async function setUpdateStatusSend(status) {
        if (isCheckItems.length === 0 && status !== 'all') {
            alert('선택된 데이터가 없습니다.');
            return;
        }
        if (confirm((status == 'all' ? '신규주문 모두' : '선태하신 주문을') + ` 발송 처리 하시겠습니까?`)) {
            const params = {
                flag: 2,
                status: status,
            };
            params['statusList'] = isCheckItems;
            setLoading(true);
            const { data } = await apiClient.post(`/order/status/updateSend`, params);
            if (data && data.result === true) {
                setCheckedAllFn(false);
                getOrderList();
            }
            setLoading(false);
        }
    }

    async function setUpdateCancelConfirm(status) {
        if (isCheckItems.length === 0) {
            alert('선택된 데이터가 없습니다.');
            return;
        }

        setMsgModalStatus(status);
        if (status == 'confirm' && status != 'refusal') {
            //사용자가 취소하고 관리자가 승인하는 경우 그냥 통과
            setUpdatCancel(status);
        } else {
            setMsgModal(true);
        }
        return;
        //}
    }

    async function setUpdatCancel(status) {
        if (status != 'confirm') {
            if (isMsgContent == '') {
                alert('사유를 입력하세요.');
                inputsMsgContent.current.focus();
                return;
            }
        }
        const params = {
            statusList: isCheckItems,
            msg: isMsgContent,
        };
        setLoading(true);
        let url = `/order/status/cancelUpdate`;
        if (status == 'refusal') {
            url = `/order/status/cancelUpdate/refusal`;
        }
        const { data } = await apiClient.post(url, params);
        if (data && data.result === true) {
            setMsgModal(false);
            setCheckedAllFn(false);
            getOrderList();
        }
        setLoading(false);
    }

    async function getOrderDetail(idx) {
        try {
            if (!idx) {
                return;
            }
            setLoading(true);
            const { data } = await apiClient.get('/order/detail/' + idx);
            if (data.result) {
                setBuyDetail(data.detail.order);
                setOrderDeatailModal(true);
            }
            setLoading(false);
        } catch (e) {
            alert(e);
        }
    }

    useEffect(() => {
        //상단 카운트 메뉴
        setFlag(isParams.flag);
        setSearchFilterTp(isParams.searchTp);
        setSearchFilterValue(isParams.searchValue);

        getOrderList();
    }, [isParams]);

    useEffect(() => {
        isSearchFilterTp === '' && setSearchFilterValue('');
    }, [isSearchFilterTp]);

    useEffect(() => {
        setGlobalItems('reload');
    }, []);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-2 col-md-6 col-sm-6">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isFlag == 0 ? 'border-primary border border-2' : '')} onClick={() => setFlagStatus(0)}>
                                            <div className="text-center">
                                                <h5>
                                                    <i className="mdi mdi-collage h4 me-1"></i> 신규주문(발주 전)
                                                </h5>
                                                <h2 className="fw-normal text-black text-primary" data-plugin="counterup">
                                                    {isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.Order : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-6">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isFlag == 1 ? 'border-primary border border-2' : '')} onClick={() => setFlagStatus(1)}>
                                            <div className="text-center">
                                                <h5>
                                                    <i className="mdi mdi-cart-outline h4 me-1"></i> 신규주문(발주 후)
                                                </h5>
                                                <h2 className="fw-normal text-black" data-plugin="counterup">
                                                    {isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.Ready : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-6">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isFlag == 7 ? 'border-primary border border-2' : '')} onClick={() => setFlagStatus(7)}>
                                            <div className="text-center">
                                                <h5>
                                                    <i className="mdi mdi-cart-remove h4 me-1"></i> 발송전 취소요청
                                                </h5>
                                                <h2 className="fw-normal text-danger" data-plugin="counterup">
                                                    {isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.ReqCancel : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-6">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isFlag == 8 ? 'border-primary border border-2' : '')} onClick={() => setFlagStatus(8)}>
                                            <div className="text-center">
                                                <h5>
                                                    <i className="mdi mdi-cart-remove h4 me-1"></i> 취소완료(최근7일)
                                                </h5>
                                                <h2 className="fw-normal text-danger" data-plugin="counterup">
                                                    {isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.Cancel : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-md-6 col-sm-6">
                                    <div className="card">
                                        <div className={`card-body widget-user p-1 border-2 hand order-menu-hover ` + (isFlag == 2 ? 'border-primary border border-2' : '')} onClick={() => setFlagStatus(2)}>
                                            <div className="text-center">
                                                <h5>
                                                    <i className="mdi mdi-cart-arrow-up h4 me-1"></i> 배송완료(최근30일)
                                                </h5>
                                                <h2 className="fw-normal text-dark" data-plugin="counterup">
                                                    {isGlobalItem && isGlobalItem.isStatistics ? isGlobalItem.isStatistics.End : 0}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-light">
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">상세조건</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill me-2" style={{ width: '140px' }}>
                                                                    <select className="form-select form-select-sm" value={isSearchFilterTp} onChange={(e) => setSearchFilterTp(e.target.value)}>
                                                                        <option value="">전체</option>
                                                                        <option value="recipient_name">수취인명</option>
                                                                        <option value="buyer_name">구매자명</option>
                                                                        <option value="buyer_phone">구매자연락처</option>
                                                                        <option value="recipient_phone">수취인연락처</option>
                                                                        <option value="email">구매자ID</option>
                                                                        <option value="receipt_no">주문번호</option>
                                                                        <option value="product_code">상품번호</option>
                                                                    </select>
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className={`form-control form-control-sm`} style={isSearchFilterTp === '' ? { backgroundColor: '#f7f7f7' } : {}} value={isSearchFilterValue} readOnly={isSearchFilterTp === '' ? true : false} onChange={(e) => setSearchFilterValue(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-auto">
                                                <div className="d-flex align-items-center flex-row">
                                                    <div className="flex-fill me-2" style={{ width: '140px' }}>
                                                        <select className="form-select form-select-sm" value={isSelectSale} onChange={(e) => setSelectSale(e.target.value)}>
                                                            <option value="">지점선택</option>
                                                            <option value="1">발송대기</option>
                                                            <option value="0">배송완료</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="justify-content-end row">
                                                <div className="col-12 text-center">
                                                    <button type="submit" className="btn btn-dark waves-effect waves-light" onClick={() => handleSerach()}>
                                                        검색
                                                    </button>
                                                    <button type="submit" className="btn btn-secondary waves-effect waves-light ms-1" onClick={() => handleSearchClear()}>
                                                        초기화
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {isSearchTp}
                <br />
                {isSearchValue}
                <br />
                {JSON.stringify(isCheckItems)}
                <br />
                {JSON.stringify(isFlag)} */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-inline-flex fw-normal h4">주문목록({isPageInfo && isPageInfo.totalCount ? isPageInfo.totalCount : 0})</div>
                                            </div>
                                            <hr className="p-0 m-0 mb-2" />
                                            <div className="row mb-2 gap-1 ps-2">
                                                {isFlag == '0' && (
                                                    <>
                                                        <div className="col-auto p-0">
                                                            <button type="button" className="btn btn-sm btn-outline-secondary waves-effect" onClick={() => setUpdateStatus('check')}>
                                                                선택 발주확인
                                                            </button>
                                                        </div>
                                                        <div className="col-auto p-0">
                                                            <button type="button" className="btn btn-sm btn-outline-secondary waves-effect" onClick={() => setUpdateStatus('all')}>
                                                                일괄 발주확인
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                                {isFlag == '1' && (
                                                    <>
                                                        <div className="col-auto p-0">
                                                            <button type="button" className="btn btn-sm btn-secondary waves-effect" onClick={() => setUpdateStatusSend('check')}>
                                                                선택 발송처리
                                                            </button>
                                                        </div>
                                                        <div className="col-auto p-0">
                                                            <button type="button" className="btn btn-sm btn-secondary waves-effect" onClick={() => setUpdateStatusSend('all')}>
                                                                일괄 발송처리
                                                            </button>
                                                        </div>
                                                        <div className="col-auto p-0">
                                                            <button type="button" className="btn btn-sm btn-outline-secondary waves-effect" onClick={() => setOrderPrint('check')}>
                                                                선택 주문서 출력
                                                            </button>
                                                        </div>
                                                        <div className="col-auto p-0">
                                                            <button className="btn btn-sm btn-outline-secondary waves-effect" onClick={() => setOrderPrint('all')}>
                                                                일괄 주문서 출력
                                                            </button>
                                                            {/* <button type="button" onClick={() => setOrderPrint('all')}></button> */}
                                                        </div>
                                                    </>
                                                )}
                                                {isFlag != '2' && isFlag != '7' && isFlag != '8' && isFlag != '9' ? (
                                                    <div className="col-auto p-0">
                                                        <button type="button" className="btn btn-sm btn-outline-danger waves-effect" onClick={() => setUpdateCancelConfirm('')}>
                                                            판매자 직접 취소처리
                                                        </button>
                                                    </div>
                                                ) : (
                                                    isFlag == '7' && (
                                                        <>
                                                            <div className="col-auto p-0">
                                                                <button type="button" className="btn btn-sm btn-outline-danger waves-effect" onClick={() => setUpdateCancelConfirm('confirm')}>
                                                                    취소 승인
                                                                </button>
                                                            </div>
                                                            <div className="col-auto p-0">
                                                                <button type="button" className="btn btn-sm btn-outline-danger waves-effect" onClick={() => setUpdateCancelConfirm('refusal')}>
                                                                    취소 거부
                                                                </button>
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                                <div className="col text-end p-0 me-2">
                                                    <button className="btn btn-sm btn-dark waves-effect" onClick={() => downloadExcel()}>
                                                        엑셀 다운로드
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-bordered mb-0" id="inline-editable">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th className="text-center text-nowrap">
                                                                <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isOrderList && isCheckItems.length == isOrderList.length ? true : false} onChange={(e) => setCheckedAllFn(e.target.checked)} />
                                                            </th>
                                                            {isListHeader.map((item) => (
                                                                <>
                                                                    <th className="text-center text-nowrap">{item}</th>
                                                                </>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {isOrderList &&
                                                            isOrderList.map((item) => (
                                                                <tr key={item.idx} className={`text-dark`} style={item.num % 2 == 0 ? { 'background-color': 'rgb(206 212 218 / 37%)' } : {}}>
                                                                    <td className="text-center align-middle">
                                                                        <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isCheckItems.length > 0 && isCheckItems.includes(item.idx) ? true : false} onChange={(e) => setCheckedFn(e.target.checked, item.idx)} />
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        <span className="link" onClick={() => getOrderDetail(item.idx)}>
                                                                            {item.receipt_no}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_email}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_phone}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.buyer_office_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.recipient_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.recipient_phone}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.delivery_flag}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.delivery_detail_flag}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.createdAt}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.updatedAt}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.enddAt}</td>
                                                                    <td className="text-center align-middle text-nowrap">{item.canceldAt}</td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        <Link className="link" target="_blank" to={process.env.REACT_APP_FRONT_URL + '/' + item.product_code}>
                                                                            {item.product_code}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="text-start text-nowrap align-middle">{item.product_name}</td>
                                                                    <td className="text-start text-nowrap align-middle">{item.option_name}</td>
                                                                    <td className="text-center text-nowrap align-middle">{comma(item.buycnt)}</td>
                                                                    <td className="text-end text-nowrap align-middle">{comma(item.sell_price)}원</td>
                                                                    <td className="text-end text-nowrap align-middle">{comma(item.total_price)}원</td>
                                                                    <td className="text-start text-nowrap align-middle">{item.address}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.zipcode}</td>
                                                                </tr>
                                                            ))}
                                                        {isOrderList && isOrderList.length == 0 && (
                                                            <>
                                                                <tr>
                                                                    <td colSpan="22" className="text-center">
                                                                        내역이 없습니다.
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12 col-sm-12 col-lg-2"></div>
                                                <div className="col-12 col-lg-8">{isPageInfo.totalCount ? <Pagination isPageInfo={isPageInfo} isCurPage={isParams.page} setPageStatus={setPageStatus} /> : ''}</div>
                                                <div className="col-12 col-sm-12 col-lg-2 text-end"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}

            <Modal show={isMsgModal} onHide={handleModal.MsgClose} backdrop="static" size="lg" centered>
                <Modal.Header closeButton className="p-0 m-0">
                    <Modal.Title>
                        <h4 className="ps-2">{isMsgModalStatus && isMsgModalStatus == 'refusal' ? '거부 사유' : '취소 사유'}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-2">
                        <textarea
                            rows={5}
                            className="form-control p-2 text-dark"
                            placeholder="사유를 입력하세요."
                            maxLength={500}
                            onChange={(e) => {
                                setMsgContent(e.target.value);
                            }}
                            ref={inputsMsgContent}
                        ></textarea>
                        <div className="mt-2">
                            <div className="text-end">{isMsgContent ? isMsgContent.length : 0} / 500</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <div className="flex-fill text-end">
                        <button type="submit" form="hook-form" className="btn btn-dark rounded-1 me-2" onClick={() => setUpdatCancel(isMsgModalStatus)}>
                            저장
                        </button>
                        <button className="btn btn-secondary rounded-1" onClick={handleModal.MsgClose}>
                            취소
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={isOrderDeatailModal} onHide={handleModal.OrderDetailClose} backdrop="static" size="lg" centered>
                <Modal.Header className="p-0 m-0 pe-2 order-detail-popup" closeButton>
                    <Modal.Title className="p-0 m-0">
                        <div className="row p-0">
                            <div className="col">
                                <div className="bg-dark p-2">
                                    <h5 className="text-white">상품주문정보 조회</h5>
                                </div>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                    }}
                >
                    <div className="row">
                        <div className="col">
                            <div>
                                <h5>주문상세 정보</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="border px-2 mb-2">
                                    <h5>
                                        상품주문번호: {isBuyDetail && isBuyDetail.receipt_no}
                                        {isBuyDetail && isBuyDetail.flag == 1 && isBuyDetail.msg == '' && isBuyDetail.canceldAt == null && <span className="text-dark ms-2">발주확인</span>}
                                        {isBuyDetail && isBuyDetail.flag == 1 && isBuyDetail.msg != '' && isBuyDetail.canceldAt && <span className="text-dark ms-2">발주확인(취소거부)</span>}
                                        {isBuyDetail && isBuyDetail.flag == 2 && <span className="text-dark ms-2">발송완료</span>}
                                        {isBuyDetail && isBuyDetail.flag == 7 && <span className="text-danger ms-2">취소요청</span>}
                                        {isBuyDetail && isBuyDetail.flag == 8 && <span className="text-danger ms-2">취소완료(구매자요청)</span>}
                                        {isBuyDetail && isBuyDetail.flag == 9 && <span className="text-danger ms-2">취소완료(관리자)</span>}
                                        {isBuyDetail && isBuyDetail.flag == 10 && <span className="text-danger ms-2">취소완료(승인)</span>}
                                    </h5>
                                </div>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                상품명
                                            </td>
                                            <td className="bg-white" colSpan="3" width="80%">
                                                {isBuyDetail && isBuyDetail.product_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                구매자명
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && isBuyDetail.buyer_name}
                                            </td>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                구매자 ID
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && isBuyDetail.buyer_email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                상품주문상태
                                            </td>
                                            <td className="bg-white" width="30%">
                                                결제완료
                                                {isBuyDetail && isBuyDetail.flag}
                                            </td>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                연락처
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && isBuyDetail.buyer_phone}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="ms-1">
                                    <h5>배송지 정보</h5>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 정보 변경시 같은 주문번호 주문건은 모두 변경됩니다.
                                    </p>
                                </div>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2" width="20%">
                                                수취인명
                                            </td>
                                            <td className="bg-white align-middle" width="30%">
                                                {isDetailEdit.recipient_name ? <input type="text" maxLength="30" className="form-control form-control-sm" id="recipient_name" defaultValue={isBuyDetail && isBuyDetail.recipient_name} onFocus={handleOnFcous} onBlur={handleOnBlur} /> : isBuyDetail && isBuyDetail.recipient_name}
                                                {!isDetailEdit.recipient_name && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                recipient_name: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className="bg-light text-nowrap align-middle pe-2" width="20%">
                                                연락처
                                            </td>
                                            <td className="bg-white align-middle" width="30%">
                                                {isDetailEdit.recipient_phone ? <input type="text" maxLength="20" className="form-control form-control-sm" placeholder="010-XXXX-XXXX" id="recipient_phone" defaultValue={isBuyDetail && isBuyDetail.recipient_phone} onFocus={handleOnFcous} onBlur={handleOnBlur} /> : isBuyDetail && isBuyDetail.recipient_phone}
                                                {!isDetailEdit.recipient_phone && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                recipient_phone: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2">지점</td>
                                            <td className="bg-white" colSpan="3">
                                                {isBuyDetail && isBuyDetail.buyer_office_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2">배송지</td>
                                            <td className="bg-white" colSpan="3">
                                                {isDetailEdit.address ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="d-flex align-items-center flex-row">
                                                                    <div className="flex-fill me-2">
                                                                        <input type="text" className="form-control form-control-sm" placeholder="우편번호" ref={inputsZipcode} readOnly onClick={handleDaum.ZipCodePopupOpen} />
                                                                    </div>
                                                                    <div className="flex-fill">
                                                                        <button className="btn btn-sm btn-secondary rounded-1" onClick={handleDaum.ZipCodePopupOpen}>
                                                                            검색
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-8"></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <input type="text" className="form-control form-control-sm mb-1 mt-1" placeholder="주소" ref={inputsAddress} readOnly onClick={handleDaum.ZipCodePopupOpen} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <input type="text" className="form-control form-control-sm" ref={inputsAddressDetail} placeholder="상세주소" maxLength="50" />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    isBuyDetail && isBuyDetail.address + ' ' + isBuyDetail.address_detail + ' 우편번호 : ' + isBuyDetail.zipcode
                                                )}
                                                {!isDetailEdit.address && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                address: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    isBuyDetail &&
                                                    isBuyDetail.flag <= 1 && (
                                                        <>
                                                            <button
                                                                className="btn btn-sm btn-danger rounded-1 mt-1"
                                                                onClick={() =>
                                                                    setDetailEdit({
                                                                        ...isDetailEdit,
                                                                        address: false,
                                                                    })
                                                                }
                                                            >
                                                                취소
                                                            </button>
                                                            <button className="btn btn-sm btn-secondary rounded-1 float-end mt-1" onClick={() => setAddressUpdate()}>
                                                                저장
                                                            </button>
                                                        </>
                                                    )
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap align-middle pe-2">배송메모</td>
                                            <td className="bg-white" colSpan="3">
                                                {isDetailEdit.memo ? <input type="text" className="form-control form-control-sm" id="memo" defaultValue={isBuyDetail && isBuyDetail.memo} onFocus={handleOnFcous} onBlur={handleOnBlur} maxLength="200" /> : isBuyDetail && isBuyDetail.memo}{' '}
                                                {!isDetailEdit.memo && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                memo: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="ms-1">
                                    <h5>주문정보</h5>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 수량변경시 판매상품 재고 및 구매자 주문내역도 변경됩니다.
                                    </p>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 상품 판매중지, 전시중지 상관없이 옵션 판매중인(사용여부) 재고 재고수량을 표시합니다.
                                    </p>
                                    <p className="text-primary mb-0">
                                        <i className="fe-check"></i> 현재 옵션 재고 수량 : <span className="text-danger">{isBuyDetail && isBuyDetail.product_option_cnt ? isBuyDetail.product_option_cnt : 0}개</span>
                                    </p>
                                </div>
                                <table className="table table-bordered mb-2">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap" width="20%">
                                                총주문금액
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isBuyDetail && comma(isBuyDetail.buycnt * isBuyDetail.sell_price)}원
                                            </td>
                                            <td className="bg-light text-nowrap" width="20%">
                                                구매수량
                                            </td>
                                            <td className="bg-white" width="30%">
                                                {isDetailEdit.buycnt ? <input type="text" className="form-control form-control-sm" id="buycnt" defaultValue={isBuyDetail && isBuyDetail.buycnt} onFocus={handleOnFcous} onBlur={handleOnBlur} maxLength="10" /> : isBuyDetail && comma(isBuyDetail.buycnt)}
                                                {!isDetailEdit.buycnt && isBuyDetail && isBuyDetail.flag <= 1 ? (
                                                    <Link
                                                        onClick={() =>
                                                            setDetailEdit({
                                                                ...isDetailEdit,
                                                                buycnt: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fe-edit ms-2"></i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2 fw-bold h5">
                                                <div className="d-flex">
                                                    <div className="flex-fill text-start">{isBuyDetail && isBuyDetail.product_name}</div>
                                                    <div className="flex-fill text-end ">상품번호 : {isBuyDetail && isBuyDetail.product_code}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap pe-2">
                                                <div className="fw-bold">
                                                    <div className="d-flex">
                                                        <div className="flex-fill text-start"> {isBuyDetail && isBuyDetail.option_name}</div>
                                                        <div className="flex-fill text-end ">옵션번호 : {isBuyDetail && isBuyDetail.option_idx}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-1">
                                                    <div className="flex-fill text-start">
                                                        수량 {isBuyDetail && comma(isBuyDetail.buycnt)} x {comma(isBuyDetail && isBuyDetail.sell_price)}원
                                                    </div>
                                                    <div className="flex-fill text-end ">{isBuyDetail && comma(isBuyDetail.buycnt * isBuyDetail.sell_price)}원</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="ms-1">
                                    <h5>주문이력</h5>
                                </div>
                                <table className="table table-bordered mb-2">
                                    <tbody>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2" width="20%">
                                                주문
                                            </td>
                                            <td className="bg-white">{isBuyDetail && isBuyDetail.createdAt && isBuyDetail.createdAt}</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2">발주확인</td>
                                            <td className="bg-white">{isBuyDetail && isBuyDetail.updatedAt && isBuyDetail.updatedAt}</td>
                                        </tr>
                                        {isBuyDetail && isBuyDetail.canceldAt && (
                                            <>
                                                <tr>
                                                    <td className="bg-light text-nowrap pe-2">취소처리일</td>
                                                    <td className="bg-white">
                                                        {isBuyDetail && isBuyDetail.canceldAt && isBuyDetail.canceldAt}
                                                        {isBuyDetail && isBuyDetail.msg && ' / 사유 : ' + isBuyDetail.msg}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <td className="bg-light text-nowrap pe-2">발송완료</td>
                                            <td className="bg-white">{isBuyDetail && isBuyDetail.enddAt && isBuyDetail.enddAt}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <div className="flex-fill text-start">
                        {/* <button type="submit" form="hook-form" className="btn btn-danger rounded-1" onClick={() => setDeleteSelectData()}>
                            수정
                        </button> */}
                    </div>
                    <div className="flex-fill text-end">
                        <button className="btn btn-secondary rounded-1" onClick={handleModal.OrderDetailClose}>
                            닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <PostPopup isDaumPopupOpenPostcode={isDaumPopupOpenPostcode} handleDaum={handleDaum} />

            <Modal show={isOrderListModal} onHide={handleModal.OrdeerListClose} backdrop="static" size="lg" centered>
                <Modal.Header closeButton className="p-0 m-0 pe-2">
                    <Modal.Title>
                        <h4 className="ps-2">주문서 출력</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                    }}
                    className="p-0 m-0"
                >
                    <div ref={printRef} className="p-2">
                        <div>
                            <p>선택하신 {isOrerPrintList && comma(isOrerPrintList.length)}개 주문건의 주문서를 출력합니다.</p>
                        </div>
                        <table className="table table-bordered border-secondary-50">
                            <thead>
                                <tr>
                                    <td rowSpan="2" className="text-center align-middle p-1 bg-light">
                                        번호
                                    </td>
                                    <td className="text-center align-middle p-1 py-2 bg-light">주문번호 / 주문일</td>
                                    <td className="text-center align-middle p-1 bg-light">상품번호/상품명/옵션</td>
                                    <td className="text-center align-middle p-1 bg-light">상품금액(수량)</td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle p-1 py-2 bg-light">수취인(구매자명)</td>
                                    <td className="text-center align-middle p-1 bg-light">배송지 주소(배송메모)</td>
                                    <td className="text-center align-middle p-1 bg-light">수취인 연락처(구매자 연락처)</td>
                                </tr>
                            </thead>
                            <tbody>
                                {isOrerPrintList &&
                                    isOrerPrintList.map((item, idx) => (
                                        <>
                                            {item.list.map((item2, idx2) => (
                                                <>
                                                    <tr key={item.idx} className={`text-dark bg-white`}>
                                                        {idx2 === 0 && (
                                                            <td className="text-center align-middle p-1 py-2" rowSpan={item.list.length > 1 && idx2 === 0 ? item.list.length + 1 : item.list.length === 1 ? 2 : 1}>
                                                                {idx + 1}
                                                            </td>
                                                        )}
                                                        {idx2 === 0 && (
                                                            <td className="text-center align-middle p-1 py-2" rowSpan={item.list.length > 1 && idx2 === 0 ? item.list.length : 1}>
                                                                <p className="text-primary fw-bold p-0 m-0">{item.receipt_no}</p>
                                                                <p className="p-0 m-0">{item2.createdAt && moment(item2.createdAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm')}</p>
                                                            </td>
                                                        )}
                                                        <td className="text-start align-middle p-1 py-2 ps-2">
                                                            <p className="p-0 m-0">
                                                                {item2.product_code}, {item2.product_name}
                                                            </p>
                                                            <p className="p-0 m-0">
                                                                <span className="text-success">옵션</span> : {item2.option_name}
                                                            </p>
                                                        </td>
                                                        <td className="text-center align-middle p-1 py-2">
                                                            <p className="p-0 m-0">{comma(item2.total_price)}원</p>
                                                            <p className="p-0 m-0">({comma(item2.buycnt)}개)</p>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}

                                            <tr className={`text-dark bg-white`}>
                                                <td className="text-center align-middle p-1 py-2">
                                                    {item.recipient_name} ({item.buyer_name})
                                                </td>
                                                <td className="text-start align-middle p-1 py-2 ps-2">
                                                    ({item.zipcode}) {item.address}
                                                    {item.address_detail}
                                                </td>
                                                <td className="text-center align-middle p-1 py-2">
                                                    {item.recipient_phone}
                                                    <br />({item.buyer_phone})
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <div className="flex-fill text-end">
                        <ReactToPrint trigger={() => <button className="btn btn-dark rounded-1 me-2">인쇄</button>} content={() => printRef.current} />
                        <button className="btn btn-secondary rounded-1" onClick={handleModal.OrdeerListClose}>
                            취소
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { OrderListPage };
