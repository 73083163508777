// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Placeholder_root__UwK47 {
    background-color: #1967d2;
    height: 2px;
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryManagerPage/Placeholder.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,MAAM;AACV","sourcesContent":[".root {\n    background-color: #1967d2;\n    height: 2px;\n    position: absolute;\n    right: 0;\n    transform: translateY(-50%);\n    top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Placeholder_root__UwK47`
};
export default ___CSS_LOADER_EXPORT___;
