import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import { Link, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import 'moment/locale/ko'; // Locale Setting
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';

const BoardPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') != null ? searchParams.get('page') : 1;
    const searchValue = searchParams.get('sv') != null ? searchParams.get('sv') : '';
    const searchView = searchParams.get('sv2') != null ? searchParams.get('sv2') : '';
    const checkedDefault = {
        isViewY: true,
        isViewN: true,
    };

    const [isPageInfo, setPageInfo] = useState({ pageCount: 5, totalCount: 0, pageSize: 10 });
    const [isBoardList, setBoardList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isCheckItems, setCheckItems] = useState([]);

    const [isParams, setParams] = useState({
        page: page,
        searchValue: searchValue,
        searchView: searchView,
        board_name: 'notice',
    });

    const [isSearchFilterValue, setSearchFilterValue] = useState();
    const [isSearchFilterAllChecked, setSearchFilterAllChecked] = useState(true);
    const [isSearchFilterChecked, setSearchFilterChecked] = useState(checkedDefault);

    //페이징에서 호출
    const setPageStatus = function (pg) {
        setParams({
            ...isParams,
            page: pg,
        });
    };

    const handleFilterAllCheck = (checked) => {
        setSearchFilterChecked((prevState) => {
            const nextState = {};
            Object.keys(prevState).forEach((key) => {
                nextState[key] = checked;
            });
            return nextState;
        });
        setSearchFilterAllChecked(checked);
    };

    const handleFilterCheck = (item) => {
        setSearchFilterChecked({
            ...isSearchFilterChecked,
            [item]: !isSearchFilterChecked[item],
        });
    };

    useEffect(() => {
        const checkFilteer = Object.keys(isSearchFilterChecked).filter((key) => {
            return isSearchFilterChecked[key] === false;
        });

        setSearchFilterAllChecked(checkFilteer.length === 0 ? true : false);
    }, [isSearchFilterChecked]);

    async function getBoardList() {
        const params = {
            page: isParams.page,
            itemsPerPage: isPageInfo.pageSize,
        };

        if (isParams.searchValue != '') {
            params.searchValue = isParams.searchValue;
        }

        if (isParams.searchView != '') {
            params.searchView = isParams.searchView;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/board/list/` + isParams.board_name, { params });
        if (data && data.result === true) {
            const totalCount = data.boardList.itemsLength;
            const arrayItem = [];
            data.boardList.items.map((e, idx) => {
                e.num = totalCount - (isParams.page - 1) * isPageInfo.pageSize - idx;
                arrayItem.push(e);
            });
            setBoardList(arrayItem);
            setPageInfo({ ...isPageInfo, totalCount: totalCount });
        }
        setLoading(false);
    }

    const handleSerach = function () {
        setParams({
            ...isParams,
            page: 1,
            searchValue: isSearchFilterValue,
            searchView: JSON.stringify(isSearchFilterChecked),
        });
    };

    const handleSearchClear = function () {
        setParams({
            ...isParams,
            page: 1,
            searchValue: '',
            searchView: JSON.stringify(checkedDefault),
        });
    };

    async function setCheckedFn(checked, idx) {
        let arryCopy = [...isCheckItems];
        if (checked) {
            arryCopy.push(idx);
        } else {
            arryCopy = arryCopy.filter((el) => el !== idx);
        }

        setCheckItems(arryCopy);
    }

    async function setCheckedAllFn(checked) {
        let arryCopy = [];
        if (checked) {
            isBoardList.map((item) => {
                arryCopy.push(item.idx);
            });
        } else {
            arryCopy = [];
        }
        setCheckItems(arryCopy);
    }

    async function setDeleteSelectData(idx) {
        const params = {};

        params['board_name'] = isParams.board_name;
        if (idx) {
            params['deleteList'] = idx;
        } else {
            if (isCheckItems.length === 0) {
                alert('선택된 데이터가 없습니다.');
                return;
            }
            params['deleteList'] = isCheckItems;
        }

        if (confirm('삭제 후 복원이 불가능합니다. 삭제하시겠습니까?')) {
            const { data } = await apiClient.post(`/board/delete`, params);
            if (data && data.result === true) {
                setCheckedAllFn(false);
                getBoardList();
            }
        }
    }

    function goBoardUpdate(idx) {
        searchParams.set('idx', idx);
        searchParams.set('page', isParams.page);
        searchParams.set('sv', isParams.searchValue);
        searchParams.set('sv2', isParams.searchView);
        setSearchParams(searchParams);

        navigate(`/board/update/` + isParams.board_name + `?` + searchParams);
    }

    useEffect(() => {
        setSearchFilterValue(isParams.searchValue);
        if (isParams.searchView) setSearchFilterChecked(JSON.parse(isParams.searchView));

        setSearchParams(''); //파라미터 초기화

        getBoardList();
    }, [isParams]);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-light">
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">검색어</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill" style={{ width: '75px' }}>
                                                                    제목/내용
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className="form-control form-control-sm" id="search" value={isSearchFilterValue} onChange={(e) => setSearchFilterValue(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">노출상태</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2  ps-2">
                                                        <div className="col-auto">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="all" checked={isSearchFilterAllChecked} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterAllCheck(e.target.checked)} />
                                                                <label className="form-check-label ms-1 me-1" style={{ marginTop: '3px' }} htmlFor="all">
                                                                    전체
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isViewY" checked={isSearchFilterChecked.isViewY} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isViewY" style={{ marginTop: '3px' }}>
                                                                    노출중
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" id="isViewN" checked={isSearchFilterChecked.isViewN} style={{ width: '20px', height: '20px' }} onClick={(e) => handleFilterCheck(e.target.id)} />
                                                                <label className="form-check-label ms-1 me-1" htmlFor="isViewN" style={{ marginTop: '3px' }}>
                                                                    노출종료
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {JSON.stringify(isSearchView)} */}
                                                </div>
                                            </div>
                                            <div className="justify-content-end row">
                                                <div className="col-12 text-center">
                                                    <button type="submit" className="btn btn-dark waves-effect waves-light" onClick={() => handleSerach()}>
                                                        검색
                                                    </button>
                                                    <button type="submit" className="btn btn-secondary waves-effect waves-light ms-1" onClick={() => handleSearchClear()}>
                                                        초기화
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-inline-flex fw-normal h4">공지사항</div>
                                                {/* {JSON.stringify(isBoardList)} */}
                                                {/* <div className="d-inline-flex">
                                        <select className="form-select form-select-sm">
                                            <option>정렬</option>
                                            <option>전시중</option>
                                            <option>전시중지</option>
                                        </select>
                                    </div> */}
                                            </div>
                                            <hr className="p-0 m-0 mb-2 mt-2" />
                                            <div className="row mb-2">
                                                <div className="col-auto p-0 ms-2">
                                                    <button type="button" className="btn btn-sm btn-outline-secondary waves-effect" onClick={() => setDeleteSelectData()}>
                                                        선택삭제
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="table-responsive-xl board-wrap">
                                                <table className="table table-bordered mb-0" id="inline-editable">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th className="text-center text-nowrap" width="30px">
                                                                <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isBoardList && isCheckItems.length == isBoardList.length ? true : false} onChange={(e) => setCheckedAllFn(e.target.checked)} />
                                                            </th>
                                                            <th className="text-center text-nowrap" width="80px">
                                                                수정
                                                            </th>
                                                            <th className="text-center text-nowrap" width="80px">
                                                                번호
                                                            </th>
                                                            <th className="text-center text-nowrap" width="90px">
                                                                노출상태
                                                            </th>
                                                            <th className="text-center text-nowrap" style={{ 'min-width': '250px' }}>
                                                                제목
                                                            </th>
                                                            <th className="text-center text-nowrap" width="170px">
                                                                전시 시작일
                                                            </th>
                                                            <th className="text-center text-nowrap" width="170px">
                                                                전시 종료일
                                                            </th>
                                                            <th className="text-center text-nowrap" width="100px">
                                                                조회수
                                                            </th>
                                                            <th className="text-center text-nowrap" width="170px">
                                                                등록일
                                                            </th>
                                                            <th className="text-center text-nowrap" width="80px">
                                                                삭제
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {isBoardList &&
                                                            isBoardList.map((item, idx) => (
                                                                <tr key={item.idx}>
                                                                    <td className="text-center align-middle">
                                                                        <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isCheckItems.length > 0 && isCheckItems.includes(item.idx) ? true : false} onChange={(e) => setCheckedFn(e.target.checked, item.idx)} />
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success btn-sm waves-effect waves-light rounded-0 text-nowrap m-0"
                                                                            onClick={() => {
                                                                                goBoardUpdate(`${item.idx}`);
                                                                            }}
                                                                        >
                                                                            수정
                                                                        </button>
                                                                    </td>
                                                                    <td className="text-center align-middle">{item.num}</td>
                                                                    <td className="text-center align-middle">{item.isView == true ? '노출중' : '노출종료'}</td>
                                                                    <td className="align-middle">
                                                                        {item.isNotice === 1 ? <span className="border px-2 py-1 me-2 bg-primary text-white">중요</span> : ''} {item.title}
                                                                    </td>
                                                                    <td className="text-center align-middle">{item.sdate ? moment(item.sdate).tz('Asia/Seoul').utc().format('YYYY/MM/DD HH:mm') : ''}</td>
                                                                    <td className="text-center align-middle">{item.edate ? moment(item.edate).tz('Asia/Seoul').utc().format('YYYY/MM/DD HH:mm') : ''}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.view_cnt}</td>
                                                                    <td className="text-center text-nowrap align-middle">{moment(item.createdAt).format('YYYY/MM/DD HH:mm')}</td>
                                                                    <td className="text-center align-middle">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success btn-sm waves-effect waves-light rounded-0 text-nowrap m-0"
                                                                            onClick={() => {
                                                                                setDeleteSelectData(item.idx);
                                                                            }}
                                                                        >
                                                                            삭제
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12 col-sm-12 col-lg-2"></div>
                                                <div className="col-12 col-lg-8">{isPageInfo.totalCount ? <Pagination isPageInfo={isPageInfo} isCurPage={isParams.page} setPageStatus={setPageStatus} /> : ''}</div>
                                                <div className="col-12 col-sm-12 col-lg-2 text-end">
                                                    <Link to="/board/update/notice" type="button" className="btn btn-dark waves-effect waves-light text-nowrap">
                                                        게시물 등록
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}
        </>
    );
};

export { BoardPage };
