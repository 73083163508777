import React, { useContext, useEffect, useRef, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const PostPopup = ({ isDaumPopupOpenPostcode, handleDaum }) => {
    //const handleClose = () => setPostModalShow(false);

    return (
        <Modal show={isDaumPopupOpenPostcode} onHide={handleDaum.Close} centered contentClassName="border shadow-lg border-1 border-dark">
            <Modal.Header closeButton className="py-0">
                <Modal.Title>
                    <h5>우편번호 검색</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <DaumPostcode
                        onComplete={handleDaum.SelectAddress} // 값을 선택할 경우 실행되는 이벤트
                        autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                        defaultQuery="" // 팝업을 열때 기본적으로 입력되는 검색어
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn_close rounded-1" variant="dark" onClick={handleDaum.Close}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { PostPopup };
