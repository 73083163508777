import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMutation } from 'react-query';
import apiClient from '../../apiClient';
import CodeInputBox from '../../components/CodeInputBox/CodeInputBox';

import styled from 'styled-components';
import UploadDropZone from '@rpldy/upload-drop-zone';
import withPasteUpload from '@rpldy/upload-paste';
import Loading from '../../components/Loading/Loading';
import QuillEditor from '../../components/QuillEditor/QuillEditor';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/ko'; // Locale Setting
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import Layout from '../../components/Layout/Layout';
import { RightSide } from '../../components/RightSide/RightSide';

const BoardUpdatePage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isloading, setLoading] = useState(false);
    const inputDateRef = useRef();
    const inputTitleRef = useRef();
    const quillRef = useRef();

    const idx = searchParams.get('idx');

    const [isBoardTitle, setBoardTitle] = useState(''); //제목
    const [isHtmlContent, setHtmlContent] = useState(''); //에디터 수정
    const [isBoardIdx, setBoardIdx] = useState(idx && idx); //수정시 idx
    const [isNotice, setNotice] = useState(0); //중요공지
    const [isView, setView] = useState(0); //노출기간설정
    const [isDateView, setDateView] = useState(); //전시기간

    const setUpdate = async (e) => {
        if (isView == 1 && isDateView == undefined) {
            alert('노출 기간을 선택해주세요.');
            inputDateRef.current.focus();
            return;
        }
        if (!isBoardTitle) {
            alert('제목을 입력하세요.');
            inputTitleRef.current.focus();
            return;
        }

        if (!isHtmlContent) {
            alert('내용을 입력하세요.');
            quillRef.current.focus();
            return;
        }

        const param = {
            title: isBoardTitle,
            board_name: 'notice',
            isNotice: isNotice,
            isView: isView,
            content: isHtmlContent,
            sdate: isView === 1 ? (isDateView ? moment(isDateView.start).add(9, 'h').format('YYYY-MM-DD HH:mm:00') : '') : null,
            edate: isView === 1 ? (isDateView ? moment(isDateView.end).add(9, 'h').format('YYYY-MM-DD HH:mm:59') : '') : null,
        };

        if (isBoardIdx !== undefined && isBoardIdx !== null) {
            param.idx = isBoardIdx;
            param.updatedAt = moment(new Date()).add(9, 'h');
        }

        setLoading(true);
        const { data } = await apiClient.post('/board/update', param);
        if (data.result) {
            navigate(-1);
        }
        setLoading(false);
    };

    const handleCallbackView = (start, end) => {
        setDateView({ start, end });
    };

    //상세페이지 호출
    async function getBoardInfo(idx) {
        try {
            if (!idx) {
                return;
            }
            setLoading(true);
            const { data } = await apiClient.get('/board/detail/' + idx);
            if (data.result) {
                setNotice(data.detail.board.isNotice ? 1 : 0);
                setView(data.detail.board.isView ? 1 : 0);
                if (data.detail.board.isView == 1) {
                    data.detail.board.sdate != null ? setDateView({ start: moment(data.detail.board.sdate).add(-9, 'h').format('YYYY-MM-DD HH:mm:00'), end: moment(data.detail.board.edate).add(-9, 'h').format('YYYY-MM-DD HH:mm:59') }) : '';
                }
                setBoardTitle(data.detail.board.title);
                setHtmlContent(data.detail.board.content);
            }
            setLoading(false);
        } catch (e) {
            alert(e);
        }
    }

    useEffect(() => {
        getBoardInfo(isBoardIdx);
    }, [isBoardIdx]);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            {/* {JSON.stringify(isDateView)} */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body text-black">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 중요공지 설정
                                                        </label>
                                                        <div>
                                                            <div className="mb-3">
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className={'btn-sm ' + (isNotice === 1 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                        onClick={(e) => {
                                                                            setNotice(1);
                                                                        }}
                                                                    >
                                                                        설정함
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className={'btn-sm ' + (isNotice === 0 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                        onClick={(e) => {
                                                                            setNotice(0);
                                                                        }}
                                                                    >
                                                                        설정안함
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="p-0 m-0 mb-3" />
                                            <div className="row">
                                                <div className="col-12 col-xl-8">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 노출기간 설정
                                                        </label>
                                                        <div>
                                                            <div className="mb-3">
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className={'btn-sm ' + (isView === 1 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                        onClick={(e) => {
                                                                            setView(1);
                                                                        }}
                                                                    >
                                                                        설정함
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className={'btn-sm ' + (isView === 0 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                        onClick={(e) => {
                                                                            setView(0);
                                                                        }}
                                                                    >
                                                                        설정안함
                                                                    </button>
                                                                </div>
                                                                {isView === 1 ? (
                                                                    <div className="mt-2 col-12 col-sm-8 col-md-7 col-xl-7 col-xxl-4">
                                                                        <div className="input-group mb-3 mt-2">
                                                                            <DateRangePicker
                                                                                // onEvent={handleEvent}
                                                                                onCallback={handleCallbackView}
                                                                                initialSettings={{
                                                                                    timePicker: true,
                                                                                    timePicker24Hour: true,
                                                                                    locale: {
                                                                                        cancelLabel: '닫기',
                                                                                        applyLabel: '적용',
                                                                                        format: 'YYYY/M/DD hh:mm',
                                                                                        daysOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
                                                                                        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <button type="button" className="btn btn-dark btn-sm input-group-prepend">
                                                                                    <i className="fe-calendar"></i>
                                                                                </button>
                                                                            </DateRangePicker>
                                                                            <input type="text" className="form-control" ref={inputDateRef} value={(isDateView ? moment(isDateView.start).format('YYYY/MM/DD HH:mm') + ' ~ ' : '') + (isDateView ? moment(isDateView.end).format('YYYY/MM/DD HH:mm') : '')} readOnly="readOnly" />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="p-0 m-0 mb-3" />
                                            <div className="row">
                                                <div className="col-12 col-xl-8">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 제목
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setBoardTitle(e.target.value);
                                                            }}
                                                            ref={inputTitleRef}
                                                            value={isBoardTitle}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 상세페이지
                                                        </label>
                                                        <div>
                                                            <QuillEditor quillRef={quillRef} htmlContent={isHtmlContent} setHtmlContent={setHtmlContent} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div>
                                                    <button type="button" className="btn-sm btn-danger waves-effect waves-light" onClick={() => goList()}>
                                                        취소
                                                    </button>
                                                    <button type="button" className="btn-sm btn-dark waves-effect waves-light float-end" onClick={() => setUpdate()}>
                                                        {isBoardIdx ? '수정' : '등록'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isloading ? <Loading /> : null}
        </>
    );
};

export { BoardUpdatePage };
