import { array } from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//curPage 현재 페이지 번호
//setPage useState 셋
//totalCount 데이터 전체 갯수
//pageCount 페이징 갯수 1~5,6~10
//pageSize 한 페이지에 보여줄 페이지 갯수
const Pagination = ({ isPageInfo, isCurPage, setPageStatus }) => {
    //console.log('isCurPage :' + isCurPage);
    const totalPage = Math.ceil(isPageInfo.totalCount / isPageInfo.pageSize);
    const pageGroup = Math.ceil(isCurPage / isPageInfo.pageCount);
    const firstNum = (pageGroup - 1) * isPageInfo.pageCount + 1;
    let lastNum = pageGroup * isPageInfo.pageCount;
    const firstPage = () => {
        setPageStatus(1);
    };

    const lastPage = () => {
        setPageStatus(totalPage);
    };

    const prevPage = () => {
        if (firstNum - 1 == 0) {
            setPageStatus(1);
        } else {
            setPageStatus(firstNum - 1);
        }
    };

    const nextPage = () => {
        if (lastNum + 1 > totalPage) {
            setPageStatus(totalPage);
        } else {
            setPageStatus(lastNum + 1);
        }
    };

    // if (isPageInfo.totalCount <= isPageInfo.pageSize) {
    //     firstNum = 1;
    // }

    if (totalPage <= lastNum) {
        lastNum = totalPage;
    }

    // if (totalPage === firstNum) {
    //     lastNum = totalPage;
    // }

    let page = [];
    for (let i = firstNum; i <= lastNum; i++) {
        page.push(i);
    }

    return (
        <nav aria-label="Page navigation example">
            {/* {'현재 페이지 번호 : ' + curPage + ' / 전체 페이지 게시글 갯수 : ' + totalCount + ' / 페이지 갯수 : ' + pageCount + ' / 페이지당 게시글수 : ' + pageSize} */}
            <ul className="pagination justify-content-center">
                <li className={`page-item ${isCurPage == 1 ? 'disabled' : ''}`}>
                    <Link className="page-link" onClick={() => firstPage()}>
                        <i className="fe-chevrons-left"></i>
                    </Link>
                </li>
                <li className={`page-item ${isCurPage == 1 ? 'disabled' : ''}`}>
                    <Link className="page-link" onClick={() => prevPage()}>
                        <i className="fe-chevron-left"></i>
                    </Link>
                </li>
                {page.map((n) => (
                    <li className="page-item" key={n}>
                        <Link
                            className={`page-link ${n == isCurPage ? 'bg-dark text-white' : ''}`}
                            onClick={() => {
                                setPageStatus(n);
                            }}
                        >
                            {n}
                        </Link>
                    </li>
                ))}

                <li className={`page-item ${isCurPage == totalPage ? 'disabled' : ''}`}>
                    <Link
                        className="page-link"
                        onClick={() => {
                            nextPage();
                        }}
                    >
                        <i className="fe-chevron-right"></i>
                    </Link>
                </li>
                <li className={`page-item ${isCurPage == totalPage ? 'disabled' : ''}`}>
                    <Link
                        className="page-link"
                        onClick={() => {
                            lastPage();
                        }}
                    >
                        <i className="fe-chevrons-right"></i>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
