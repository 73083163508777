// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    height: 100%;
    background: radial-gradient(#2f2f2f, #000);
}

.lab-intro-warap {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    margin-top: -140px;
}

.lab-intro-warap .lab-intro-logo img {
    width: 170px;
}

.lab-intro-warap .lab-intro-text {
    margin-top: 20px;
}

.lab-intro-warap .lab-intro-text img {
    height: 30px;
    opacity: 0;
    transform: translateY(-500px);
    animation: bouncetxt 0.6s ease-out forwards;
    animation-delay: calc(0.1s * var(--i));
}

.lab-intro-warap .lab-intro-input {
    transform: translateY(-2500px);
    animation: bouncetxt 0.6s ease-out forwards;
    animation-delay: calc(0.1s * var(--i));
}

@keyframes bouncetxt {
    0% {
        opacity: 0;
        transform: translateY(-300px);
    }

    60% {
        opacity: 1;
        transform: translateY(20px);
    }

    80% {
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/OrderNoticeIntroPage/intro.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,0CAA0C;AAC9C;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,6BAA6B;IAC7B,2CAA2C;IAC3C,sCAAsC;AAC1C;;AAEA;IACI,8BAA8B;IAC9B,2CAA2C;IAC3C,sCAAsC;AAC1C;;AAEA;IACI;QACI,UAAU;QACV,6BAA6B;IACjC;;IAEA;QACI,UAAU;QACV,2BAA2B;IAC/B;;IAEA;QACI,4BAA4B;IAChC;;IAEA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ","sourcesContent":["body {\n    height: 100%;\n    background: radial-gradient(#2f2f2f, #000);\n}\n\n.lab-intro-warap {\n    position: absolute;\n    top: 50%;\n    width: 100%;\n    text-align: center;\n    margin-top: -140px;\n}\n\n.lab-intro-warap .lab-intro-logo img {\n    width: 170px;\n}\n\n.lab-intro-warap .lab-intro-text {\n    margin-top: 20px;\n}\n\n.lab-intro-warap .lab-intro-text img {\n    height: 30px;\n    opacity: 0;\n    transform: translateY(-500px);\n    animation: bouncetxt 0.6s ease-out forwards;\n    animation-delay: calc(0.1s * var(--i));\n}\n\n.lab-intro-warap .lab-intro-input {\n    transform: translateY(-2500px);\n    animation: bouncetxt 0.6s ease-out forwards;\n    animation-delay: calc(0.1s * var(--i));\n}\n\n@keyframes bouncetxt {\n    0% {\n        opacity: 0;\n        transform: translateY(-300px);\n    }\n\n    60% {\n        opacity: 1;\n        transform: translateY(20px);\n    }\n\n    80% {\n        transform: translateY(-20px);\n    }\n\n    100% {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
