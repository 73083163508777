import { React, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../App';

const LeftSide = () => {
    const location = useLocation();
    const { isGlobalItem, setGlobalItems } = useContext(AppContext);

    useEffect(() => {
        console.log(location.pathname);
    }, []);
    return (
        <>
            <div className="left-side-menu">
                <div className="h-100" data-simplebar>
                    {/* <div className="user-box text-center">
                        <img src="assets/images/users/user-1.jpg" alt="user-img" title="Mat Helme" className="rounded-circle img-thumbnail avatar-md" />
                        <div className="dropdown">
                            <a href="#" className="user-name dropdown-toggle h5 mt-2 mb-1 d-block" data-bs-toggle="dropdown" aria-expanded="false">
                                Nowak Helme
                            </a>
                            <div className="dropdown-menu user-pro-dropdown">
                                <a href="javascript:;" className="dropdown-item notify-item">
                                    <i className="fe-user me-1"></i>
                                    <span>My Account</span>
                                </a>

                                <a href="javascript:;" className="dropdown-item notify-item">
                                    <i className="fe-settings me-1"></i>
                                    <span>Settings</span>
                                </a>

                                <a href="javascript:;" className="dropdown-item notify-item">
                                    <i className="fe-lock me-1"></i>
                                    <span>Lock Screen</span>
                                </a>

                                <a href="javascript:;" className="dropdown-item notify-item">
                                    <i className="fe-log-out me-1"></i>
                                    <span>Logout</span>
                                </a>
                            </div>
                        </div>

                        <p className="text-muted left-user-info">Admin Head</p>

                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a href="#" className="text-muted left-user-info">
                                    <i className="mdi mdi-cog"></i>
                                </a>
                            </li>

                            <li className="list-inline-item">
                                <a href="#">
                                    <i className="mdi mdi-power"></i>
                                </a>
                            </li>
                        </ul>
                    </div> */}

                    <div id="sidebar-menu">
                        <ul id="side-menu">
                            <li className={`${location.pathname === '/' ? 'menuitem-active' : ''}`}>
                                <Link to="/">
                                    <i className="mdi mdi-view-dashboard-outline"></i>
                                    <span> Dashboard </span>
                                </Link>
                            </li>

                            <li className={`${location.pathname === '/member' ? 'menuitem-active' : ''}`}>
                                <Link to="/member">
                                    <i className="mdi mdi-account-search-outline"></i>
                                    <span> 회원관리 </span>
                                </Link>
                            </li>

                            <li className={`${location.pathname === '/order' || location.pathname === '/orderTotal' ? 'menuitem-active' : ''}`}>
                                <Link to="#order" data-bs-toggle="collapse">
                                    <i className="mdi mdi-order-bool-descending"></i>
                                    {isGlobalItem && isGlobalItem.isStatistics && isGlobalItem.isStatistics.Order > 0 && <span className="badge bg-primary rounded-pill float-end me-3">{isGlobalItem.isStatistics.Order}</span>}
                                    <span> 주문관리 </span>
                                    <span className="menu-arrow"></span>
                                </Link>
                                <div className={`collapse ` + (location.pathname === '/order' || location.pathname === '/orderTotal' ? 'show' : '')} id="order">
                                    <ul className="nav-second-level">
                                        <li>
                                            <Link to="/order" className={`${location.pathname === '/order' ? 'active' : ''}`}>
                                                신규주문
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/orderTotal" className={`${location.pathname === '/orderTotal' ? 'active' : ''}`}>
                                                주문조회
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className={`${location.pathname === '/product' || location.pathname === '/product/update' || location.pathname === '/categoryManager' ? 'menuitem-active' : ''}`}>
                                <Link to="#product" data-bs-toggle="collapse">
                                    <i className="mdi mdi-clipboard-outline"></i>
                                    <span> 상품관리 </span>
                                    <span className="menu-arrow"></span>
                                </Link>
                                <div className={`collapse ` + (location.pathname === '/product' || location.pathname === '/product/update' || location.pathname === '/categoryManager' ? 'show' : '')} id="product">
                                    <ul className="nav-second-level">
                                        <li>
                                            <Link to="/product" className={`${location.pathname === '/product' ? 'active' : ''}`}>
                                                상품 조회/수정
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/product/update" className={`${location.pathname === '/product/update' ? 'active' : ''}`}>
                                                상품등록
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/categoryManager" className={`${location.pathname === '/categoryManager' ? 'active' : ''}`}>
                                                분류관리(카테고리)
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className={`${location.pathname === '/board/notice' || location.pathname === '/board/qna' ? 'menuitem-active' : ''}`}>
                                <Link to="#board" data-bs-toggle="collapse">
                                    <i className="mdi mdi-bulletin-board"></i>
                                    {isGlobalItem && isGlobalItem.isStatistics && isGlobalItem.isStatistics.Qna > 0 && <span className="badge bg-primary rounded-pill float-end me-3">{isGlobalItem.isStatistics.Qna}</span>}
                                    <span> 게시판관리 </span>
                                    <span className="menu-arrow"></span>
                                </Link>
                                <div className={`collapse ` + (location.pathname === '/board/notice' || location.pathname === '/board/qna' ? 'show' : '')} id="board">
                                    <ul className="nav-second-level">
                                        <li>
                                            <Link to="/board/notice" className={`${location.pathname === '/board/notice' ? 'active' : ''}`}>
                                                공지사항
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/board/qna" className={`${location.pathname === '/board/qna' ? 'active' : ''}`}>
                                                1:1문의
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className={`${location.pathname === '/setting' ? 'menuitem-active' : ''}`}>
                                <Link to="/setting">
                                    <i className="mdi mdi-hammer-screwdriver"></i>
                                    <span> 사이트 설정 </span>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    onClick={() => {
                                        alert('준비중입니다.');
                                    }}
                                >
                                    <i className="mdi mdi-chart-box-outline"></i>
                                    <span> 통계 </span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="clearfix"></div>
                </div>
            </div>
        </>
    );
};
export { LeftSide };
