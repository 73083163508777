import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import Loading from '../../components/Loading/Loading';
import 'moment/locale/ko'; // Locale Setting
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { ImageList, ImageListItem } from '@mui/material';
import { TransformWrapper, TransformComponent } from '@pronestor/react-zoom-pan-pinch';
import 'react-photo-album/rows.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Companylogo from './../../assets/images/rainbow-logo3.png';
import CheckIcon from '@mui/icons-material/Check';
import XlsIcon from './../../assets/images/xls_icon.png';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createBrowserHistory } from 'history';

const OrderNoticePage = () => {
    const divRef = useRef();
    const navigate = useNavigate();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const [isNoticeList, setNoticeList] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [isImages, setImages] = useState([]);
    const [isCurrentIdx, setCurrentIdx] = useState();
    const [isCurrentDt, setCurrentDt] = useState();
    const [isDate, setDate] = useState([]);

    const [isSelectImage, setSelectImage] = useState('');
    const [isSelectImageIdx, setSelectImageIdx] = useState('');
    const [isSelectOriName, setSelectOriName] = useState('');
    const [isAttachFile, setAttachFile] = useState({});
    const [isNoticePopupIdx, setNoticePopupIdx] = useState('');
    const [isShowImagePopup, setShowImagePopup] = useState(false);

    async function getNoticeList(idx_) {
        if (idx_ === undefined) {
            idx_ = 0;
        }
        setLoading(true);

        let { data } = await apiClient.post(`/notice/list`, { idx: idx_, token: localStorage.getItem('accessTokenOrderNotice') });
        if (data && data.result === true) {
            const arrayItem = [];
            const imagesArray = [];
            const dateArray = [];

            data.list.items.map((e) => {
                dateArray.push({ idx: e.idx, dt: e.dt });
                if (e.idx === data.selectedIdx) {
                    setCurrentDt(e.dt);
                }
            });

            data.imageItems.map((e, itemIdx) => {
                imagesArray.push({ id: itemIdx, image: e.file_path, origin_name: e.origin_name, notice_idx: e.notice_idx, thumnail_idx: e.thumnail_idx });
            });

            setCurrentIdx(data.selectedIdx);
            setImages(imagesArray);
            setNoticeList(arrayItem);
            setDate(dateArray);
            setAttachFile(data.xlsFile ? data.xlsFile : '');

            try {
                const popupInfo = imagesArray.filter((item) => item.thumnail_idx === data.popupIdx);
                //alert(JSON.stringify(popupInfo));
                setNoticePopupIdx(popupInfo.length > 0 ? popupInfo[0] : '');
            } catch (e) {
                console.log(e);
            }

            if (data.token === '') {
                localStorage.removeItem(`accessTokenOrderNotice`);
            } else {
                localStorage.setItem(`accessTokenOrderNotice`, data.token);
            }
        } else {
            if (data.msg === 'token error') {
                //alert('세션이 종료되어 로그인 페이지로 이동합니다.');
                navigate('/');
                return;
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        getNoticeList();
    }, []);

    const handleShowImage = (idx) => () => {
        setSelectImage(isImages[idx].image);
        setSelectOriName(isImages[idx].origin_name);
        setSelectImageIdx(idx);
    };

    const handleCloseImage = () => {
        setSelectImage('');
        setSelectOriName('');
    };

    const setMoveImage = (tp) => {
        let idx = 0;
        if (tp === 'left') {
            idx = isSelectImageIdx - 1;
        } else {
            idx = isSelectImageIdx + 1;
        }

        if (idx === -1 || isImages.length === idx) {
            return;
        }

        setSelectImage(isImages[idx].image);
        setSelectOriName(isImages[idx].origin_name);
        setSelectImageIdx(idx);
    };

    useEffect(() => {
        if (isSelectImage) {
            setShowImagePopup(true);
        } else {
            setShowImagePopup(false);
        }
    }, [isSelectImage]);

    useEffect(() => {
        if (divRef.current) {
            const width = divRef.current.offsetWidth;
            const height = divRef.current.offsetHeight;
            setDimensions({ width, height });
        }
    }, []);

    const [isShowNoticePopup, setShowNoticePopup] = useState('');

    const todayPopupCookie = localStorage.getItem('todayPopup');
    const todayPopupIdxCookie = localStorage.getItem('todayPopup_idx');

    useEffect(() => {
        if (isNoticePopupIdx === '') return;
        const today = new Date();
        const handleMainPop = () => {
            if (todayPopupCookie && todayPopupCookie > today) {
                if (todayPopupIdxCookie != isNoticePopupIdx.thumnail_idx) {
                    setShowNoticePopup(true);
                }
                return;
            }
            if (!todayPopupCookie || todayPopupCookie < today) {
                if (isNoticePopupIdx.thumnail_idx !== '') {
                    setShowNoticePopup(true);
                }
            }
        };
        window.setTimeout(handleMainPop, 500); // 1초 뒤 실행
    }, [todayPopupCookie, isNoticePopupIdx]);

    const closeTodayPop = () => {
        let expires = new Date();
        expires = expires.setHours(23, 59, 59, 0); //오늘 23시 59분 59초로 설정
        localStorage.setItem('todayPopup', expires);
        localStorage.setItem('todayPopup_idx', isNoticePopupIdx.thumnail_idx);
        setShowNoticePopup(false);
    };

    const history = createBrowserHistory();
    history.push('/notice');
    useEffect(() => {
        const listenBackEvent = () => {
            handleCloseImage();
            setShowNoticePopup(false);
        };
        const historyEvent = history.listen(({ action }) => {
            if (action === 'POP') {
                listenBackEvent();
            }
        });
        return historyEvent;
    }, []);

    return (
        <>
            {isCurrentIdx && (
                <div className="bg-white sticky-top p-2 pt-1 pb-1 border-bottom border-1 border-secondary">
                    <div className="d-flex justify-content-between">
                        <div className="bg-white lab-logo-txt">
                            <img src={Companylogo} className="notice-logo align-bottom" style={{ marginBottom: '3px' }} />
                            <span className="align-bottom text-dark h5 ms-1">Rainbow</span>
                            <span className="align-bottom h5" style={{ color: '#ef4266' }}>
                                Lab
                            </span>
                        </div>
                        <div>
                            {isAttachFile && isAttachFile ? (
                                <>
                                    <Link to={isAttachFile.file_path} download={isAttachFile.origin_name}>
                                        <img src={XlsIcon} className="notice-logo" /> <span className="text-dark">발주서</span>
                                    </Link>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="container-fluid h-100 pb-4" ref={divRef}>
                <div className="gallery-list">
                    <div className="row justify-content-md-start p-2">
                        <ImageList variant="" cols={3} gap={7}>
                            {isImages.map((item, idx) => (
                                <>
                                    <ImageListItem key={item.image}>
                                        <img srcSet={`${item.image}`} src={`${item.image}`} alt={item.title} loading="lazy" className="rounded border border-2 border-dark gallery-img" onClick={handleShowImage(idx)} style={{ width: '100%', overflow: 'hidden', 'object-position': 'top' }} />
                                    </ImageListItem>
                                </>
                            ))}
                        </ImageList>
                    </div>
                </div>
            </div>
            <Modal show={isShowImagePopup} onHide={handleCloseImage}>
                <Modal.Body className="p-0">
                    <div style={{ position: 'relative' }}>
                        <TransformWrapper initialScale={1} limitToBounds={true} alignmentAnimation={{ sizeX: 0, sizeY: 0 }} centerOnInit={false}>
                            {({ zoomIn, zoomOut, resetTransform, zoomToElement }) => (
                                <>
                                    <div style={{ padding: '10px' }}>
                                        <TransformComponent
                                            wrapperStyle={{
                                                height: '93dvh',
                                                width: '100%',
                                            }}
                                        >
                                            <div>
                                                <img src={isSelectImage} width="100%" />
                                            </div>
                                        </TransformComponent>
                                    </div>
                                </>
                            )}
                        </TransformWrapper>

                        <div style={{ position: 'absolute', top: '5px', left: '5px' }} className="fw-bold">
                            {isSelectImageIdx + 1} / {isImages.length}
                        </div>

                        <div style={{ position: 'absolute', bottom: '5px', right: '5px' }}>
                            <Button className="btn_close" variant="secondary" onClick={handleCloseImage}>
                                닫기
                            </Button>
                        </div>

                        {isSelectImageIdx === 0 ? (
                            ''
                        ) : (
                            <ArrowBackIosNewIcon
                                style={{ position: 'absolute', left: '-10px', top: '50%', 'margin-top': '-15px', cursor: 'pointer' }}
                                opacity="0.3"
                                sx={{
                                    fontSize: {
                                        xs: '40px',
                                        md: '50px',
                                        lg: '60px',
                                    },
                                }}
                                onClick={() => setMoveImage('left')}
                            />
                        )}

                        {isImages.length === isSelectImageIdx + 1 ? (
                            ''
                        ) : (
                            <ArrowForwardIosIcon
                                style={{ position: 'absolute', right: '-10px', top: '50%', 'margin-top': '-15px', cursor: 'pointer' }}
                                opacity="0.3"
                                sx={{
                                    fontSize: {
                                        xs: '40px',
                                        md: '50px',
                                        lg: '60px',
                                    },
                                }}
                                onClick={() => setMoveImage('right')}
                            />
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            {isNoticePopupIdx && (
                <Modal show={isShowNoticePopup}>
                    <Modal.Body className="p-0">
                        <div style={{ position: 'relative' }}>
                            <TransformWrapper initialScale={1} limitToBounds={true} alignmentAnimation={{ sizeX: 0, sizeY: 0 }} centerOnInit={false}>
                                {({ zoomIn, zoomOut, resetTransform, zoomToElement }) => (
                                    <>
                                        <div style={{ padding: '10px' }}>
                                            <TransformComponent
                                                wrapperStyle={{
                                                    height: '93dvh',
                                                    width: '100%',
                                                }}
                                            >
                                                <div>
                                                    <img src={isNoticePopupIdx.image} width="100%" />
                                                </div>
                                            </TransformComponent>
                                        </div>
                                    </>
                                )}
                            </TransformWrapper>

                            <div style={{ position: 'absolute', bottom: 0 }} className="bg-dark w-100 p-1">
                                <Button className="btn_close bg-dark" variant="secondary" onClick={() => closeTodayPop()}>
                                    오늘 하루 열지 않기
                                </Button>

                                <Button className="btn_close bg-dark float-end" variant="secondary" onClick={() => setShowNoticePopup(false)}>
                                    닫기
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {isCurrentIdx && (
                <Dropdown className="position-fixed right-0 bottom-0 end-0 m-2" drop={'up'}>
                    <span className="me-2">{isCurrentDt}</span>

                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="rounded-circle p-2">
                        <CalendarMonthIcon style={{ color: '#fff' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ height: '200px', overflowX: 'hidden', overflowY: 'auto', borderRadius: '10px' }}>
                        {isDate.map((item) => (
                            <>
                                <Dropdown.Item className="h5" onClick={() => getNoticeList(item.idx)}>
                                    <div className="d-flex">
                                        <div style={{ width: '27px' }}>{item.idx == isCurrentIdx ? <CheckIcon className="text-blue fw-bold" style={{ marginTop: '-4px' }} /> : ''}</div>
                                        <div>{item.dt}</div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                            </>
                        ))}
                        <i className="bi bi-check"></i>
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {isLoading ? <Loading /> : null}
        </>
    );
};

export { OrderNoticePage };
