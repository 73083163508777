import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ArrowRight, Delete, EditNote, FileCopy, ModeEdit } from '@mui/icons-material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useDragOver } from '@minoru/react-dnd-treeview';
import styles from './CustomNode.module.css';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

export const CustomNode = (props) => {
    const [hover, setHover] = useState(true);
    const { id, droppable, data, text } = props.node;
    const [visibleInput, setVisibleInput] = useState(false);
    const [labelText, setLabelText] = useState(text);
    const indent = props.depth * 24;

    const handleSelect = () => props.onSelect(props.node);

    const handleShowInput = () => {
        setVisibleInput(true);
    };

    const handleCancel = () => {
        setLabelText(text);
        setVisibleInput(false);
    };

    const handleChangeText = (e) => {
        setLabelText(e.target.value);
    };

    const handleSubmit = () => {
        setVisibleInput(false);
        props.onTextChange(id, labelText);
    };

    const handleToggle = (e) => {
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);
    return (
        <div className={styles.root} style={{ paddingInlineStart: indent }}>
            <div className={`${styles.handle} drag-handle`} ref={props.handleRef} data-testid={`drag-handle-${props.node.id}`}>
                <DragHandleIcon />
            </div>
            {/* <div className={styles.labelGridItem}>
                <Typography variant="body2">
                    <span className="h5">{props.node.text}</span>
                </Typography>
            </div> */}
            <div className={styles.labelGridItem}>
                {visibleInput ? (
                    <div className={styles.inputWrapper}>
                        <TextField
                            className={`${styles.textField}
              ${styles.nodeInput}`}
                            value={labelText}
                            inputProps={{ maxLength: 20 }}
                            onChange={handleChangeText}
                        />
                        <IconButton className={styles.editButton} onClick={handleSubmit} disabled={labelText === ''}>
                            <CheckIcon className={styles.editIcon} />
                        </IconButton>
                        <IconButton className={styles.editButton} onClick={handleCancel}>
                            <CloseIcon className={styles.editIcon} />
                        </IconButton>
                    </div>
                ) : (
                    <div>
                        <div className={`tree-node ${styles.root} cursor`} onClick={handleSelect} style={{ paddingInlineStart: indent }}>
                            <Typography variant="body2" className={styles.nodeLabel}>
                                <span className={`h5 ${props.isSelected ? styles.isSelected : ''}`}>{props.node.text}</span>
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
            {!visibleInput && (
                <div className={styles.actionButton}>
                    <IconButton size="small" className={styles.editButton} onClick={handleShowInput}>
                        <EditIcon fontSize="small" className={styles.editIcon} />
                    </IconButton>
                    <IconButton size="small" onClick={() => props.onDelete(id)}>
                        <Delete fontSize="small" />
                    </IconButton>
                </div>
            )}
            {/* <div>
                <div className={styles.actionButton}>
                    <IconButton size="small" onClick={() => props.onDelete(id)}>
                        <Delete fontSize="small" />
                    </IconButton>
                    <IconButton className={styles.editButton} onClick={handleShowInput}>
                         <ModeEdit fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={() => props.onEdit(id)}>
                        <EditNote fontSize="small" />
                    </IconButton>
                </div>
            </div> */}
        </div>
    );
};
