import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import { Link, json, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import 'moment/locale/ko'; // Locale Setting
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material';
import { CustomNode } from '../../pages/CategoryManagerPage/CustomNode';
import { CustomDragPreview } from '../../pages/CategoryManagerPage/CustomDragPreview';
import { Placeholder } from '../../pages/CategoryManagerPage/Placeholder';
import { theme } from '../../pages/CategoryManagerPage/theme';
import { Tree, MultiBackend, getBackendOptions, DndProvider, getDescendants } from '@minoru/react-dnd-treeview';
import styles from '../../pages/CategoryManagerPage/App.module.css';
import { Copyright } from '@mui/icons-material';

const SettingPage = () => {
    const [isLoading, setLoading] = useState(false);

    const termsInputRef = useRef(null);
    const accountInputRef = useRef(null);
    const copyrightInputRef = useRef(null);
    const officeAddInputRef = useRef(null);

    const [isTerms, setTerms] = useState('');
    const [isCopyRight, setCopyRight] = useState('');
    const [isAccount, setAccount] = useState('');
    const [isOfficeList, setOfficeList] = useState('');

    const [isOfficeUpdateIdx, setOfficeUpdateIdx] = useState('');

    const updateData = async function (tp, obj) {
        if (obj.current.value === '') {
            alert('내용을 입력하세요.');
            obj.current.focus();
            return;
        }
        const params = {
            gubun: tp,
            isUsed: 1,
            parent_idx: 0,
            txt: obj.current.value,
        };
        const { data } = await apiClient.post('/setting/update/', params);
        if (data.result) {
            toast.success('등록(수정) 되었습니다.');
        }
    };

    async function getData(gubun, setData, parentIdx) {
        try {
            if (parentIdx === '') return;
            setLoading(true);

            let url = '/code/list/' + gubun + '/' + parentIdx;
            if (gubun === 'office') {
                url = '/office/list/' + gubun;
            }
            const { data } = await apiClient.get(url);
            if (data.result) {
                if (gubun !== 'office') {
                    setData(data.codeList.items[0].txt);
                } else {
                    const codeList = data.codeList.items;
                    const itemList = [];
                    codeList.map((e, idx) => {
                        itemList.push({
                            idx: e.idx,
                            order: e.val,
                            text: e.txt,
                        });
                    });
                    setData(itemList);
                }
            }
            setLoading(false);
        } catch (e) {}
    }

    const setOfficeCancel = async () => {
        setOfficeUpdateIdx('');
        officeAddInputRef.current.value = '';
    };

    const setOfificeDelete = async (item) => {
        if (confirm('[' + item.text + '] 삭제시 해당 지점으로 설정된 사용자는 미분류로 처리됩니다.')) {
            const param = {
                idx: item.idx,
                isUsed: 0,
            };

            const { data } = await apiClient.post('/code/delete/', param);
            if (data.result) {
                setOfficeOrder(item, 'delete');
            }
        }
    };

    const setOfificeUpdateInfo = async (item) => {
        officeAddInputRef.current.value = item.text;
        setOfficeUpdateIdx(item.idx);
    };

    const setOffice = async () => {
        if (officeAddInputRef.current.value === '') {
            alert('지점명을 입력하세요.');
            officeAddInputRef.current.focus();
            return;
        }

        const param = {
            gubun: 'office',
            txt: officeAddInputRef.current.value,
            parent_idx: 0,
            isUsed: 1,
        };

        if (isOfficeUpdateIdx !== '') {
            param.idx = isOfficeUpdateIdx;
        }

        if (isOfficeUpdateIdx === '') {
            param.val = String(isOfficeList.length + 1);
        }

        const { data } = await apiClient.post('/code/update/', param);
        if (data.result) {
            getData('office', setOfficeList, 0);
            setOfficeCancel();
        } else {
            if (data.msg == 'duplication') {
                alert('이미 사용중인 지점명입니다.');
                officeAddInputRef.current.focus();
            }
        }
    };

    const setOfficeOrder = async (item, flag) => {
        setOfficeCancel();
        let officeList = [...isOfficeList];
        //alert(officeList);

        if (flag === 'up' || flag === 'down') {
            let current = officeList.findIndex((e) => e.idx === item.idx);

            if (current === 0 && flag === 'up') {
                return;
            }

            if (current === officeList.length - 1 && flag === 'down') {
                return;
            }

            let target = 0;
            flag === 'up' ? (target = current - 1) : (target = current + 1);

            let tmp = officeList[current]; //옮길 대상
            officeList[current] = officeList[target];
            officeList[target] = tmp;
        }

        if (flag === 'delete') {
            officeList = officeList.filter((e) => e.idx !== item.idx);
        }

        const itemList = [];
        officeList.map((e, idx) => {
            itemList.push({
                idx: e.idx,
                val: idx + 1,
                text: e.text,
            });
        });

        const param = {
            codeList: itemList,
        };

        //setLoading(true);
        const { data } = await apiClient.post('/code/order/', param);
        if (data.result) {
            getData('office', setOfficeList, 0);
        }
    };

    useEffect(() => {
        getData('terms', setTerms, 0);
        getData('copyright', setCopyRight, 0);
        getData('account', setAccount, 0);

        getData('office', setOfficeList, 0);
    }, []);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="header-title">사이트 설정</h3>

                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="example-textarea" className="form-label">
                                                            회원가입 약관설정
                                                        </label>

                                                        <textarea className="form-control" ref={termsInputRef} rows="5" defaultValue={isTerms}></textarea>
                                                        <div className="text-end mt-1">
                                                            <button type="submit" className="btn btn-dark btn-sm waves-effect waves-light" onClick={(e) => updateData('terms', termsInputRef)} style={{ whiteSpace: 'nowrap', marginTop: '3px' }}>
                                                                저장
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-email" className="form-label">
                                                            사이트 copyright
                                                        </label>
                                                        <input type="text" ref={copyrightInputRef} defaultValue={isCopyRight} className="form-control" />
                                                        <div className="text-end mt-1">
                                                            <button type="submit" className="btn btn-dark btn-sm waves-effect waves-light" onClick={(e) => updateData('copyright', copyrightInputRef)} style={{ whiteSpace: 'nowrap', marginTop: '3px' }}>
                                                                저장
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-email" className="form-label">
                                                            지점관리
                                                        </label>
                                                        <div className="border p-1">
                                                            {isOfficeList &&
                                                                isOfficeList.map((item, i, row) => (
                                                                    <>
                                                                        <div className={`rounded-1 ${isOfficeUpdateIdx === item.idx ? 'border border-dark border-2' : ''}  bg-primary p-2 m-1 text-black fw-bold`}>
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="w-50 ps-1">{item.text}</div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="me-2 cursor" onClick={(e) => setOfficeOrder(item, 'up')}>
                                                                                        <i className="fe-arrow-up ms-2"></i>
                                                                                    </div>
                                                                                    <div className="me-2 cursor" onClick={(e) => setOfficeOrder(item, 'down')}>
                                                                                        <i className="fe-arrow-down ms-2"></i>
                                                                                    </div>
                                                                                    <div className="me-2 cursor" onClick={(e) => setOfificeUpdateInfo(item)}>
                                                                                        <i className="fe-edit ms-2"></i>
                                                                                    </div>
                                                                                    <div className="cursor" onClick={(e) => setOfificeDelete(item)}>
                                                                                        <i className="fe-trash-2 ms-2"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                        </div>

                                                        <div className="mt-1 w-50">
                                                            <div className="input-group w-auto">
                                                                <input type="text" className="form-control" ref={officeAddInputRef} />
                                                                <button className="btn btn-dark btn-sm waves-effect waves-light" onClick={(e) => setOffice()} type="button">
                                                                    {isOfficeUpdateIdx === '' ? '추가' : '수정'}
                                                                </button>
                                                                {isOfficeUpdateIdx !== '' && (
                                                                    <button className="btn btn-danger btn-sm waves-effect waves-light ms-1" onClick={(e) => setOfficeCancel()} type="button">
                                                                        취소
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-email" className="form-label">
                                                            입금 계좌 관리
                                                        </label>
                                                        <input type="text" ref={accountInputRef} defaultValue={isAccount} className="form-control" />
                                                        <div className="text-end mt-1">
                                                            <button type="submit" className="btn btn-dark btn-sm waves-effect waves-light" onClick={(e) => updateData('account', accountInputRef)} style={{ whiteSpace: 'nowrap', marginTop: '3px' }}>
                                                                저장
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 d-none">
                                                    <div className="mb-3">
                                                        <label htmlFor="example-select" className="form-label">
                                                            Input Select
                                                        </label>
                                                        <select className="form-select" id="example-select">
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-multiselect" className="form-label">
                                                            Multiple Select
                                                        </label>
                                                        <select id="example-multiselect" multiple className="form-select">
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-fileinput" className="form-label">
                                                            Default file input
                                                        </label>
                                                        <input type="file" id="example-fileinput" className="form-control" />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-date" className="form-label">
                                                            Date
                                                        </label>
                                                        <input className="form-control" id="example-date" type="date" name="date" />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-month" className="form-label">
                                                            Month
                                                        </label>
                                                        <input className="form-control" id="example-month" type="month" name="month" />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-time" className="form-label">
                                                            Time
                                                        </label>
                                                        <input className="form-control" id="example-time" type="time" name="time" />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-week" className="form-label">
                                                            Week
                                                        </label>
                                                        <input className="form-control" id="example-week" type="week" name="week" />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-number" className="form-label">
                                                            Number
                                                        </label>
                                                        <input className="form-control" id="example-number" type="number" name="number" />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="example-color" className="form-label">
                                                            Color
                                                        </label>
                                                        <input className="form-control" id="example-color" type="color" name="color" value="#71b6f9" />
                                                    </div>

                                                    <div>
                                                        <label htmlFor="example-range" className="form-label">
                                                            Range
                                                        </label>
                                                        <input className="form-range" id="example-range" type="range" name="range" min="0" max="100" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            <ToastContainer
                position="top-right" // 알람 위치 지정
                autoClose={3000} // 자동 off 시간
                hideProgressBar={false} // 진행시간바 숨김
                closeOnClick // 클릭으로 알람 닫기
                rtl={false} // 알림 좌우 반전
                pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                draggable // 드래그 가능
                pauseOnHover // 마우스를 올리면 알람 정지
                theme="light"
                // limit={1} // 알람 개수 제한
            />
        </>
    );
};

export { SettingPage };
