import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import Loading from '../../components/Loading/Loading';
import 'moment/locale/ko'; // Locale Setting
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Companylogo from './../../assets/images/rainbow-logo.png';
import { useCookies } from 'react-cookie';
import moment from 'moment/moment';

const LoginPage = () => {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies(['rememberEmailId']);
    const [isRemember, setIsRemember] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const onSubmit = async (param) => {
        setLoading(true);

        let { data } = await apiClient.post(`/login`, param);
        if (data) {
            localStorage.setItem(`accessTokenAdmin`, data.token);
            localStorage.setItem(`accessTokenAdmin_exp`, data.exp);

            if (data.result) {
                if (isRemember) {
                    //쿠키에 userId 값 저장, 유효기간 2000초
                    setCookie('rememberEmailId', param.email, { maxAge: 3600 * 24 * 30 });
                }

                location.state ? navigate(location.state.reffer) : navigate('/');
            } else {
                alert('사용자 정보가 올바르지 않습니다.');
            }
        }
        setLoading(false);
    };

    const handleOnChange = (e) => {
        setIsRemember(e.target.checked);
        if (!e.target.checked) {
            removeCookie('rememberEmailId');
        }
    };

    useEffect(() => {
        if (cookies.rememberEmailId !== undefined) {
            setValue('email', cookies.rememberEmailId);
            setIsRemember(true);
        }
    }, []);
    return (
        <>
            <div className="account-pages my-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-4">
                            <div className="text-center">
                                {/* <a href="index.html">
                                    <img src="assets/images/logo-dark.png" alt="" height="22" className="mx-auto" />
                                </a> */}
                                <p className="text-muted mt-4 mb-4">
                                    <img src={Companylogo} alt="logo" />
                                </p>
                            </div>
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="text-center mb-4">
                                        <h4 className="text-uppercase mt-0">Sign In</h4>
                                    </div>

                                    <form id="hook-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                                        <div className="mb-3">
                                            <label htmlFor="uid" className="form-label">
                                                ID
                                            </label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                id="email"
                                                // value="goodmins@gmail.com"
                                                placeholder="Enter your email"
                                                {...register('email', {
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                        message: 'Enter your email',
                                                    },
                                                    required: 'Enter your email',
                                                })}
                                            />
                                            <p className="mt-1 h5 text-danger">{errors.email?.message}</p>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">
                                                Password
                                            </label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                id="password"
                                                autoComplete="off"
                                                placeholder="Enter your password"
                                                maxLength="50"
                                                {...register('password', {
                                                    required: 'Enter your password',
                                                })}
                                            />
                                            <p className="mt-1 h5 text-danger">{errors.password?.message}</p>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="saveEmail"
                                                    name="saveEmail"
                                                    onChange={(e) => {
                                                        handleOnChange(e);
                                                    }}
                                                    checked={isRemember}
                                                />
                                                <label className="form-check-label" htmlFor="saveEmail">
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>

                                        <div className="mb-3 d-grid text-center">
                                            <button className="btn btn-primary" type="submit">
                                                Log In
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/* <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <p>
                                        {' '}
                                        <a href="pages-recoverpw.html" className="text-muted ms-1">
                                            <i className="fa fa-lock me-1"></i>Forgot your password?
                                        </a>
                                    </p>
                                    <p className="text-muted">
                                        Don`t have an account?{' '}
                                        <a href="pages-register.html" className="text-dark ms-1">
                                            <b>Sign Up</b>
                                        </a>
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <Loading /> : null}
        </>
    );
};

export { LoginPage };
