import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination';
import { PostPopup } from '../../components/PostPopup';
import { comma } from '../../common';
import 'moment/locale/ko'; // Locale Setting
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import { useForm } from 'react-hook-form';

const MemberPage = () => {
    const [isOfficeList, setOfficeList] = useState(''); //office
    const [isMemberDetailModal, setMemberDetailModal] = useState(false);

    const [isSearchFilterTp, setSearchFilterTp] = useState();
    const [isSearchFilterValue, setSearchFilterValue] = useState();
    const [isSearchFilterOffice, setSearchFilterOffice] = useState();

    const [isPageInfo, setPageInfo] = useState({ pageCount: 5, totalCount: 0, pageSize: 10 });
    const [isMemberList, setMemberList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isCheckItems, setCheckItems] = useState([]);

    const [isMemberDetail, setMemberDetail] = useState();
    const editDefault = {
        flag: false,
        type: '',
        title: '',
    };
    const [isMemberEdit, setMemberEdit] = useState(editDefault);

    const {
        register,
        control,
        handleSubmit,
        getValues,
        setFocus,
        setValue,
        reset,
        formState,
        setError,
        formState: { errors },
    } = useForm();

    const onSubmit = async (param) => {
        console.log('Form submitted.', param);

        //setLoading(true);
        const params = {};
        params.idx = isMemberDetail.idx;

        if (isMemberEdit.type == 'phone') {
            params.phone = param.phone;
        }
        if (isMemberEdit.type == 'uname') {
            params.uname = param.uname;
        }
        if (isMemberEdit.type == 'office') {
            params.office = param.office;
        }

        console.log(params);
        //return;
        let { data } = await apiClient.post(`/member/detail/update`, params);
        if (data && data.result === true) {
            setMemberEdit(editDefault);
            getMemberDetail(isMemberDetail.idx);
        } else {
            alert('처리도중 오류가 발생했습니다.');
        }
        setLoading(false);
    };

    //검색
    const [isParams, setParams] = useState({
        page: 1,
        searchTp: '',
        searchValue: '',
        office: 0,
    });

    //페이징에서 호출
    const setPageStatus = function (pg) {
        setParams({
            ...isParams,
            page: pg,
        });
    };

    async function getMemberList() {
        const params = {
            page: isParams.page,
            itemsPerPage: isPageInfo.pageSize,
        };

        if (isParams.searchTp != '') {
            params.searchTp = isParams.searchTp;
        }

        if (isParams.searchValue != '') {
            params.searchValue = isParams.searchValue;
        }

        if (isParams.office != '') {
            params.office = isParams.office;
        }

        setLoading(true);
        let { data } = await apiClient.get(`/member/list`, { params });
        if (data && data.result === true) {
            setMemberList(data.memberList.items);
            setPageInfo({ ...isPageInfo, totalCount: data.memberList.itemsLength });
        }
        setLoading(false);
    }

    async function setCheckedAllFn(checked) {
        let arryCopy = [];
        if (checked) {
            isMemberList.map((item) => {
                arryCopy.push(item.idx);
            });
        } else {
            arryCopy = [];
        }
        setCheckItems(arryCopy);
    }

    async function handleSerach() {
        setParams({
            ...isParams,
            page: 1,
            searchTp: isSearchFilterTp,
            searchValue: isSearchFilterValue,
            office: isSearchFilterOffice,
        });
    }
    async function handleSearchClear() {
        setParams({
            ...isParams,
            page: 1,
            searchTp: '',
            searchValue: '',
            office: '',
        });
    }

    async function setCheckedFn(checked, idx) {
        let arryCopy = [...isCheckItems];
        if (checked) {
            arryCopy.push(idx);
        } else {
            arryCopy = arryCopy.filter((el) => el !== idx);
        }

        setCheckItems(arryCopy);
    }

    async function setDeleteSelectData(idx) {
        const params = {};

        if (idx) {
            params['deleteList'] = idx;
        } else {
            if (isCheckItems.length === 0) {
                alert('선택된 데이터가 없습니다.');
                return;
            }
            params['deleteList'] = isCheckItems;
        }

        if (confirm('삭제 후 복원이 불가능합니다. 삭제하시겠습니까?')) {
            const { data } = await apiClient.post(`/member/delete`, params);
            if (data && data.result === true) {
                setCheckedAllFn(false);
                getMemberList();
            }
        }
    }

    async function getOfficeList(categoryGubun, setOfficeList, parentIdx) {
        try {
            if (parentIdx === '') return;
            const { data } = await apiClient.get('/code/list/' + categoryGubun + '/' + parentIdx);
            if (data.result) {
                setOfficeList(data.codeList);
            }
        } catch (e) {}
    }

    const handleModal = {
        MemberDetailClose: () => {
            setMemberDetailModal(false);
            getMemberList();
        },
        MemberEditClose: () => {
            setMemberEdit(editDefault);
            setValue('phone', '');
            setValue('uname', '');
            setValue('office', '');
        },
    };

    async function getMemberDetail(idx) {
        setLoading(true);

        let { data } = await apiClient.get(`/member/detail/` + idx);
        if (data && data.result === true) {
            setMemberDetail(data.detail.user);
            setMemberDetailModal(true);
        }
        setLoading(false);
    }

    useEffect(() => {
        getOfficeList('office', setOfficeList, 0);
    }, []);

    useEffect(() => {
        setSearchFilterTp(isParams.searchTp);
        setSearchFilterValue(isParams.searchValue);
        setSearchFilterOffice(isParams.office);

        getMemberList();
    }, [isParams]);

    useEffect(() => {
        isSearchFilterTp === '' && setSearchFilterValue('');
    }, [isSearchFilterTp]);
    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-light">
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">상세조건</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill me-2" style={{ width: '140px' }}>
                                                                    <select className="form-select form-select-sm" value={isSearchFilterTp} onChange={(e) => setSearchFilterTp(e.target.value)}>
                                                                        <option value="">전체</option>
                                                                        <option value="uname">이름</option>
                                                                        <option value="email">아이디</option>
                                                                        <option value="phone">연락처</option>
                                                                    </select>
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <input type="text" className={`form-control form-control-sm`} style={isSearchFilterTp === '' ? { backgroundColor: '#f7f7f7' } : {}} value={isSearchFilterValue} readOnly={isSearchFilterTp === '' ? true : false} onChange={(e) => setSearchFilterValue(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-3 col-sm-2 col-xl-1 border-end">
                                                    <div style={{ marginTop: '8px' }} className="pe-3">
                                                        <span className="text-nowrap fw-normal h5">지점</span>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row gx-4 gy-2 ps-2">
                                                        <div className="col-auto">
                                                            <div className="d-flex align-items-center flex-row">
                                                                <div className="flex-fill me-2" style={{ width: '140px' }}>
                                                                    <select
                                                                        className="form-select form-select-sm"
                                                                        onChange={(e) => {
                                                                            setSearchFilterOffice(e.target.value);
                                                                        }}
                                                                        value={isSearchFilterOffice}
                                                                    >
                                                                        {isOfficeList && isOfficeList.items.length > 0 ? <option value="">지점선택</option> : ''}
                                                                        {isOfficeList && isOfficeList ? (
                                                                            isOfficeList.items.map((item) => (
                                                                                <option key={item.idx} value={item.idx}>
                                                                                    {item.txt}
                                                                                </option>
                                                                            ))
                                                                        ) : (
                                                                            <option value="">등록된 지점이 없습니다.</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="justify-content-end row">
                                                <div className="col-12 text-center">
                                                    <button type="submit" className="btn btn-dark waves-effect waves-light" onClick={() => handleSerach()}>
                                                        검색
                                                    </button>
                                                    <button type="submit" className="btn btn-secondary waves-effect waves-light ms-1" onClick={() => handleSearchClear()}>
                                                        초기화
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-inline-flex fw-normal h4">회원목록({isPageInfo && isPageInfo.totalCount ? isPageInfo.totalCount : 0})</div>
                                            </div>
                                            {/* {TODO 아이디 클릭시 상세 정보 노출 / 접속 이력, 승인여부, 관리자 처리} */}
                                            <hr className="p-0 m-0 mb-2" />

                                            <div className="row mb-2 gap-1 ps-2">
                                                <div className="col-auto p-0">
                                                    <button type="button" className="btn btn-sm btn-outline-secondary waves-effect" onClick={() => setDeleteSelectData()}>
                                                        선택삭제
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-bordered mb-0" id="inline-editable">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th className="text-center text-nowrap">
                                                                <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isMemberList && isCheckItems.length == isMemberList.length ? true : false} onChange={(e) => setCheckedAllFn(e.target.checked)} />
                                                            </th>
                                                            <th className="text-center text-nowrap">가입일</th>
                                                            <th className="text-center text-nowrap">아이디</th>
                                                            <th className="text-center text-nowrap">이름</th>
                                                            <th className="text-center text-nowrap">연락처</th>
                                                            <th className="text-center text-nowrap">지점</th>
                                                            <th className="text-center text-nowrap">마지막주문일</th>
                                                            <th className="text-center text-nowrap">최근 30일주문 건수</th>
                                                            <th className="text-center text-nowrap">최근 30일주문 금액/수량</th>
                                                            <th className="text-center text-nowrap">마지막로그인 IP</th>
                                                            <th className="text-center text-nowrap">마지막접속일</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {isMemberList &&
                                                            isMemberList.map((item) => (
                                                                <tr key={item.idx} className={item.isUsed == false ? `text-danger` : `text-dark`} style={item.num % 2 == 0 ? { 'background-color': 'rgb(206 212 218 / 37%)' } : {}}>
                                                                    <td className="text-center align-middle">
                                                                        <input className="form-check-input" type="checkbox" style={{ width: '20px', height: '20px' }} checked={isCheckItems && isCheckItems.length > 0 && isCheckItems.includes(item.idx) ? true : false} onChange={(e) => setCheckedFn(e.target.checked, item.idx)} />
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">{item.createdAt ? moment(item.createdAt).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : ''}</td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        <span className="link" onClick={() => getMemberDetail(item.idx)}>
                                                                            {item.email}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">
                                                                        {item.uname} {item.isUsed == false ? `(삭제)` : ''}
                                                                    </td>
                                                                    <td className="text-center text-nowrap align-middle">{item.phone}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.office_name != 0 ? item.office_name : '지점없음'}</td>
                                                                    <td className="text-center text-nowrap align-middle">{item.lastOrderDate ? moment(item.lastOrderDate).tz('Asia/Seoul').utc().format('YYYY-MM-DD HH:mm') : ''}</td>
                                                                    <td className="text-end text-nowrap align-middle">{item.totalCnt && comma(item.totalCnt) + '건'}</td>
                                                                    <td className="text-end text-nowrap align-middle">{item.totalSum && comma(item.totalSum) + '원 (' + comma(item.totalBuyCnt) + ')'}</td>
                                                                    <td className="text-center text-nowrap align-middle"></td>
                                                                    <td className="text-center text-nowrap align-middle"></td>
                                                                </tr>
                                                            ))}
                                                        {isMemberList && isMemberList.length == 0 && (
                                                            <>
                                                                <tr>
                                                                    <td colSpan="22" className="text-center">
                                                                        내역이 없습니다.
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12 col-sm-12 col-lg-2"></div>
                                                <div className="col-12 col-lg-8">{isPageInfo.totalCount ? <Pagination isPageInfo={isPageInfo} isCurPage={isParams.page} setPageStatus={setPageStatus} /> : ''}</div>
                                                <div className="col-12 col-sm-12 col-lg-2 text-end"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}

            <Modal show={isMemberDetailModal} onHide={handleModal.MemberDetailClose} backdrop="static" size="xl" centered>
                <Modal.Header className="p-0 m-0 pe-2 order-detail-popup" closeButton>
                    <Modal.Title className="p-0 m-0">
                        <div className="row p-0">
                            <div className="col">
                                <div className="bg-dark p-2">
                                    <h5 className="text-white">회원정보</h5>
                                </div>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                    }}
                >
                    <div className="row">
                        <div className="col">
                            <div className="d-flex align-items-start">
                                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                        회원정보
                                    </button>
                                    {/* <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        접속정보
                                    </button> */}
                                </div>
                                <div className="tab-content pt-0 flex-fill" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                        <table className="table table-bordered mb-0" id="inline-editable">
                                            <tbody>
                                                <tr>
                                                    <td>가입일</td>
                                                    <td>{isMemberDetail && isMemberDetail.createdAt}</td>
                                                </tr>
                                                <tr>
                                                    <td width="150px">아이디</td>
                                                    <td>{isMemberDetail && isMemberDetail.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>이름</td>
                                                    <td>
                                                        {isMemberDetail && isMemberDetail.uname}
                                                        <i
                                                            className="fe-edit ms-2 text-primary cursor"
                                                            onClick={() =>
                                                                setMemberEdit({
                                                                    flag: true,
                                                                    type: 'uname',
                                                                    title: '이름 변경',
                                                                })
                                                            }
                                                        ></i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>연락처</td>
                                                    <td>
                                                        {isMemberDetail && isMemberDetail.phone}
                                                        <i
                                                            className="fe-edit ms-2 text-primary cursor"
                                                            onClick={() =>
                                                                setMemberEdit({
                                                                    flag: true,
                                                                    type: 'phone',
                                                                    title: '연락처 변경',
                                                                })
                                                            }
                                                        ></i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>지점</td>
                                                    <td>
                                                        {isMemberDetail && isMemberDetail.office_name}
                                                        <i
                                                            className="fe-edit ms-2 text-primary cursor"
                                                            onClick={() =>
                                                                setMemberEdit({
                                                                    flag: true,
                                                                    type: 'office',
                                                                    title: '지점 변경',
                                                                })
                                                            }
                                                        ></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* {isMemberDetail && isMemberDetail.idx} */}
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                        ...
                                    </div>
                                </div>
                            </div>

                            <ul className="nav nav-tabs d-none" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                                        Home
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                                        Profile
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
                                        Contact
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false" disabled>
                                        Disabled
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content d-none" id="myTabContent">
                                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    ...
                                </div>
                                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                    ...
                                </div>
                                <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                    ...
                                </div>
                                <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabIndex="0">
                                    ...tabIndex
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row"></div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <div className="flex-fill text-start">
                        {/* <button type="submit" form="hook-form" className="btn btn-danger rounded-1" onClick={() => setDeleteSelectData()}>
                            수정
                        </button> */}
                    </div>
                    <div className="flex-fill text-end">
                        <button className="btn btn-secondary rounded-1" onClick={handleModal.MemberDetailClose}>
                            닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={isMemberEdit.flag} onHide={handleModal.MemberEditClose} centered contentClassName="border shadow-lg border-1 border-dark">
                <Modal.Header closeButton className="py-0">
                    <Modal.Title>
                        <h5>{isMemberEdit.title}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-scrollable pe-2">
                        <form id="hook-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                            {isMemberEdit.type === 'phone' ? (
                                <div>
                                    <input
                                        type="text"
                                        className="form-control rounded-0 shadow-none"
                                        id="phone"
                                        maxLength="13"
                                        {...register('phone', {
                                            pattern: {
                                                value: /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/,
                                                message: `'-' 포함해서 입력하세요 010-XXXX-XXXX`,
                                            },
                                            required: '연락처를 입력하세요. 010-XXXX-XXXX',
                                        })}
                                    />
                                    <p className="mt-2 h6 text-danger">{errors.phone?.message}</p>
                                </div>
                            ) : (
                                ''
                            )}

                            {isMemberEdit.type === 'uname' ? (
                                <div>
                                    <input
                                        type="text"
                                        className="form-control rounded-0 shadow-none"
                                        id="uname"
                                        maxLength="20"
                                        {...register('uname', {
                                            required: '이름을 입력하세요.',
                                        })}
                                    />
                                    <p className="mt-2 h6 text-danger">{errors.uname?.message}</p>
                                </div>
                            ) : (
                                ''
                            )}

                            {isMemberEdit.type === 'office' ? (
                                <div>
                                    <select
                                        className="form-select form-select-sm shadow-none rounded-0"
                                        id="office"
                                        {...register('office', {
                                            required: '지점을 선택하세요.',
                                        })}
                                    >
                                        <option value="">지점을 선택하세요.</option>
                                        {isOfficeList && isOfficeList ? (
                                            isOfficeList.items.map((item) => (
                                                <option key={item.idx} value={item.idx}>
                                                    {item.txt}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">등록된 지점이 없습니다.</option>
                                        )}
                                    </select>
                                    <p className="mt-2 h6 text-danger">{errors.office?.message}</p>
                                </div>
                            ) : (
                                ''
                            )}
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" form="hook-form" className="btn btn-dark rounded-1">
                        저장
                    </button>
                    <button className="btn btn-secondary rounded-1" onClick={handleModal.MemberEditClose}>
                        취소
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { MemberPage };
