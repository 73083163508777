import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../apiClient';

import styled from 'styled-components';
import Uploady from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';
import withPasteUpload from '@rpldy/upload-paste';
import { useItemFinishListener } from '@rpldy/uploady';
import UploadButton from '@rpldy/upload-button';
import Loading from '../../components/Loading/Loading';
import QuillEditor from '../../components/QuillEditor/QuillEditor';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/ko'; // Locale Setting
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navi } from '../../components/Navi/Navi';
import { LeftSide } from '../../components/LeftSide/LeftSide';
import { RightSide } from '../../components/RightSide/RightSide';
import ColorSelect from '../../components/ColorSelect/ColorSelect';
import { comma, uncomma } from '../../common';

const StyledDropZone = styled(UploadDropZone)`
    width: 200px;
    height: 200px;
    border: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    font-size: 13px;
    background-color: rgba(108, 117, 125, 0.25) !important;
    border: 1px solid #a7a7a7;
    border-radius: 5px;
    position: relative;
    button {
        width: 200px;
        position: absolute;
        bottom: -1px;
        border: 1px solid #a7a7a7;
        padding: 5px 0 5px 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

const PasteUploadDropZone = withPasteUpload(StyledDropZone);

const ProductUpdatePage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams.toString());
    //console.log(searchParams.get('copy'));
    const navigate = useNavigate();
    const { product_code } = useParams();
    const [isLoading, setLoading] = useState(false);
    const quillRef = useRef();
    const [isCheckItems, setCheckItems] = useState([]);

    const TIME_ZONE = 9 * 60 * 60 * 1000; // 9시간
    const date = new Date();
    const now = new Date(date.getTime() + TIME_ZONE).toISOString().replace('T', ' ').slice(0, -5);
    const insert_product_code = now.replaceAll('-', '').replaceAll(' ', '').replaceAll(':', '').substring(2);
    console.log(insert_product_code);
    const [isHtmlContent, setHtmlContent] = useState(''); //에디터 수정

    const [isSale, setSale] = useState(1); //판매상태
    const [isView, setView] = useState(1); //전시상태
    //const [isUsed, setUsed] = useState(1); //삭제유부
    const [isProductIdx, setProductIdx] = useState(); //수정시 IDX
    const [isColorUsed, setColorUsed] = useState(1); //색상옵션 사용
    const [isProductCode, setProductCode] = useState(insert_product_code); //상품코드
    //console.log(isProductCode);
    const [isProductName, setProductName] = useState(''); //상품제목
    const [isProductComment, setProductComment] = useState(''); //상품코멘트
    const [isCategory1, setCategory1] = useState(''); //카테고리1
    const [isCategory2, setCategory2] = useState(''); //카테고리2
    const [isSelectCategory1, setSelectCategory1] = useState(''); //카테고리1
    const [isSelectCategory2, setSelectCategory2] = useState(''); //카테고리2

    const [isTextOption, setTextOption] = useState(); //옵션일괄등록
    const [categoryReg, setCategoryReg] = useState(0); //카테고리등록 flag

    const [isChangeSelect, setChangeSelect] = useState(1);
    const [isChangeValue, setChangeValue] = useState();
    const [isOptionDeleteList, setOptionDeleteList] = useState([]);

    const [isColorData, setColorData] = useState();

    const [isThumnail, setThumnail] = useState({
        productThumnail: '',
        productThumnailIdx: 0,
    });

    const otpionStructure = {
        id: 0,
        optionIdx: 0,
        optionName: '',
        optionColor: 0,
        optionColorClass: '',
        optionCount: 0,
        isSale: true,
        price: 0,
        sellPrice: 0,
        isUsed: true,
    };
    const [isOptionData, setOptionData] = useState([otpionStructure]);

    // 체크박스 단일 선택
    const handleSingleCheck = (checked, idx) => {
        if (checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            setCheckItems((prev) => [...prev, idx]);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setCheckItems(isCheckItems.filter((el) => el !== idx));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if (checked) {
            // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
            const idArray = [];
            isOptionData.forEach((el) => idArray.push(el.id));
            setCheckItems(idArray);
        } else {
            // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
            setCheckItems([]);
        }
    };

    async function setTextToOption() {
        if (!isTextOption) {
            toast.info('옵션명 일괄 등륵올 내용을 입력해주세요.');
            inputsTextOption.current.focus();
            return;
        }

        let isOptionDataCopy = [...isOptionData];
        const splitValue = isTextOption.split(',');

        //첫번째 값이 아무것도 없으면 초기화
        if (isOptionDataCopy.length === 1) {
            if (isOptionDataCopy[0].optionName === '') {
                isOptionDataCopy = [];
            }
        }

        let id_ = isOptionDataCopy.length;
        splitValue.forEach((value) => {
            if (value != '') {
                if (isOptionData.findIndex((e) => e.optionName === value.trim()) == -1) {
                    const isStructureCopy = JSON.parse(JSON.stringify(otpionStructure));
                    isStructureCopy.id = id_++;
                    isStructureCopy.optionName = value.trim();
                    isOptionDataCopy.push(isStructureCopy);
                }
            }
        });
        setOptionData(isOptionDataCopy);
        setTextOption('');
    }

    const handleTextOptionChange = (evnt) => {
        const { name, value } = evnt.target;
        setTextOption(value);
    };

    const inputsProductName = useRef();
    const inputsCategory1 = useRef();
    const inputsCategory2 = useRef();
    const inputsThumnail = useRef();
    const inputsTextOption = useRef();
    const inputsOptionName = useRef([]);
    const setUpdate = async (e) => {
        if (isProductName == '') {
            toast.info('상품제목을 입력하세요.');
            inputsProductName.current.focus();
            return;
        }

        if (isSelectCategory1 == '') {
            toast.info('대분류를 선택하세요.');
            inputsCategory1.current.focus();
            return;
        }

        if (isSelectCategory2 == '') {
            toast.info('중분류를 선택하세요.');
            inputsCategory2.current.focus();
            return;
        }

        if (isThumnail.productThumnail == '') {
            toast.info('대표이미지를 등록하세요.');
            inputsThumnail.current.focus();
            return;
        }

        let optionNameEmpty = false;
        for (let i = 0; i < isOptionData.length; i++) {
            if (inputsOptionName.current[i].value === '') {
                toast.info('옵션명을 입력하세요.');
                inputsOptionName.current[i].focus();
                optionNameEmpty = true;
                break;
            }
        }

        if (optionNameEmpty) return;

        if (isHtmlContent == '') {
            toast.info('상세페이지 내용을 입력하세요.');
            return;
        }

        const param = {
            //isUsed: isUsed,
            isView: isView,
            isSale: isSale,
            product_code: isProductCode,
            product_name: isProductName,
            product_comment: isProductComment,
            product_m_code: '', //관리코드 미사용
            category1: isSelectCategory1,
            category2: isSelectCategory2,
            thumnail: isThumnail,
            contents: isHtmlContent,
            optionlist: isOptionData,
            optionDeleteList: isOptionDeleteList,
            isColorUsed: isColorUsed,
        };
        if (searchParams.get('copy') !== 'y') {
            param.idx = isProductIdx;
            if (isProductIdx !== undefined) param.updatedAt = moment(new Date()).add(9, 'h');
        }

        setLoading(true);
        const { data } = await apiClient.post('/product/update', param);
        if (data.result) {
            navigate(`/product?` + searchParams);
            //alert();
            //navigate(-1);
            //console.log(data.result);
        } else {
            alert('처리도중 오류가 발생했습니다. 다시 시도해주세요.');
        }
        setLoading(false);
    };

    //카테고리 호출 함수
    async function getCategory(categoryGubun, setCategory, parentIdx) {
        try {
            if (parentIdx === '') return;
            const { data } = await apiClient.get('/code/list/' + categoryGubun + '/' + parentIdx);
            if (data.result) {
                setCategory(data.codeList);
            }
        } catch (e) {}
    }

    //테이블 추가 삭제
    const addTableRows = (pos) => {
        // console.log(pos);
        const rows = [...isOptionData];
        rows.splice(pos + 1, 0, otpionStructure);

        let id_ = 0;
        rows.forEach((e) => {
            e.id = id_++;
        });
        setOptionData(rows);
    };
    const deleteTableRows = (index) => {
        const rows = [...isOptionData];

        if (rows[index].optionIdx !== 0) {
            const deleterows = [...isOptionDeleteList];
            deleterows.push(rows[index].optionIdx);
            setOptionDeleteList(deleterows);
        }

        rows.splice(index, 1);

        let id_ = 0;
        rows.forEach((e) => {
            e.id = id_++;
        });

        setOptionData(rows);
    };

    const handleChangeValue = () => {
        if (isCheckItems.length == 0) {
            toast.info('선택된 옵션이 없습니다. 선택후 이용해주세요.');
            return;
        }

        let isOptionDataCopy = [...isOptionData];
        if (isChangeSelect == '1') {
            isOptionDataCopy.forEach((e) => {
                if (isCheckItems.includes(e.id)) {
                    e.optionCount = isChangeValue;
                }
            });
        }

        if (isChangeSelect == '2') {
            isOptionDataCopy.forEach((e) => {
                if (isCheckItems.includes(e.id)) {
                    e.price = isChangeValue;
                }
            });
        }

        if (isChangeSelect == '3') {
            isOptionDataCopy.forEach((e) => {
                if (isCheckItems.includes(e.id)) {
                    e.sellPrice = isChangeValue;
                }
            });
        }

        if (isChangeSelect == '4') {
            isOptionDataCopy.forEach((e) => {
                if (isCheckItems.includes(e.id)) {
                    e.isSale = JSON.parse(isChangeValue);
                }
            });
        }

        setOptionData(isOptionDataCopy);
    };

    const handleOptionChange = (index, evnt) => {
        let { name, value } = evnt.target;
        const rowsInput = [...isOptionData];
        if (name == 'isSale') {
            value = JSON.parse(value);
        }
        rowsInput[index][name] = value;
        setOptionData(rowsInput);
    };

    const handleOptionFocus = (index, evnt) => {
        let { name, value } = evnt.target;
        if (value == 0) {
            evnt.target.value = '';
        }

        if (value.indexOf(',') != -1) {
            value = uncomma(value);
        }
        if (value > 0) {
            evnt.target.value = '';
            //evnt.target.focus();
            evnt.target.value = value;
            evnt.target.focus({ cursor: 'end' });
        }
    };

    const handleOptionBlur = (index, evnt) => {
        let { name, value } = evnt.target;
        if (value == '') {
            evnt.target.value = '0';
        }

        if (value > 0) {
            //evnt.target.value = comma(evnt.target.value);
        }
    };

    const ThumnailUploadStatus = () => {
        useItemFinishListener((item) => {
            if (item.uploadResponse.data.result) {
                setThumnail({
                    productThumnail: item.uploadResponse.data.uploadFile[0].file_path,
                    productThumnailIdx: item.uploadResponse.data.uploadFile[0].idx,
                });
            }
        });
    };

    const handleColorSelect = (item, idx) => {
        // console.log(item);
        const rowsInput = [...isOptionData];
        //초기화
        rowsInput[idx]['optionColor'] = item === 0 ? 0 : item.idx;
        rowsInput[idx]['optionColorClass'] = item === 0 ? '' : item.txt2;
        setOptionData(rowsInput);
        document.body.click();
        //ref.handleHide();
    };

    //상세페이지 호출
    async function getProductInfo(product_code) {
        try {
            if (!product_code) {
                return;
            }
            setLoading(true);
            const { data } = await apiClient.get('/product/detail/' + product_code);
            if (data.result) {
                if (searchParams.get('copy') !== 'y') {
                    setProductIdx(data.detail.product.idx);
                    setProductCode(data.detail.product.product_code);
                }
                setSale(data.detail.product.isSale);
                setView(data.detail.product.isView);
                setProductName(data.detail.product.product_name);
                setProductComment(data.detail.product.product_comment);
                setSelectCategory1(data.detail.product.category1);
                setSelectCategory2(data.detail.product.category2);
                setColorUsed(data.detail.product.isColorUsed);
                setThumnail({
                    productThumnail: data.detail.product.thumnail,
                    productThumnailIdx: data.detail.product.thumnail_idx,
                });
                setHtmlContent(data.detail.product.contents);

                const isOptionDataTemp = [];
                data.detail.productOption.forEach(function (item, idx) {
                    const isStructureCopy = JSON.parse(JSON.stringify(otpionStructure));
                    isStructureCopy.id = idx;
                    if (searchParams.get('copy') !== 'y') {
                        isStructureCopy.optionIdx = isStructureCopy.optionIdx = item.optionIdx;
                    }
                    isStructureCopy.optionName = item.optionName;
                    isStructureCopy.optionColor = item.color_idx;
                    isStructureCopy.optionColorClass = item.optionColorClass;
                    isStructureCopy.optionCount = item.cnt;
                    isStructureCopy.isSale = item.isSale == 0 ? false : true;
                    isStructureCopy.price = item.price;
                    isStructureCopy.sellPrice = item.sellPrice;
                    isStructureCopy.isUsed = true;
                    isOptionDataTemp.push(isStructureCopy);
                });
                setOptionData(isOptionDataTemp);
            }
            setLoading(false);
        } catch (e) {
            alert(e);
        }
    }

    //저장후 카테고리 재호출
    useEffect(() => {
        if (categoryReg === 3) {
            getCategory('category-1', setCategory1, 0);
        }
        if (categoryReg === 3 && isSelectCategory1) {
            getCategory('category-2', setCategory2, isSelectCategory1);
        }
    }, [categoryReg]);

    //시작시 카테고리 호출
    useEffect(() => {
        getCategory('category-1', setCategory1, 0);
    }, []);

    useEffect(() => {
        getCategory('category-2', setCategory2, isSelectCategory1);
    }, [isSelectCategory1]);

    useEffect(() => {
        getCategory('color', setColorData, 0);
    }, []);

    useEffect(() => {
        getProductInfo(product_code);
    }, [product_code]);

    return (
        <>
            <div id="wrapper">
                <Navi />
                <LeftSide />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body text-black">
                                            <h4 className="header-title">
                                                상품{isProductIdx && isProductIdx ? '수정' : '등록'} {searchParams && searchParams.get('copy') === 'y' ? '(복사 상품)' : ''}
                                            </h4>
                                            <div className="row mt-3">
                                                <div>
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 판매상태
                                                        </label>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className={'btn-sm ' + (isSale === 1 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                onClick={(e) => {
                                                                    setSale(1);
                                                                }}
                                                            >
                                                                판매
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={'btn-sm ' + (isSale === 0 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                onClick={(e) => {
                                                                    setSale(0);
                                                                }}
                                                            >
                                                                판매중지
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div>
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 전시상태
                                                        </label>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className={'btn-sm ' + (isView === 1 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                onClick={(e) => {
                                                                    setView(1);
                                                                }}
                                                            >
                                                                전시
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={'btn-sm ' + (isView === 0 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                                onClick={(e) => {
                                                                    setView(0);
                                                                }}
                                                            >
                                                                전시중지
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 상품제목
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setProductName(e.target.value);
                                                            }}
                                                            value={isProductName}
                                                            ref={inputsProductName}
                                                        />
                                                    </div>
                                                </div>
                                                {/* {JSON.stringify(isColorData)} */}
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 상품코멘트
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={isProductComment}
                                                            onChange={(e) => {
                                                                setProductComment(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11 "></i> 대분류
                                                        </label>
                                                        <div className="input-group">
                                                            <select
                                                                className="form-select rounded-2"
                                                                onChange={(e) => {
                                                                    setSelectCategory1(e.target.value);
                                                                }}
                                                                value={isSelectCategory1}
                                                                ref={inputsCategory1}
                                                            >
                                                                {isCategory1 && isCategory1.items.length > 0 ? <option>카테고리를 선택하세요.</option> : ''}
                                                                {isCategory1 && isCategory1 ? (
                                                                    isCategory1.items.map((item) => (
                                                                        <option key={item.idx} value={item.idx}>
                                                                            {item.txt}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">등록된 카테고리가 없습니다.</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 중분류
                                                        </label>
                                                        <div className="input-group">
                                                            <select
                                                                className="form-select rounded-2"
                                                                disabled={isSelectCategory1 ? false : true}
                                                                onChange={(e) => {
                                                                    setSelectCategory2(e.target.value);
                                                                }}
                                                                value={isSelectCategory2}
                                                                ref={inputsCategory2}
                                                            >
                                                                <option value="">카테고리를 선택하세요.</option>
                                                                {isCategory2 && isCategory2.items.length > 0 ? (
                                                                    isCategory2.items.map((item) => (
                                                                        <option key={item.idx} value={item.idx}>
                                                                            {item.txt}
                                                                        </option>
                                                                    ))
                                                                ) : isSelectCategory1 === '' ? (
                                                                    <option value="">대분류를 먼저 선택하세요.</option>
                                                                ) : (
                                                                    <option value="">등록된 카테고리가 없습니다.</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-10">
                                                    <div className="mb-3">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11" ref={inputsThumnail}></i> 대표 이미지
                                                        </label>

                                                        <Uploady
                                                            multiple="false"
                                                            grouped
                                                            method="POST"
                                                            destination={{
                                                                url: process.env.REACT_APP_AXIOS_URL + `/product/thumnailImageUpload`,
                                                            }}
                                                        >
                                                            <div className="avatar-xl position-relative" style={{ width: '200px', height: '200px' }}>
                                                                {isThumnail.productThumnail == '' ? (
                                                                    <>
                                                                        <PasteUploadDropZone>
                                                                            이미지 드래그
                                                                            <br /> 또는 복사 붙여넣기
                                                                            <br />
                                                                            <br />
                                                                            <UploadButton>파일 선택</UploadButton>
                                                                        </PasteUploadDropZone>
                                                                        <div style={{ display: 'none' }}>
                                                                            <ThumnailUploadStatus />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <img
                                                                            src={isThumnail.productThumnail}
                                                                            alt="preview-img"
                                                                            className="img-fluid avatar-xl rounded border border-black "
                                                                            style={{
                                                                                width: '200px',
                                                                                height: '100%',
                                                                                zIndex: '1',
                                                                            }}
                                                                        />
                                                                        <span
                                                                            className="position-absolute top-0 end-0 bg-dark opacity-50"
                                                                            style={{
                                                                                padding: '1px 2px 0 2px',
                                                                                margin: '1px 1px 0 0',
                                                                                zIndex: '2',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                setThumnail({
                                                                                    productThumnail: '',
                                                                                    productThumnailIdx: 0,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-close font-20 text-light"></i>
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Uploady>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body text-black">
                                            <div className="row mb-2">
                                                <div className="col-lg-10">
                                                    <i className="dripicons-chevron-right font-11"></i> 옵션
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-10">옵션 명일괄 등록 (중복 옵션명은 자동으로 제외됩니다.)</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 col-sm-7 col-lg-6 mb-2">
                                                    <input type="text" className="form-control" placeholder="예시 : 옵션1, 옵션2 ( , 로 구분)" onChange={(evnt) => handleTextOptionChange(evnt)} value={isTextOption} ref={inputsTextOption} />
                                                </div>
                                                <div className="col-auto ps-0 mb-2">
                                                    <button type="button" className="btn btn-primary btn-sm waves-effect waves-light" style={{ whiteSpace: 'nowrap', marginTop: '3px' }} onClick={(e) => setTextToOption()}>
                                                        옵션일괄 추가
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className="py-0 my-0 mb-3 mt-2" />

                                            <div className="row mb-1">
                                                {/* {JSON.stringify(isOptionData)} */}
                                                <p className="sub-header pb-0 mb-2">미사용인 경우 화면에 노출되지 않습니다.</p>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className={'btn-sm ' + (isColorUsed === 1 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                        onClick={(e) => {
                                                            setColorUsed(1);
                                                        }}
                                                    >
                                                        색상옵션 사용
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={'btn-sm ' + (isColorUsed === 0 ? 'btn-dark' : 'btn-secondary-light') + ' rounded-0 border-0'}
                                                        onClick={(e) => {
                                                            setColorUsed(0);
                                                        }}
                                                    >
                                                        색상옵션 미사용
                                                    </button>
                                                </div>
                                                {/* <br />

                                    <div>{JSON.stringify(isOptionDeleteList)}</div>
                                    <br />
                                    <br />
                                    <div>{JSON.stringify(isCheckItems)}</div>
                                    <br />
                                    <br />*/}
                                                {/* <div>{JSON.stringify(isOptionData)}</div> */}
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col">
                                                    <div className="d-flex justify-content-end">
                                                        <div className="d-inline-flex me-1">
                                                            <select
                                                                className="form-select"
                                                                onChange={(e) => {
                                                                    setChangeSelect(e.target.value);
                                                                }}
                                                            >
                                                                <option value="1">재고수량</option>
                                                                <option value="2">상품원가</option>
                                                                <option value="3">판매가</option>
                                                                <option value="4">사용여부</option>
                                                            </select>
                                                        </div>
                                                        <div className="d-inline-flex">
                                                            {isChangeSelect == '4' ? (
                                                                <select className="form-select rounded-2" style={{ minWidth: '80px' }} onChange={(e) => setChangeValue(e.target.value)}>
                                                                    <option value="true">Y</option>
                                                                    <option value="false">N</option>
                                                                </select>
                                                            ) : (
                                                                <input type="number" className="form-control" style={{ width: '80px' }} onChange={(e) => setChangeValue(e.target.value)} />
                                                            )}
                                                        </div>
                                                        <div className="d-inline-flex">
                                                            <button type="button" className="btn btn-primary btn-sm waves-effect waves-light ms-1" onClick={(e) => handleChangeValue()}>
                                                                선택변경
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* {JSON.stringify(isOptionData)} */}

                                                <div className="col-lg-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-centered mb-0 border product" style={{ minWidth: '700px' }}>
                                                            <thead className="table-secondary">
                                                                <tr>
                                                                    <th scope="col" className="font-13 text-center p-0" width="3%">
                                                                        <input className="form-check-input" type="checkbox" name="select-all" style={{ width: '20px', height: '20px' }} onChange={(e) => handleAllCheck(e.target.checked)} checked={isCheckItems.length === isOptionData.length ? true : false} />
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center" width="20%">
                                                                        옵션명
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center text-nowrap" width="5%">
                                                                        색상선택
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center text-nowrap" width="10%">
                                                                        재고수량
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center text-nowrap" width="5%">
                                                                        판매상태
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center" width="10%">
                                                                        상품원가
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center" width="10%">
                                                                        판매가
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center" width="5%">
                                                                        사용여부
                                                                    </th>
                                                                    <th scope="col" className="font-13 text-center" width="5%"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {isOptionData
                                                                    .filter((e) => e.isUsed == true)
                                                                    .map((tableData, index) => (
                                                                        <tr key={index} id={'Row-' + index} scope="row">
                                                                            <td className="text-center">
                                                                                <input className="form-check-input" name={'select-' + index} type="checkbox" style={{ width: '20px', height: '20px', marginTop: '7px' }} value="0" onChange={(e) => handleSingleCheck(e.target.checked, index)} checked={isCheckItems.includes(index) ? true : false} />
                                                                            </td>
                                                                            <td className="px-1">
                                                                                <input type="text" className="form-control" value={tableData.optionName} placeholder="옵션명" onChange={(evnt) => handleOptionChange(index, evnt)} name="optionName" id="optionName" style={{ minWidth: '300px' }} ref={(el) => (inputsOptionName.current[index] = el)} autoComplete="off" />
                                                                            </td>
                                                                            <td className="px-1 codeSelect">
                                                                                {/* {tableData.optionColor} */}
                                                                                {isColorData &&
                                                                                    (tableData.optionColor !== 0 && tableData.optionColor !== null ? (
                                                                                        <>
                                                                                            <ColorSelect title="" idx={index} isColorData={isColorData} handleColorSelect={handleColorSelect}>
                                                                                                <div className={`circle hand ` + tableData.optionColorClass}></div>
                                                                                            </ColorSelect>
                                                                                        </>
                                                                                    ) : (
                                                                                        <ColorSelect title="" idx={index} isColorData={isColorData} handleColorSelect={handleColorSelect}>
                                                                                            <div style={{ marginTop: '9px' }} className="text-center hand">
                                                                                                색상선택
                                                                                            </div>
                                                                                        </ColorSelect>
                                                                                    ))}
                                                                            </td>
                                                                            <td className="px-1">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control text-center"
                                                                                    value={tableData.optionCount}
                                                                                    placeholder="재고수량"
                                                                                    onChange={(evnt) => handleOptionChange(index, evnt)}
                                                                                    name="optionCount"
                                                                                    id="optionCount"
                                                                                    style={{ minWidth: '50px' }}
                                                                                    autoComplete="off"
                                                                                    onFocus={(evnt) => handleOptionFocus(index, evnt)}
                                                                                    onBlur={(evnt) => handleOptionBlur(index, evnt)}
                                                                                />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <div className="pt-1 text-nowrap" style={{ marginTop: '3px' }}>
                                                                                    {tableData.optionCount > 0 ? '판매중' : '품절'}
                                                                                </div>
                                                                            </td>
                                                                            <td className="px-1">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control text-center"
                                                                                    value={tableData.price}
                                                                                    placeholder="상품원가"
                                                                                    onChange={(evnt) => handleOptionChange(index, evnt)}
                                                                                    name="price"
                                                                                    id="price"
                                                                                    style={{ minWidth: '100px' }}
                                                                                    autoComplete="off"
                                                                                    onFocus={(evnt) => handleOptionFocus(index, evnt)}
                                                                                    onBlur={(evnt) => handleOptionBlur(index, evnt)}
                                                                                />
                                                                            </td>
                                                                            <td className="px-1">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control text-center"
                                                                                    value={tableData.sellPrice}
                                                                                    placeholder="판매가"
                                                                                    onChange={(evnt) => handleOptionChange(index, evnt)}
                                                                                    name="sellPrice"
                                                                                    id="sellPrice"
                                                                                    style={{ minWidth: '100px' }}
                                                                                    autoComplete="off"
                                                                                    onFocus={(evnt) => handleOptionFocus(index, evnt)}
                                                                                    onBlur={(evnt) => handleOptionBlur(index, evnt)}
                                                                                />
                                                                            </td>
                                                                            <td className="px-1 text-center">
                                                                                <select className="form-select rounded-2" style={{ minWidth: '80px' }} value={tableData.isSale} onChange={(evnt) => handleOptionChange(index, evnt)} name="isSale" id="isSale">
                                                                                    <option value="true">Y</option>
                                                                                    <option value="false">N</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                {/* {isOptionData.length - 1 === index ? (
                                                                                    <button type="button" className="btn btn-primary btn-sm waves-effect waves-light" onClick={() => addTableRows(index)} style={{ whiteSpace: 'nowrap', marginTop: '3px' }}>
                                                                                        옵션추가
                                                                                    </button>
                                                                                ) : ( */}
                                                                                <div className="text-nowrap">
                                                                                    {isOptionData.length !== 1 ? (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm waves-effect waves-light"
                                                                                            onClick={() => {
                                                                                                deleteTableRows(index);
                                                                                            }}
                                                                                            style={{ whiteSpace: 'nowrap', marginTop: '3px' }}
                                                                                        >
                                                                                            옵션삭제
                                                                                        </button>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                    <button type="button" className="btn btn-primary btn-sm waves-effect waves-light ms-1" onClick={() => addTableRows(index)} style={{ whiteSpace: 'nowrap', marginTop: '3px' }}>
                                                                                        옵션추가
                                                                                    </button>
                                                                                </div>
                                                                                {/* )} */}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-body text-black">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">
                                                            <i className="dripicons-chevron-right font-11"></i> 상세페이지
                                                        </label>
                                                        <div>
                                                            <QuillEditor quillRef={quillRef} htmlContent={isHtmlContent} setHtmlContent={setHtmlContent} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="mt-4">
                                                    <button type="button" className="btn-sm btn-danger waves-effect waves-light" onClick={() => navigate(`/product?` + searchParams)}>
                                                        취소
                                                    </button>
                                                    <button type="button" className="btn-sm btn-dark waves-effect waves-light float-end" onClick={() => setUpdate()}>
                                                        {isProductIdx ? '수정' : '등록'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightSide />
            <div className="rightbar-overlay"></div>
            {isLoading ? <Loading /> : null}
            <ToastContainer
                position="top-right" // 알람 위치 지정
                autoClose={3000} // 자동 off 시간
                hideProgressBar={false} // 진행시간바 숨김
                closeOnClick // 클릭으로 알람 닫기
                rtl={false} // 알림 좌우 반전
                pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                draggable // 드래그 가능
                pauseOnHover // 마우스를 올리면 알람 정지
                theme="light"
                // limit={1} // 알람 개수 제한
            />
        </>
    );
};

export { ProductUpdatePage };
