// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_app__jPdYC {
    height: 100%;
}

.App_treeRoot__j1lK2 {
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
}

.App_draggingSource__viXA4 {
    opacity: 0.3;
}

.App_placeholderContainer__FPj2E {
    position: relative;
}

ul li {
    list-style-type: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryManagerPage/App.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".app {\n    height: 100%;\n}\n\n.treeRoot {\n    box-sizing: border-box;\n    height: 100%;\n    padding: 20px;\n}\n\n.draggingSource {\n    opacity: 0.3;\n}\n\n.placeholderContainer {\n    position: relative;\n}\n\nul li {\n    list-style-type: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__jPdYC`,
	"treeRoot": `App_treeRoot__j1lK2`,
	"draggingSource": `App_draggingSource__viXA4`,
	"placeholderContainer": `App_placeholderContainer__FPj2E`
};
export default ___CSS_LOADER_EXPORT___;
