// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDragPreview_root__a03tl {
    align-items: 'center';
    background-color: #1967d2;
    border-radius: 4px;
    box-shadow:
        0 12px 24px -6px rgba(0, 0, 0, 0.25),
        0 0 0 1px rgba(0, 0, 0, 0.08);
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    padding: 4px 8px;
    pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryManagerPage/CustomDragPreview.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB;;qCAEiC;IACjC,WAAW;IACX,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".root {\n    align-items: 'center';\n    background-color: #1967d2;\n    border-radius: 4px;\n    box-shadow:\n        0 12px 24px -6px rgba(0, 0, 0, 0.25),\n        0 0 0 1px rgba(0, 0, 0, 0.08);\n    color: #fff;\n    display: inline-flex;\n    font-size: 14px;\n    padding: 4px 8px;\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CustomDragPreview_root__a03tl`
};
export default ___CSS_LOADER_EXPORT___;
