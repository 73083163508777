// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomNode_root__rA2NX {
    align-items: center;
    /* display: grid; */
    display: flex;
    grid-template-columns: auto auto 1fr auto;
    height: 32px;
    padding-inline-end: 8px;
}

.CustomNode_isSelected__mfNpW {
    color: #2268c9;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
}

.CustomNode_expandIconWrapper__ibPHA {
    align-items: center;
    font-size: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
}

.CustomNode_expandIconWrapper__ibPHA.CustomNode_isOpen__pBxZB {
    transform: rotate(90deg);
}

.CustomNode_labelGridItem__WhIiM {
    padding-inline-start: 8px;
    flex: 1 1 auto !important;
}

.CustomNode_inputWrapper__7HJnJ {
    align-items: center;
    display: flex;
    grid-template-columns: repeat(3, auto);
}

.CustomNode_textField__bPQqx input {
    font-size: 14px;
    padding: 6px 0;
}

.CustomNode_editButton__de-jn {
    padding: 6px;
}

.CustomNode_editIcon__21G4u {
    font-size: 20px;
}

.CustomNode_nodeInput__yDQsp {
    width: 200px;
}

.CustomNode_nodeLabel__PmfPM {
    padding-right: 15px;
}

.CustomNode_handle__BnTun {
    cursor: grab;
    display: flex;
}

.CustomNode_handle__BnTun > svg {
    pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryManagerPage/CustomNode.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,0BAA0B;IAC1B,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,iCAAiC;IACjC,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".root {\n    align-items: center;\n    /* display: grid; */\n    display: flex;\n    grid-template-columns: auto auto 1fr auto;\n    height: 32px;\n    padding-inline-end: 8px;\n}\n\n.isSelected {\n    color: #2268c9;\n    text-decoration: underline;\n    text-underline-offset: 6px;\n    text-decoration-thickness: 2px;\n}\n\n.expandIconWrapper {\n    align-items: center;\n    font-size: 0;\n    cursor: pointer;\n    display: flex;\n    height: 24px;\n    justify-content: center;\n    width: 24px;\n    transition: transform linear 0.1s;\n    transform: rotate(0deg);\n}\n\n.expandIconWrapper.isOpen {\n    transform: rotate(90deg);\n}\n\n.labelGridItem {\n    padding-inline-start: 8px;\n    flex: 1 1 auto !important;\n}\n\n.inputWrapper {\n    align-items: center;\n    display: flex;\n    grid-template-columns: repeat(3, auto);\n}\n\n.textField input {\n    font-size: 14px;\n    padding: 6px 0;\n}\n\n.editButton {\n    padding: 6px;\n}\n\n.editIcon {\n    font-size: 20px;\n}\n\n.nodeInput {\n    width: 200px;\n}\n\n.nodeLabel {\n    padding-right: 15px;\n}\n\n.handle {\n    cursor: grab;\n    display: flex;\n}\n\n.handle > svg {\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CustomNode_root__rA2NX`,
	"isSelected": `CustomNode_isSelected__mfNpW`,
	"expandIconWrapper": `CustomNode_expandIconWrapper__ibPHA`,
	"isOpen": `CustomNode_isOpen__pBxZB`,
	"labelGridItem": `CustomNode_labelGridItem__WhIiM`,
	"inputWrapper": `CustomNode_inputWrapper__7HJnJ`,
	"textField": `CustomNode_textField__bPQqx`,
	"editButton": `CustomNode_editButton__de-jn`,
	"editIcon": `CustomNode_editIcon__21G4u`,
	"nodeInput": `CustomNode_nodeInput__yDQsp`,
	"nodeLabel": `CustomNode_nodeLabel__PmfPM`,
	"handle": `CustomNode_handle__BnTun`
};
export default ___CSS_LOADER_EXPORT___;
